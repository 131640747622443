.root {
  display: table-cell;
  width: 20px;
  vertical-align: middle;
}
.root .dropdownMenu > li > a {
  padding: 0.5rem;
  text-align: right;
}
.dropdownMenu {
  composes: dropdownMenu from '~vbrick-player-src/MenuButton.module.less';
}
:global(.dropdown-menu).dropdownMenu {
  min-width: 0;
  transform: translateX(3px);
}
.menuButton {
  composes: menuButton from '~vbrick-player-src/MenuButton.module.less';
  width: auto;
}
.layoutIcon {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  height: 16px;
  width: 16px;
}
