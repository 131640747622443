.video-thumbnail-status {
  position: absolute;top: 0;right: 0;bottom: 0;left: 0;
}
.video-thumbnail-status.fill {
  background: #b3b3b3;
}
.video-thumbnail-status.processing {
  background: #000;
  z-index: 1;
}
.video-thumbnail-status > span {
  position: absolute;
  left: 50%;
  top: 50%;
  border-radius: 50%;
  background: rgba(48, 48, 48, 0.75);
  color: #ebebeb;
  text-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
  text-align: center;
}
.video-thumbnail-status > .status-processing {
  background: none;
}
.video-thumbnail-status > .status-processing .loader.small {
  border-color: #999999;
  border-left-color: #fff;
  margin: 0;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
}
.video-thumbnail-status .status-inactive {
  background: none;
  color: #fff;
  opacity: 0.85;
  z-index: 1;
}
.tile-item .video-thumbnail-status > span {
  font-size: 30px;
  margin-left: -19px;
  margin-top: -19px;
  height: 38px;
  width: 38px;
  line-height: 38px;
}
.tile-item .video-thumbnail-status > .status-processing .loader.small {
  top: -2px;
}
.file-list-row.rejected .video-thumbnail-status {
  background: #c41616;
}
.file-list-row.pending-approval .video-thumbnail-status {
  background: rgba(0, 160, 240, 0.5);
}
.file-list-row.pending-approval.video-owner .video-thumbnail-status {
  background: rgba(255, 255, 0, 0.5);
}
.file-list-row .video-thumbnail-status > span {
  font-size: 18px;
  margin-left: -12px;
  margin-top: -12px;
  height: 24px;
  width: 24px;
  line-height: 24px;
}
.file-list-row .status-processing {
  position: relative;
  height: 18px;
}
