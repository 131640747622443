.root {
  background: #f3f3f3;
  padding: 0.875rem;
  border-radius: 3px !important;
}
.header {
  margin: 0 4rem 1rem 0;
  text-align: right;
}
.mediumChartWrapper {
  -webkit-flex: 1 1 45%;
  flex: 1 1 45%;
}
@media screen and (max-width: 991px) {
  .mediumChartWrapper {
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
  }
  .mediumChartWrapper:not(:last-child) {
    margin-right: 0 !important;
  }
}
.mediumChartSize {
  width: 100%;
  height: 320px;
}
.grid {
  height: 600px;
}
.searchWrapper {
  padding: 10px 0;
  display: flex;
  justify-content: flex-end;
}
.search {
  flex: 0 0 30%;
}
@media screen and (max-width: 767px) {
  .search {
    flex: 1 1 100%;
  }
}
