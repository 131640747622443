.root {
  position: relative;
}
.root:global(.infiniteGridEnableStatusBar) {
  margin-bottom: 50px;
}
.scrollExpiredMsg {
  position: absolute;
  width: 100%;
  bottom: -50px;
  left: 0;
  height: 50px;
  text-align: center;
  padding-top: 20px;
}
