.inputWrap {
  position: relative;
}
.inputWrap :global(ul.dropdown-menu) {
  top: 43px !important;
  right: 0;
  font-size: 1rem;
  border: 0;
  max-height: 400px;
  overflow-y: auto;
  border-radius: 3px !important;
}
.inputWrap :global(ul.dropdown-menu) li > a {
  border-left: 0px;
  padding: 8px 16px;
}
.inputWrap input[vb-text-input] {
  font-size: 1rem;
  height: 36px;
}
.noRightBorderRadius {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.noLeftBorderRadius {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.dropdownMenu {
  position: absolute;
  width: 100%;
  margin-top: 2px;
  z-index: 1;
  background: #fff;
  min-height: 150px;
  padding: 10px;
  color: #b0b0b0;
  top: 43px;
  border-radius: 3px;
}
.dropdownMenu .fadeInOut:global(.ng-hide) {
  opacity: 0;
}
.dropdownMenu .fadeInOut:global(.ng-hide-add),
.dropdownMenu .fadeInOut:global(.ng-hide-remove) {
  transition: all linear 0.5s;
}
