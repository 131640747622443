@import (reference) '~rev-less/global/variables';
@import (reference) '~rev-less/global/mixins';

&.open {
	.dropdown-menu.align-right {
		right: -1px;
		display: inline-block;
		min-width: 0;
	}
}

.dropdown-menu {
	left: 0;
	font-size: 14px;
	-webkit-transform: translate3d(0,0,0); // iOS render bug

	.list-group-item {
		border-bottom: 1px solid @borderColor;
		border-right: none;
		border-left: none;
		background: @white;

		&:last-child {
			margin-bottom: 0;
			.RoundedBottomShape();
		}

		&:first-child {
			.RoundedTopShape();
		}
	}

	a.list-group-item.active {
		&:extend(.dropdown-menu .list-group-item);
	}

	.dropdown.open & {
		&,
		.list-group-item:first-child {
			.BorderTopLeftRadius(0);
		}

		&.align-right,
		&.align-right .list-group-item:first-child {
			.BorderTopLeftRadius();
			.BorderTopRightRadius();
		}
	}

	.navbar-fixed-top & .list-group,
	.navbar-admin-main & .list-group {
		margin-bottom: 0;

		&-item {
			position: relative;
			display: block;
			padding: 10px 12px;
			margin-bottom: -1px;
			color: @color1LightText;
			font-size: 14px;
			.TransitionAll(none);

			&-heading {
				position: relative;
				display: block;
				padding: 10px 12px;
				margin-bottom: -1px;
				border: none;
				border-bottom: 1px solid #dddddd;
				background: @white;
				color: @color1LightText;
				font-size: 10px;
				font-weight: bold;
				text-transform: uppercase;
			}
		}
	}

	&.navdrop-menu {

		.table-cell & {
			line-height: 1;
			left: auto;
			top: 54px;
			margin: 0;
			padding: 0;
			background: @color2Lighten05;
			border: 1px solid @blackFade50;
			.RoundedShape(4px);
			.BoxShadow(0 10px 20px @blackFade50);
			z-index: 5000;

			.modal-open & {
				z-index: 910;
			}

			a,
			.list-group-item {
				background: none;
				color: @color1MedText;
				border: none;

				.glyphicons {
					color: @color1LightText;
					font-size: @type16;
				}
			}
		}

		.table-tools .table-cell &,
		.panel-heading .table-cell & {
			top: 28px;

			.list-group {
				margin-bottom: 0;
			}
		}

		/* TODO: More of a notice that these admin and media navbars get very messy
		/* because they are not contained in the same wrapper anymore */
		.navbar-admin-main .table-cell & {
			width: auto;
			top: 36px;
			.BorderTopLeftRadius(0);
			.BorderTopRightRadius(0);

			.list-group-item:first-child {
				.BorderTopLeftRadius(0);
				.BorderTopRightRadius(0);
			}
		}

		.menu-header .table-cell,
		.menu-footer {
			padding: 12px 15px;
		}

		a.list-group-item:hover:not(.active) {
			color: @white;
			background-color: @accentColor;

			.glyphicons {
				color: @white;
			}
		}
	}
}

.navbar-admin-main {
	.table-cell.open > a,
	.table-cell.dropdown.open > a.dropdown-toggle:not(.btn-primary),
	.table-cell.dropdown.open > button.dropdown-toggle:not(.btn-primary),
	.table-cell.dropdown.open > a.dropdown-toggle:hover:not(.btn-primary),
	.table-cell.dropdown.open > button.dropdown-toggle:hover:not(.btn-primary) {
		background: @blackFade05;
		border-color: @blackFade25;
		.BoxShadow(inset 0 0 2px @blackFade25);
	}
}

.navbar-admin-main .table-cell.open > a {
	background: @accentColor !important;
	color: @white;
}

.scrollable-area-wrapper {
	position: relative;

	&.has-items:after {
		content: '';
		position: absolute;
		left: 0;
		right:0;
		bottom: 0;
		height: 5px;
		.VerticalGradient(fade(@white, 0%), @blackFade10);
	}
}