.customSelect {
  border-radius: 3px;
  background: #fff;
  border-style: solid;
  display: block;
  position: relative;
  height: 40px;
  overflow: hidden;
}
.customSelect select {
  background: #fff;
  box-sizing: border-box;
  border: 1px solid transparent;
  color: #141414;
  cursor: pointer;
  font-size: 0.875rem;
  line-height: 1.6;
  margin: 0;
  outline: none;
  padding: 6px 42px 6px 10px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.customSelect:after,
.customSelect:before {
  content: " ";
  pointer-events: none;
  position: absolute;
  z-index: 2;
}
.customSelect:after {
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  display: block;
  height: 0;
  margin-top: -3px;
  right: 1em;
  top: 50%;
  width: 0;
}
.customSelect:before {
  height: 100%;
  right: 0;
  top: 0;
  width: 40px;
}
.customSelect:not(.focus) {
  border: 1px solid #909090;
}
.customSelect:not(.focus).error {
  border: 3px solid #c41616;
}
.customSelect:not(.focus).error select {
  padding: 6px 40px 8px 8px;
}
.customSelect.focus {
  border-width: 3px;
  box-shadow: inset 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
  outline: 0;
  border-right: 0;
}
.customSelect.focus select {
  padding: 6px 40px 8px 8px;
}
.customSelect.focus select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
  padding: 3px 42px 3px 8px;
}
/* IE 10/11+ - This hides native dropdown button arrow so it will have the custom appearance. Targeting media query hack via http://browserhacks.com/#hack-28f493d247a12ab654f6c3637f6978d5 - looking for better ways to achieve this targeting */
/* The second rule removes the odd blue bg color behind the text in the select button in IE 10/11 and sets the text color to match the focus style's - fix via http://stackoverflow.com/questions/17553300/change-ie-background-color-on-unopened-focused-select-box */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .customSelect select {
    border: 1px solid transparent;
    padding-right: 2em;
  }
  .customSelect select:focus {
    padding-bottom: 6px;
    padding-top: 6px;
  }
  .customSelect select::-ms-expand {
    display: none;
  }
  .customSelect select::-ms-value {
    background: none;
    color: #141414;
    padding-right: 4rem;
  }
  .customSelect:after {
    display: block;
  }
}
