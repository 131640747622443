@import (reference) '~rev-less/global/variables';
@import (reference) '~rev-less/global/mixins';

.vbUiBtn {
	display: inline-block;
	margin-bottom: 0;
	font-size: @type14;
	font-weight: 400;
	white-space: nowrap;
	text-align: center;
	vertical-align: middle;
	cursor: pointer;
	.RoundedShape(@defaultBorderRadius);
	padding: 0;
	border-width: 1px;
	border-style: solid;
	padding: 8px 12px;
	line-height: 1.4;
	position: relative;

	&:hover, &:focus {
		text-decoration: none;
		color: inherit;
	}

	&[disabled] {
		opacity: .5;
		&:hover:after, &:after {
			content: none;
		}

	}

}

.afterButton {
	content: '';
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.btnSecondary {
	&:after {
		.afterButton;
		background-color: rgba(255, 255, 255, .1) //lighten 10%;
	}

	&:hover {
		&:after {
			background-color: rgba(0, 0, 0, .2) //darken 20%;
		}
	}
}

:global(.btnSecondaryInactive) {
	&:after {
		background-color: rgba(0, 0, 0, .1) //darken 20%;
	}
}

.btnPrimary {
	&:hover:after {
		.afterButton;
		background-color: rgba(0, 0, 0, 0.2);
	}
}
