@import (reference) "~rev-less/global/mixins";
@import (reference) "~rev-less/global/variables";
@height: 260px;

.root {
	display: block;
	position: relative;

	:global(.cke_combo_button) {
		margin-bottom: 3px;
	}

	:global(.cke_wysiwyg_frame) {
		border-style: solid;
		box-sizing: border-box;

		&:global(:not(.has-focus)) {
			border-color: @lightBorder;
			border-width: 1px;
		}

		&:global(.has-focus) {
			border-width: 3px;
			.BoxShadow(inset 0px 0px 5px 0px @blackFade25);
		}
	}
}

.editorLoading {
	height: @height;
	width: 100%;
	position: absolute;
}

:global(.user-content) {
	a {
		color: @accentDarken10;
		&:hover {
			color: @accentColor;
		}
	}
}
