.vbUiBtn {
  display: inline-block;
  padding: 8px 12px;
  margin-bottom: 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.4;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 3px;
}
.vbSecondaryBtn {
  composes: vbUiBtn;
  background-color: #f1f1f1;
  border: 1px solid #909090;
  background: linear-gradient(180deg, #fff, #f1f1f1);
  color: #141414;
  box-shadow: #000;
}
.vbSecondaryBtn:focus,
.vbSecondaryBtn:hover {
  box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.4);
  text-decoration: none;
  color: #141414;
}
.vbSecondaryBtn[disabled] {
  color: #b0b0b0;
}
.vbSecondaryBtn[disabled]:hover {
  box-shadow: none;
}
.vbSecondaryBtn :global(.glyphicons) {
  color: inherit !important;
}
