.form {
  position: relative;
}
.textInput {
  padding-right: 3rem;
}
.clearButton,
.searchButton {
  background: none;
  border: 0;
  bottom: 0;
  color: #141414;
  padding: 2px 1rem 0;
  position: absolute;
  right: 0;
  top: 0;
}
.searchButton {
  pointer-events: none;
}
.searchButtonHidden {
  opacity: 0;
}
