@import (reference) '~rev-less/global/variables';
@import (reference) '~rev-less/global/mixins';

.btnToggleWrapper {
	position: relative;
    display: inline-block;
	vertical-align: middle;

	[vb-ui-radio-btn], button {
		float: left;

		&:first-child {
			margin-left: 0;
		}

		&:not(:first-child):not(:last-child) {
			margin-left: -1px;
			border-radius: 0;
		}

		&:first-child:not(:last-child) {
			.RoundedLeftShape(4px);
		}

		&:last-child:not(first-child) {
			margin-left: -1px;
			.RoundedRightShape(4px);
		}
	}
}


:global(.vbUiRadioBtnActive) {
	.activeBtn;
}

.activeBtn {
	background: none;
	position: relative;

	&:hover {
		.BoxShadow(none) !important;
		background: linear-gradient(@color0Fade50, @color0Fade50);
	}
}
