.thumbnail-picker-overlay {
  position: absolute;top: 0;right: 0;bottom: 0;left: 0;
}
.thumbnail-picker-overlay {
  z-index: 1;
  text-align: center;
}
.thumbnail-picker-overlay:hover {
  background: rgba(0, 0, 0, 0.1);
}
.thumbnail-picker-overlay:hover span.show-on-hover {
  display: inline;
}
.thumbnail-picker-overlay:hover div.show-on-hover {
  display: block;
}
.thumbnail-picker-overlay:hover .hide-on-hover {
  display: none;
}
.thumbnail-picker-overlay:hover .circle {
  background: #000;
}
.thumbnail-picker-overlay.btn-file {
  position: absolute;
}
.thumbnail-picker-overlay .table-display {
  height: 100%;
}
.thumbnail-picker-overlay .table-cell {
  vertical-align: middle;
}
.thumbnail-picker-overlay .circle {
  width: 50px;
  height: 50px;
  font-size: 32px;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  vertical-align: middle;
}
.thumbnail-picker-overlay .circle .glyphicons {
  top: 8px;
}
.video-playback-status-msg {
  color: #fff;
}
.video-playback-status-msg.centered-text-block {
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}
.video-playback-status-msg.centered-text-block > .centered-text-block-content {
  -webkit-flex: 1;
  flex: 1;
  line-height: 2rem;
  position: static;
}
.video-playback-status-msg .centered-text-block-content > h3:first-child {
  margin-top: 0;
}
.video-playback-status-msg h3 .glyphicons {
  margin-right: 4px;
}
.video-playback-status-msg .video-playback-error-msg,
.video-playback-status-msg.video-playback-error-msg,
.video-playback-status-msg .video-playback-error-msg a,
.video-playback-status-msg.video-playback-error-msg a,
.video-playback-status-msg .video-playback-error-msg h3,
.video-playback-status-msg.video-playback-error-msg h3 {
  color: #ffffb3;
}
.video-playback-status-msg .restore-button {
  font-size: 1rem;
  margin-top: 1.25rem;
}
vbrick-player vb-time-marker.triangle-on-bottom {
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent #00a0f0 transparent;
  margin-left: -9px;
  bottom: -11px;
}
vbrick-player vb-time-marker.triangle-on-top {
  border-style: solid;
  border-width: 5px 5px 0 5px;
  border-color: #ffff00 transparent transparent transparent;
  margin-left: -4px;
  top: -5px;
  z-index: 2;
}
vbrick-player vb-time-marker.inactive-time-range {
  background-color: #c41616;
  cursor: default;
  height: 100%;
}
vbrick-player vb-time-marker.time-range-display {
  height: 10px;
  top: -10px;
  border: 1px solid #ff8015;
  background-color: #ff8015;
}
