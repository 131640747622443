@import (reference) "~rev-less/global/variables";
@import (reference) "~rev-less/global/mixins";

.root {
	background: @color2Lighten03;
	padding: @type14;
	.RoundedShape(@defaultBorderRadius) !important;
}

.header {
	margin: 0 4rem 1rem 0;
	text-align: right;
}

.mediumChartWrapper {
	.flexItem(1 1 45%);

	@media screen and (max-width: @viewPortMedWidth - 1px) {
		.flexItem(1 1 100%);

		&:not(:last-child) {
			margin-right: 0 !important;
		}

	}
}

.mediumChartSize {
	width: 100%;
	height: 320px;
}

.grid {
	height: 600px; //check Later for height responsive
}

.searchWrapper {
	padding: 10px 0;
	display: flex;
	justify-content: flex-end;
}

.search {
	flex: 0 0 30%;

	@media screen and (max-width: @viewPortSmallWidth - 1px) {
		flex: 1 1 100%;
	}
}