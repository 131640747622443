@import "~rev-less/global/variables";
@import (reference) "~rev-less/global/mixins";

@deletWarning: #fcf8e3;

.video-stream {
	padding: 8px;
	background: @white;
	margin-bottom: 8px;
	.RoundedShape(3px);

	&.new {
		background: @accentFade10;
	}

	label {
		font-weight: normal;
		color: @color1;
	}

	.video-stream-header {
		margin: -8px 0 0 0;
		padding: 0;

		.combined-toolbar {
			margin: 0;
			h4 {
				margin: 0;
				line-height: 40px;
				font-size: @type16;
				text-shadow: 0 1px 0 @whiteFade50;
				font-weight: bold;
				color: @color1MedText;
				line-height: 1.3;
				word-break: break-all;

				.has-error& {
					color: @warningColor;
				}
			}

			input {
				margin-top: 8px;
			}

			&.not-new-stream-group {
				border-bottom: 1px solid @color1Fade10;
			}
		}
	}

	.video-stream-body {
		margin: 0 -8px;
		padding-left: 8px;
	}

	ul.streams {
		margin: 8px 0 0 0;
		padding: 0;
		list-style-type: none;

		li {
			margin-top: 14px;
			&:first-child{
				margin-top: 0;
			}
		}
	}

	li {
		color: #7d7d7d;

		strong {
			width: 70px;
			margin-right: 10px;
		}
	}

	vb-input[inline] {
		display: block;
		margin: 0;
	}

	.form-group {
		padding: 4px 0 !important;
	}

	.required-field,
	.success-field {
		position: absolute;
		top: -8px !important;
		right: -8px !important;

		@media(max-width: 767px) {
			right: -18px !important;
		}
	}

	vb-input {
		.has-error .error-field {
			position: static;
			text-align: right;
			margin: 7px 2px 0 0;

			.control-label{
				padding: 0;
			}
		}
	}

	.vb-multicast-ip,
	.vb-multicast-port,
	.vb-multicast-packet-size {
		.has-error {
			.error-field {
				text-align: left;
				float: right;
				clear: right;
				max-width: 100%;

				.control-label {
					white-space: normal;
				}
			}
		}

		.bytes-legend(3px);
	}


	&.processing {
		color: @color1;
		background: #f1f2f3;

		.video-stream-header {
			margin-bottom: -8px;
			border-bottom: 0;
		}
	}

	&:not(.has-error) .form-control:focus{
		border-color: @accentFade50;
	}

	.btn-admin.btn-white:not(:hover) {
		background: none;
	}

	&,
	&-input {
		&.has-error {
			color: @warningColor;
			background: fade(@warningColor, 10%);
		}
	}

	&-delete-warning {
		background: @deletWarning
	}

	.vbrick-multicast {
		margin-top: 15px;
		background: @accentFade10;
		padding: 5px;

		.bytes-legend(10px);

		.bytes-help-text {
			font-size: @type12;
		}

		&-check-box {
			margin: 1px 5px 0 -20px;
		}
	}

	.bytes-legend(@top) {
		.bytes {
			position: absolute;
			top: @top;
			right: -20px;
		}
	}

	.multicast-group-error {

		.error-field {
			position: relative;
			top: 0;

			@media(min-width: @viewPortVerySmallWidth) {
				right: -15px;
			}

			@media(min-width: @viewPortSmallWidth) {
				right: -32px;
			}
		}
	}

}
