@import (reference) "~rev-less/global/variables";
@import (reference) "~rev-less/global/mixins";

.upload-media {
	background-color: transparent;
}

.webexImportModal {
	.modal-content {
		overflow: visible;
	}
}

typeahead-container {
	z-index: 6000 !important;
}

upload-menu {
	border-style: solid;
	border-width: 1px;
	display: block;
	min-height: 100px;
	overflow: hidden;
	width: 665px;
	.RoundedShape();

	.form-upload-files {
		margin: 15px;
	}

	.link-url-wrapper {
		padding: 12px 15px;

		label,
		.has-error
		{
			margin: 4px 0 !important;
		}

		.has-error {
			color: @warningColor;
		}

		vb-ui-form-group {
			padding: 0;
			margin: 0;
		}

		.form-control {
			background: @white;
		}

		.flex-navigation-bar {
			margin-top: @typeUnit1-2x;
		}

		.btn {
			.BoxShadow(none);
			padding: 0 @typeUnit1-2x;
			height: 2rem;
			line-height: 2rem;
		}

		.btn-group {
			white-space: nowrap;

			.btn {
				display: inline-block;
			}
		}

		.fixed {
			padding-left: 8px;

			vb-ui-form-group {
				width: 100%;
			}

			.btn-block {
				min-width: 100px;
			}
		}

		select.form-control.selectize-style {
			height: 2rem;
			line-height: 2rem;
			padding: 0 @typeUnit1-2x;
			.BoxShadow(none) !important;
		}

		select.form-control.selectize-style::before {
			display: none;
		}
	}

	.upload-file-btn-wrapper .btn-file {
		font-size: @type16;
		font-weight: 400;
		height: 100px;
		line-height: 70px;
		position: relative;
		.RoundedShape();

		small {
			position: absolute;
			top: 28px;
			left: 0;
			right: 0;
			text-shadow: none;
		}
	}

	.upload-file-wrapper {
		padding: @typeUnit1-2x @typeUnit1-2x @typeUnit1-4x;
		background: @white;
		margin: 0 @typeUnit1x;
		border-bottom: 1px solid @borderColor;
		.BoxShadow(0 1px 2px @blackFade25);

		.loader {
			font-size: 1.1em;
			margin-left: 3px;
		}

		&.upload-complete {
			.BoxShadow(none);
			border-bottom: 1px solid @borderColorLight;

			.global-nav-wrap & .circle_ok {
				color: @accentColor;
			}
		}

		&.upload-error {
			h4 {
				color: @warningColor;
			}

			.error-msg {
				white-space: normal;
				height: auto;
				line-height: 1rem;
				&:extend(.WordBreak);
			}

			.glyphicons {
				.PointerCursor;
			}
		}

		&.upload-loading {
			.upload-file h4 {
				padding-right: 2em;
			}
		}


		.global-nav-wrap & {
			&.upload-error a,
			.error-msg,
			&.upload-error .glyphicons {
				color: @warningColor;
			}
		}

		.progress-wrapper {
			.RoundedShape();
			padding: @typeUnit1-2x 20px @typeUnit1-2x 0;
		}

		h4 {
			font-weight: bold;
			font-size: @type14;
			color: @color1;
			margin: 0;
			height: 1.5rem;
			line-height: 1.5rem;
			position: relative;
		}

		p {
			height: 1.5rem;
			line-height: 1.5rem;
			font-size: @type12;
			margin: 0;
			padding-right: 20px;
			color: @color1LightText;
			position: relative;

			&.upload-name {
				font-weight: bold;
				font-size: @type14;
				color: @color1;
			}

			.glyphicons {
				position: absolute;
				right: 0;
				font-size: @type12;
				top: auto;
				bottom: 0.5rem;
			}
		}

		.upload-file {
			&,
			a {
				color: @color1;
				.WordBreak;
			}

			.btn .loader {
				position: absolute;
				right: -.75em;
			}
			h4 {
				height: auto !important;
			}
		}

		.upload-title {
			font-size: @type14;
		}
	}

	.upload-viewing-hours-warning {
		margin-top: 1rem;
		padding: .5rem;
	}

	vb-checkbox .vb-checkbox-label {
		text-transform: capitalize;
	}

	.nav-tabs {
		li {
			border-bottom: 0 !important;

			&.active {
				border-color: transparent;

				a:hover {
					color: inherit;
				}
			}

			&.active a,
			a:hover {
				color: inherit;
			}

			&.active a,
			&.active a:hover,
			a:hover {
				background: none;
			}

			a,
			a:hover {
				border: 0 !important;
			}

			&:not(.active) {
				border-color: transparent;
			}
		}

		.nav-justified > li > a,
		> li > a {
			border-color: transparent;
			line-height: 1;
			color: @color1LightText;
			font-size: 14px;
			padding: 10px;
			margin: 4px 0;
		}

		&.nav-justified {
			> .active > a,
			> .active > a:hover {
				border: 1px solid transparent;
			}

			> li {
				border-left: 1px solid @blackFade50;

				&:first-child {
					border-left: none;
				}
			}
		}
	}

	.tab-pane {
		padding: 0;

		ol {
			margin: 0;
		}

		li {
			display: block;
			position: relative;
		}

		label {
			text-transform: uppercase;
			font-size: 11px;
			margin-bottom: 8px;
		}

		.table-cell {
			vertical-align: top;
		}
	}

	.scrollable-area {
		max-height: 300px;
		overflow-y: scroll;
	}

	@media(max-width: 600px){
		.link-url-wrapper {
			.table-cell {
				display: block;
				width: 100%;

				.btn-group {
					width: 100%;

					.btn {
						width: 50%;
					}
				}
			}

			.btn,
			select {
				height: 36px;
				line-height: normal;
			}
		}
	}
}

.dropdown-menu.navdrop-menu.upload-media .nav-tabs.nav-justified > li {
	display: table-cell;
	width: 1%;
}

.dropdown-menu.navdrop-menu .nav-tabs.nav-justified > li {
	word-break: break-word;
}

.upload-media {
	border: none !important;

	.nav-tabs.nav-justified {
		table-layout: fixed;

		> li {
			position: relative;
			overflow: hidden;
			vertical-align: middle;
			a {
				padding: 12px 4px;
				white-space: initial;
			}
		}
	}

	@media(max-width: 991px) {
		margin-top: -4px !important; //fixes a gap between the menu and its triangle
	}

	.upload-import-button {
		margin: 1em;

		.upload-import-button-logo {
			display: block;
			width: 50px;
			height: 50px;
			.RoundedShape();
			margin: 0 auto .25em;
			background-repeat: no-repeat;
			background-position: center;

			&.webex-logo {
				background-image: url(/img/icon-webex-40.png);
			}

			&.upload-import-button-rev-create {
				background-color: transparent;
				background-image: url(/img/btn-rev-create-50.png);
			}
		}
	}
}

@media(max-width: @viewPortSmallWidth){
	.dropdown-menu.navdrop-menu.upload-media {
		position: fixed;
		width: 100vw !important;

		upload-menu {
			width: auto;
		}
	}
}
