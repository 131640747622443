@import (reference) '~rev-less/global/variables';
@import (reference) '~rev-less/global/mixins';

.host {
	display: block;
	position: relative;
}

.wrapper {
	.BoxShadow();
	border: 1px solid;
	padding: 0 1rem 1rem;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	z-index: 50;
}