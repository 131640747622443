.host {
  display: block;
  position: relative;
}
.wrapper {
  box-shadow: 0 0 2px #000;
  border: 1px solid;
  padding: 0 1rem 1rem;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 50;
}
