.root:global(.ng-invalid):not(:global(.ng-untouched)):not(.hasFocus) .dateInput,
.root:global(.ng-invalid):not(:global(.ng-untouched)):not(.hasFocus) .dateBtn {
  border-bottom: 3px solid #c41616 !important;
  border-top: 3px solid #c41616 !important;
}
.root:global(.ng-invalid):not(:global(.ng-untouched)):not(.hasFocus) .dateInput {
  border-left: 3px solid #c41616;
}
.root:global(.ng-invalid):not(:global(.ng-untouched)):not(.hasFocus) .dateBtn {
  border-right: 3px solid #c41616 !important;
}
.dateWrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}
.dateWrapper :global(.dropdown-menu) {
  color: #000;
  padding: 0;
  margin-left: 32px;
}
.dateWrapper :global(.dropdown-menu) thead :global(.btn-sm) {
  font-weight: normal;
  padding: 12px 10px;
}
.dateWrapper :global(.dropdown-menu) :global(.btn) {
  border: none;
  background-color: transparent;
  border-radius: 0;
  box-shadow: none;
}
.dateWrapper :global(.dropdown-menu) :global(.btn) span {
  padding: 5px 8px;
}
.dateWrapper :global(.dropdown-menu) :global(.btn:not(.active) span.text-info) {
  font-weight: bold;
  color: #141414;
  border: 1px solid #909090;
  background-color: transparent;
}
.dateWrapper :global(.dropdown-menu) :global(.datePickerSelectedDate) button span {
  font-weight: bold;
  border: 1px solid #909090;
}
.dateWrapper > input {
  border-radius: 3px 0 0 3px;
}
.today {
  font-weight: bold;
  color: #141414;
  border: 1px solid #909090;
  background-color: transparent;
}
.dateInput {
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
}
.dateBtn {
  height: 100%;
  padding: 5px 10px;
  border-radius: 0 4px 4px 0;
  border: 1px solid #909090 !important;
  border-left-style: none !important;
  width: 40px;
}
