.RadioContainer {
  cursor: pointer;
  display: block;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.RadioContainer input {
  position: absolute;
  opacity: 0;
}
.RadioCheckmark {
  background-color: #fff;
  border: 1px solid #909090;
  border-radius: 50%;
  height: 24px;
  left: 0;
  position: absolute;
  top: 0;
  width: 24px;
}
.RadioContainer:hover input ~ .RadioCheckmark {
  background-color: #909090;
}
.RadioContainer input:checked ~ .RadioCheckmark {
  background-color: #fff;
}
.RadioCheckmarkChecked {
  border-radius: 50%;
  display: block;
  left: 4px;
  height: 14px;
  position: absolute;
  top: 4px;
  width: 14px;
}
.RadioTextInput {
  line-height: 1.75;
  padding-left: 35px;
}
.vbRadioCheckmark {
  composes: RadioCheckmark from './vb-ui-radio-button.module.less';
  width: 20px;
  height: 20px;
}
.vbRadioCheckmarkChecked {
  composes: RadioCheckmarkChecked from './vb-ui-radio-button.module.less';
  height: 10px;
  width: 10px;
}
.vbRadioTextInput {
  composes: RadioTextInput from './vb-ui-radio-button.module.less';
  position: relative;
  top: -2px;
}
