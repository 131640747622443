@import (reference) "~rev-less/global/variables";
@import (reference) "~rev-less/global/mixins";

.loadingContainer {
	left: 0;
	line-height: 1.5;
	overflow: hidden;
	//position: absolute;
	right: 0;
	text-align: center;
	top: 50%;

	&:not(:global(.box-inline)) {
		position: absolute;

		.icon {
			display: flex;
		}
	}

	&:global(.medium) {
		margin-top: -24px;

		.msg {
			font-size: @type10;
		}
	}

	&:global(.large) {
		margin-top: -36px;

		.msg {
			font-size: @type21;

			@media (max-width: @viewPortMobileDeviceWidth) {
				font-size: @type12;
			}
		}
	}
}

.loader {
	.Animation(spin 1.5s infinite linear);
	.RoundedShape(50%);
	border-left: 0.2em solid @blackFade50;
	border: 0.2em solid fade(@gray, 50%);
	display: inline-block;
	font-size: 18px;
	height: 1em;
	margin: 0 8px;
	overflow: hidden;
	position: relative;
	text-indent: -9999em;
	vertical-align: middle;
	width: 1em;

	&:after {
		.RoundedShape(50%);
		width: 1em;
		height: 1em;
	}

	&:global(.medium) {
		font-size: 48px;
		border-width: 0.125em;
		display: block;
	}

	&:global(.large) {
		font-size: 72px;
		border-width: 0.1em;
		display: block;
	}
}

.icon {
	display: inline-block;
	margin-bottom: 2px;

	&:before {
		display: none;
		position: absolute;
	}

	&:global(.medium) {
		font-size: @type18;

		&:before { // IE workaround
			min-width: 64px;
			top: 16px;
		}
	}

	&:global(.large) {
		font-size: @type30;

		&:before { // IE workaround
			min-width: 88px;
			top: 22px;
		}
	}

	&:global(.medium),
	&:global(.large) {
		display: block;

		&:before {
			display: block;
		}
	}
}

.msg:empty {
	display: none;
}

.msg:not(:empty) {
	display: block;
	margin: 1rem 0;
	padding: 0 .5rem;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}