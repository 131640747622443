@import (reference) '~rev-less/global/mixins';
@import (reference) '~rev-less/global/variables';

.rootElement {
	.alignItems(center);
	.flexDisplay;
	.flexJustifyContent(center);
	background-color: @white;
	height: 100%;
	position: relative;
	width: 100%;
}

.chartOutput {
	.AbsoluteFill;
}

.noData {
	color: @color1LightText;
	font-size: @type16;
	font-weight: bold;
}