.btn:global(.active),
.btnGroup :global(.btn):global(.active),
.btn:global(.active):hover,
.btnGroup :global(.btn):global(.active):hover {
  color: #005c8a;border-color: rgba(0, 92, 138, 0.25);background: #d7f2ff;box-shadow: none;
}
.mediaToolbar {
  display: block;
  position: fixed;
  min-height: 48px;
  width: 100%;
  z-index: 10;
  background: #f3f3f3;
  border-bottom: 1px solid #d8d8d8;
}
.mediaToolbarContainer {
  display: flex;
  align-items: stretch;
  padding: 10px 0 4px;
  flex-direction: column;
}
@media (min-width: 768px) {
  .mediaToolbarContainer {
    flex-direction: row;
  }
}
.mediaToolbarNav {
  margin-right: 10px;
  flex-grow: 1;
  font-size: 1.25rem;
  line-height: 2rem;
}
.mediaToolbarNav :global(.chevron-right) {
  font-size: 1rem;
}
@media (min-width: 768px) {
  .mediaToolbarNav {
    align-self: flex-end;
  }
}
.mediaToolbarControls {
  flex-grow: 0;
  flex-shrink: 0;
  text-align: right;
}
.btn,
.btnGroup :global(.btn) {
  height: 36px;
  padding: 0 12px !important;
  border-radius: 2px;
  background: none;
  border-color: #cbcbcb;
  color: #565656;
  box-shadow: inset 0 1px 0 #fff;
  font-size: 0.875rem;
  text-shadow: none;
  line-height: 36px;
}
.btn:hover:not(:global(.disabled)),
.btnGroup :global(.btn):hover:not(:global(.disabled)) {
  border: 1px solid #b0b0b0;
}
@media (max-width: 767px) {
  .btn,
  .btnGroup :global(.btn) {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}
.mediaToolbarControlBtns {
  display: flex;
  padding-top: 10px;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
}
.mediaToolbarControlBtns:empty {
  padding-top: 0;
}
.mediaToolbarControlBtns :global(.badge) {
  background-color: #00a0f0;
  left: 4px;
}
.mediaToolbarControlBtns :global(.btn-group) {
  line-height: 36px;
}
@media (min-width: 768px) {
  .mediaToolbarControlBtns {
    align-items: center;
    justify-content: flex-end;
  }
}
.playIcon {
  border-radius: 20px;
  border: 3px solid #fff;
  background: #fff;
  color: #00a0f0 !important;
  margin-right: 10px;
}
.teamHeader {
  display: block;
  border-top: 1px solid #ebebeb;
  padding: 1rem 0 1rem 2rem;
  height: 125px;
  min-width: 100%;
  z-index: 15;
  background-color: #f3f3f3;
}
@media all and (max-width: 992px) {
  .teamHeader {
    position: relative;
    top: 0;
  }
}
.uploadToTeam {
  height: inherit;
  margin-top: -17px;
  width: 8rem;
  float: right;
  background: rgba(0, 0, 0, 0.5);
  padding: 0;
  position: relative;
}
.uploadToTeam :global(.upload-btn) {
  height: inherit;
  width: inherit;
  background: none;
  border-color: #cbcbcb;
  color: #565656;
  box-shadow: inset 0 1px 0 #fff;
  font-size: 0.875rem;
  text-shadow: none;
  padding: 0 12px !important;
  line-height: 36px;
}
.uploadToTeam :global(.disk_open) {
  color: #fff;
  font-size: 2rem;
}
.uploadToTeam :global(.upload-text) {
  font-size: 0.7rem;
  color: #fff;
}
.teamLogoContainer {
  max-width: 120px;
}
@media (max-width: 768px) {
  .teamLogoContainer {
    padding-left: 0 !important;
  }
}
@media (max-width: 505px) {
  .teamHeaderContent {
    padding: 0 !important;
  }
}
.teamName {
  line-height: 1.2;
  margin: 0 0 0.5% 0;
  word-break: break-all;
}
.verticalDivider {
  border-left: 2px solid;
  margin-left: 5px;
  padding-left: 7px;
}
