@font-face {
	font-family: 'Karla';
	src: url('karla-regular.eot');
	src: url('karla-regular.eot?#iefix') format('embedded-opentype'),
	url('karla-regular.woff') format('woff'),
	url('karla-regular.ttf') format('truetype'),
	url('karla-regular.svg#karlaregular') format('svg');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Karla';
	src: url('karla-bold.eot');
	src: url('karla-bold.eot?#iefix') format('embedded-opentype'),
	url('karla-bold.woff') format('woff'),
	url('karla-bold.ttf') format('truetype'),
	url('karla-bold.svg#karlabold') format('svg');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Karla';
	src: url('karla-italic.eot');
	src: url('karla-italic.eot?#iefix') format('embedded-opentype'),
	url('karla-italic.woff') format('woff'),
	url('karla-italic.ttf') format('truetype'),
	url('karla-italic.svg#karlaitalic') format('svg');
	font-weight: 400;
	font-style: italic;
}