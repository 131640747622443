branding-preview-banner {
  display: block;
}
branding-preview-banner section {
  position: fixed;
  z-index: 1000;
  bottom: 0;
  height: 50px;
  width: 100%;
  padding: 10px;
  background-color: #fddd9a;
  text-align: center;
}
@media (max-width: 991px) {
  branding-preview-banner section {
    display: none;
  }
}
branding-preview-banner .preview-message {
  display: inline-block;
  margin-top: 5px;
}
branding-preview-banner .btn-preview {
  position: absolute;
  right: 10px;
  top: 10px;
}
