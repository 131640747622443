.root {
  display: block;
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}
.rootStopped {
  background: #000;
}
:global(.vgPosterRootStopped) {
  background: #000;
}
.image {
  bottom: 0;
  display: block;
  height: auto;
  left: 0;
  margin: auto;
  max-height: 100%;
  max-width: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}
