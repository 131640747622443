.root {
  composes: time-label from global;
  background: #f3f3f3;
  border-radius: 2px;
  border: 1px solid rgba(48, 48, 48, 0.75);
  bottom: 16px;
  color: #303030;
  cursor: default;
  display: table-cell;
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 2px 5px 1px 5px;
  pointer-events: none;
  position: absolute;
  text-align: left;
  transform: translate(-50%, 0);
  vertical-align: middle;
  width: auto;
  z-index: 1;
}
.root:after {
  border-color: #f3f3f3 transparent transparent transparent;
  border-style: solid;
  border-width: 4px 4px 0;
  bottom: -4px;
  content: '';
  height: 0;
  left: 50%;
  margin-left: -4px;
  position: absolute;
  width: 0;
}
:global(.vbScrubBarCurrentTimeBubbleIsLiveHide) {
  visibility: hidden;
}
