.warning,
.error {
  padding: 0.5rem 1rem;
}
.warning {
  background-color: #fff3cd;
  border-color: #ffeeba;
}
.lightText {
  color: #fff;
}
.lightText:hover {
  color: #fff;
}
