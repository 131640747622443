vb-toolbar {
  width: 100%;
}
vb-toolbar.mobile-nav,
vb-toolbar.section-header {
  height: 64px;
  color: #565656;
}
vb-toolbar.mobile-nav h1,
vb-toolbar.section-header h1 {
  font-weight: bold;
  font-size: 1.125rem;
  text-transform: uppercase;
  color: #565656;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  margin: 0;
}
vb-toolbar.panel-sub-header,
vb-toolbar.sub-section-header {
  height: 40px;
  color: #565656;
  border-top: 3px solid #cbcbcb;
}
vb-toolbar.panel-sub-header h2,
vb-toolbar.sub-section-header h2 {
  font-size: 0.875rem;
  font-weight: bold;
  color: #565656;
  text-transform: uppercase;
  margin: 0;
}
vb-toolbar.panel-sub-header {
  border-top: none;
  border-bottom: 1px solid #d8d8d8;
}
vb-toolbar .btn:not(.btn-primary):not(.btn-open-in-new-window):not(.active) {
  background: none;
  border-color: #cbcbcb;
}
vb-toolbar .btn-white {
  color: #898989;
}
vb-toolbar .btn-white.active {
  color: #005c8a;
  border-color: rgba(0, 92, 138, 0.25);
  background: #d7f2ff;
  box-shadow: none;
}
vb-toolbar .btn-white.active .glyphicons {
  color: #005c8a;
}
vb-toolbar .search-input {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}
vb-toolbar .search-input form {
  min-width: 150px;
  max-width: 300px;
}
vb-toolbar.mobile-nav {
  display: none;
}
@media (max-width: 991px) {
  vb-toolbar.mobile-nav {
    display: block;
    width: 100%;
    height: 48px;
    background: #303030;
    color: #898989;
  }
  vb-toolbar.mobile-nav h1 {
    color: #898989;
  }
  vb-toolbar.mobile-nav .btn {
    border: none;
  }
  vb-toolbar.mobile-nav .btn:hover {
    color: #fff;
  }
  vb-toolbar.mobile-nav .btn.btn-back {
    padding: 0 16px;
  }
  vb-toolbar.mobile-nav.bottom {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    top: auto;
    height: 48px;
    background: #fff;
    border-top: 1px solid #b2b2b2;
    border-bottom: none;
    z-index: 10000;
  }
  vb-toolbar.mobile-nav.bottom .btn {
    font-size: 1rem;
    color: #303030;
  }
  vb-toolbar.mobile-nav.bottom .btn:hover {
    color: #303030;
  }
  vb-toolbar.mobile-nav.bottom.active {
    border: none;
  }
  vb-toolbar.mobile-nav.bottom.active .btn {
    color: #303030;
  }
}
