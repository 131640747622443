.menuButtonContainer {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  width: 40px;
}
.menuButtonContainer select {
  font-size: 18px;
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.menuButtonContainer .textMenuButton {
  font-family: inherit;
  font-size: 13px;
}
.menuButton {
  composes: dropdown-toggle from global;
  composes: iconButton from './VbrickPlayer.module.less';
  width: 100%;
}
.menuButton.selected {
  color: #00a0f0;
}
.textMenuButtonContainer {
  composes: menuButtonContainer;
  width: 40px;
}
.textMenuButton {
  composes: menuButton;
}
.dropdown {
  composes: dropdown from global;
  composes: dropup from global;
}
.dropdown:global(.open) .menuButton {
  background: #00a0f0;
  color: #fff;
}
.dropdownMenu {
  composes: dropdown-menu from global;
  composes: pull-right from global;
}
:global(.dropdown-menu).dropdownMenu {
  background-color: #303030;
  text-align: left;
}
:global(.dropdown-menu).dropdownMenu > li > a {
  color: rgba(255, 255, 255, 0.5);
  padding-left: 30px;
}
:global(.dropdown-menu).dropdownMenu > li > a:hover {
  background-color: #00a0f0;
  color: #fff;
  cursor: pointer;
}
:global(.dropdown-menu).dropdownMenu > li > a.selected:before {
  content: '\e207';
  font-family: 'Glyphicons Regular';
  left: 10px;
  position: absolute;
}
