@import (reference) '~rev-less/global/variables';

.rowDivider {
	composes: theme-primary-border from global;
	border-bottom-style: solid;
	border-bottom-width: 1px;
	margin-top: 0;
	padding-top: 0;
	margin-bottom: 40px;
	padding-bottom: 4px;
	display: flex;
	justify-content: space-between;
	width: 100%;

	.dlgIcon {
		font-size: 24px;
		cursor: pointer;
	}

	h4 {
		margin: 6px 12px;
	}
}

.spacer {
	flex-grow: 1;
}

.container {
	display: flex !important;
	flex-direction: column;
	align-items: center;
	padding: 16px;
	height: 100%;
	width: 100%;

	.spinnerContainer {
		height: 400px;
	}

	.playerContainer {
		width: 100%;
		height: 100%;
	}
	vb-video-player {
		width: 100%;
		height: 400px;
	}

	vb-overlay-play-controls div {
		background: none;

		div {
			display: none;
		}
	}

	vb-playback-rate-button, vb-playback-menu-button, vg-time-display, vg-volume, vg-fullscreen-button {
		display: none;
	}

	.selectBtn {
		margin: 16px;
	}
}
