vbrick-player-wmv .player-wrap .overlay-play,
vbrick-player-wmv .player-wrap .overlay-reconnecting {
  position: absolute;top: 0;right: 0;bottom: 0;left: 0;
}
vbrick-player-wmv {
  /* jquery slider Overrides */
}
vbrick-player-wmv .player-placeholder,
vbrick-player-wmv .vb-player {
  width: 100%;
  height: 100%;
}
vbrick-player-wmv .player-wrap {
  background: #000;
  height: 100%;
  width: 100%;
}
vbrick-player-wmv .player-wrap .overlay-play {
  cursor: pointer;
  z-index: 5;
}
vbrick-player-wmv .player-wrap .overlay-play:hover .circle {
  background: #00a0f0;
  color: #fff;
}
vbrick-player-wmv .player-wrap .overlay-play .circle {
  color: rgba(255, 255, 255, 0.75);
  height: 50px;
  width: 50px;
  background: rgba(48, 48, 48, 0.5);
  font-size: 30px;
  top: 50%;
  left: 50%;
  position: absolute;
  margin-top: -25px;
  margin-left: -25px;
  z-index: 10;
}
vbrick-player-wmv .player-wrap .overlay-play .glyphicons {
  margin-top: 9px;
  margin-left: 2px;
}
.vb-player-ie vbrick-player-wmv .player-wrap .overlay-play {
  z-index: 2;
}
vbrick-player-wmv .player-wrap .overlay-reconnecting {
  background-color: #000;
}
vbrick-player-wmv .player-wrap .overlay-reconnecting .status-msg h3 {
  color: #fff;
}
vbrick-player-wmv .player-wrap .fixed-ratio-wrapper {
  z-index: 1;
}
.vb-player-no-video-overlay vbrick-player-wmv .player-wrap .player-placeholder {
  padding-bottom: 35px;
}
.vb-player-no-video-overlay vbrick-player-wmv .player-wrap.hide-video object,
.modal-open .vb-player-no-video-overlay vbrick-player-wmv .player-wrap object {
  visibility: hidden;
}
.modal-open .vb-player-ie vbrick-player-wmv .player-wrap object {
  visibility: hidden;
}
vbrick-player-wmv .player-wrap .subtitles {
  position: absolute;
  bottom: 40px;
  color: #fff;
  font-size: 18px;
  text-align: center;
  text-shadow: 1px 1px #000, -1px 1px #000, 1px -1px #000, -1px -1px #000;
  white-space: pre-line;
  width: 100%;
}
vbrick-player-wmv .player-controls {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  opacity: 1;
  -webkit-transition: all linear 0.25s;
  transition: all linear 0.25s;
  border: 1px solid #1e1e1e;
  background-color: #303030;
  -webkit-transform: translateZ(0);
  /* TODO: button transitions cancel control bar leave transitions, find a workaround */
}
vbrick-player-wmv .player-controls .btn {
  -webkit-transition: none;
  transition: none;
}
vbrick-player-wmv .player-controls .btn .glyphicon,
vbrick-player-wmv .player-controls .btn .subtitles-icon {
  vertical-align: middle;
}
vbrick-player-wmv .player-controls .btn.selected {
  color: #00a0f0;
}
vbrick-player-wmv .player-controls-inner {
  width: 100%;
  display: table;
}
vbrick-player-wmv .player-controls .table-cell {
  vertical-align: middle;
}
vbrick-player-wmv .player-controls .table-cell:first-child {
  padding-right: 10px;
}
vbrick-player-wmv .player-controls .flex-container {
  -webkit-align-items: center;
  align-items: center;
}
vbrick-player-wmv .player-controls .flex-container .video-length {
  line-height: 36px;
}
vbrick-player-wmv .player-controls .flex-container .time-label {
  bottom: 24px;
}
vbrick-player-wmv .player-controls .dropdown.open .btn {
  background: #00a0f0;
  color: #fff;
}
vbrick-player-wmv .player-controls .dropdown.open .dropdown-menu {
  background-color: #303030;
}
vbrick-player-wmv .player-controls .dropdown.open .dropdown-menu > li > a {
  color: rgba(255, 255, 255, 0.5);
  padding-left: 30px;
}
vbrick-player-wmv .player-controls .dropdown.open .dropdown-menu > li > a:hover {
  color: #fff;
  background-color: #00a0f0;
}
vbrick-player-wmv .player-controls .dropdown.open .dropdown-menu > li > a.selected:before {
  font-family: 'Glyphicons Regular';
  content: '\E207';
  position: absolute;
  left: 10px;
}
.vb-player-ie vbrick-player-wmv .player-controls {
  position: absolute;
}
.vb-player-ie vbrick-player-wmv .player-controls .time-label {
  bottom: 24px;
}
.vb-player-no-video-overlay vbrick-player-wmv .player-controls .time-label {
  display: none;
}
vbrick-player-wmv .player-controls .subtitles-icon {
  border-radius: 6px;
  padding: 0 2px;
  border-width: 2px;
  border-style: solid;
}
vbrick-player-wmv .player-controls .glyphicon-cog {
  font-size: 1rem;
  top: 0;
}
vbrick-player-wmv .icon-button {
  background: none;
  color: rgba(255, 255, 255, 0.5);
  border: none;
  border-radius: 0;
  vertical-align: middle;
  box-shadow: none;
  text-align: center;
}
vbrick-player-wmv .icon-button:hover,
vbrick-player-wmv .icon-button:focus,
vbrick-player-wmv .icon-button:focus:active,
vbrick-player-wmv .icon-button:active {
  border-radius: 0;
  color: #fff;
  text-decoration: none;
}
vbrick-player-wmv .icon-button .glyphicons {
  font-size: 18px;
  line-height: 1;
  top: 0;
  float: left;
}
vbrick-player-wmv .play-pause-btn {
  padding: 0 16px;
  text-align: center;
  width: 50px;
  height: 30px;
}
vbrick-player-wmv .play-pause-btn:hover {
  background: #00a0f0;
  color: #fff;
}
vbrick-player-wmv .progress-indicator,
vbrick-player-wmv .progress-indicator-volume {
  position: relative;
  cursor: pointer;
  height: 12px;
  width: 100%;
  background: #5a5a5a;
}
vbrick-player-wmv .time-label {
  position: absolute;
  bottom: 16px;
  background: #f3f3f3;
  border-radius: 2px;
  padding: 2px 5px 1px 5px;
  color: #303030;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  border: 1px solid rgba(48, 48, 48, 0.75);
  line-height: 1.5;
}
vbrick-player-wmv .time-label:after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4px 4px 0 4px;
  border-color: #f3f3f3 transparent transparent transparent;
  position: absolute;
  left: 50%;
  margin-left: -4px;
  bottom: -4px;
}
vbrick-player-wmv .video-length {
  white-space: nowrap;
  color: rgba(255, 255, 255, 0.5);
  display: table-cell;
  font-size: 12px;
  padding: 0 8px;
  line-height: 0;
  vertical-align: middle;
}
vbrick-player-wmv .video-length.video-length-live {
  padding: 0;
}
vbrick-player-wmv .control-spacer {
  width: 18px;
}
vbrick-player-wmv .volume-btn {
  width: 30px;
  margin-right: 10px;
}
vbrick-player-wmv .video-slider-bar,
vbrick-player-wmv .volume-slider-bar {
  padding: 7px 0;
  position: relative;
}
vbrick-player-wmv .volume-slider-bar {
  width: 80px;
  padding-right: 10px;
}
vbrick-player-wmv .table-cell:last-child .closed-caption-icon {
  margin-right: 5px;
}
vbrick-player-wmv .full-screen .btn {
  width: 45px;
}
vbrick-player-wmv .ui-slider-range {
  -webkit-transition: all linear 0.15s;
  transition: all linear 0.15s;
  outline: 1px solid transparent;
  /* fix trailing lines in chrome http://stackoverflow.com/questions/12348884/jquery-ui-draggable-element-leaves-weird-traces-in-chrome */
}
.progress-indicator-volume vbrick-player-wmv .ui-widget-content {
  background-color: #00a0f0;
}
vbrick-player-wmv .ui-widget-header {
  border: none;
  background: #00a0f0;
}
vbrick-player-wmv .ui-slider .ui-slider-handle {
  display: none;
}
vbrick-player-wmv .ui-widget-content {
  border: none;
}
vbrick-player-wmv .ui-corner-all,
vbrick-player-wmv .ui-corner-bottom,
vbrick-player-wmv .ui-corner-right,
vbrick-player-wmv .ui-corner-br {
  border-bottom-right-radius: 0;
}
vbrick-player-wmv .ui-corner-all,
vbrick-player-wmv .ui-corner-bottom,
vbrick-player-wmv .ui-corner-left,
vbrick-player-wmv .ui-corner-bl {
  border-bottom-left-radius: 0;
}
vbrick-player-wmv .ui-corner-all,
vbrick-player-wmv .ui-corner-top,
vbrick-player-wmv .ui-corner-right,
vbrick-player-wmv .ui-corner-tr {
  border-top-right-radius: 0;
}
vbrick-player-wmv .ui-corner-all,
vbrick-player-wmv .ui-corner-top,
vbrick-player-wmv .ui-corner-left,
vbrick-player-wmv .ui-corner-tl {
  border-top-left-radius: 0;
}
@media (min-width: 1400px) {
  vbrick-player-wmv .player-wrap .overlay-play .circle {
    height: 80px;
    width: 80px;
    font-size: 48px;
    margin-top: -40px;
    margin-left: -40px;
  }
  vbrick-player-wmv .player-wrap .overlay-play .glyphicons {
    margin-top: 14px;
    margin-left: 3px;
  }
}
@media (min-width: 992px) {
  vbrick-player-wmv .full-screen-video .container-fluid {
    padding: 0 16px;
  }
  vbrick-player-wmv .full-screen-video .vbrick-modal-player .player-controls {
    position: absolute;
  }
  vbrick-player-wmv .full-screen-video .normal-target {
    display: none;
  }
  vbrick-player-wmv .full-screen-video .player-wrap:not(.vbrick-modal-player) .player-controls {
    left: 30px;
    right: 30px;
    bottom: 16px;
  }
}
