@import (reference) '~rev-less/global/mixins';
@import (reference) '~rev-less/global/variables';

.root {
	border: 1px solid @color2Darken15;
	background-color: @backgroundLighten8;
	color: @blackFade75;
	display: flex;
	height: 160px;
	margin-bottom: 1.5rem;

	&:not(:last-child) {
		margin-right: 1.5rem;
	}

	&.sizeMedium {
		height: 210px;
	}

	&.sizeLarge {
		height: 400px;
	}
}

.headerContainer {
	border-top: 3px solid;
	border-bottom: 1px solid @color2Darken15 !important;
	height: 50px;
	line-height: 1.1;
	width: 100%;
}

.tooltipContainer {
	top: -10px !important; //adjustment. hover put tooltip over text.
	:global(.tooltip-arrow) {
		left: 50% !important;
	}
}

.noHeaderBottomBorder {
	.headerContainer {
		border-bottom: 0 !important;
	}
}

.headerAbsoluteContainer {
	padding: 5px 10px;
}

.header {
	.TextClip;
	font-weight: bold;
	text-transform: uppercase;
	font-size: @type14;
	margin: 0;
}

.subheader {
	.TextClip;
	font-size: @type10;
	margin: .25rem 0 0;
}

.content {
	color: @blackFade75;
	padding: 1rem;
}

.footer {
	border-top: 1px solid @color2Darken15;
	padding: 10px 20px;
}

.footerItem {
	text-align: center;
	font-weight: bold;
	&:not(:first-child) {
		border-left: 1px solid @color2Darken15;
	}
}

