.root {
  cursor: pointer;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  width: 40px;
}
.button {
  composes: iconButton from '../../VbrickPlayer.module.less';
  width: 100%;
}
.enter:before {
  content: '\e350';
}
.exit:before {
  content: '\e350';
}
