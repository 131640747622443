.vbUiBtn {
  display: inline-block;
  margin-bottom: 0;
  font-size: 0.875rem;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 3px;
  padding: 0;
  border-width: 1px;
  border-style: solid;
  padding: 8px 12px;
  line-height: 1.4;
  position: relative;
}
.vbUiBtn:hover,
.vbUiBtn:focus {
  text-decoration: none;
  color: inherit;
}
.vbUiBtn[disabled] {
  opacity: 0.5;
}
.vbUiBtn[disabled]:hover:after,
.vbUiBtn[disabled]:after {
  content: none;
}
.afterButton {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.btnSecondary:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.1);
}
.btnSecondary:hover:after {
  background-color: rgba(0, 0, 0, 0.2);
}
:global(.btnSecondaryInactive):after {
  background-color: rgba(0, 0, 0, 0.1);
}
.btnPrimary:hover:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
}
