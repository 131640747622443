.root {
  overflow: hidden;
  position: relative;
}
.sidebarButtonContainer {
  border: 0;
  border-left-width: 1px;
  border-left-style: solid;
  box-sizing: content-box;
  width: 48px;
  z-index: 1;
}
@media (max-width: 991px) {
  .sidebarButtonContainer {
    border-top-width: 1px;
    border-top-style: solid;
  }
}
.sidebarButtonContainerFixedColor {
  background: #3d3d3d;
  border-left: 1px solid #909090 !important;
}
.sidebarButton {
  composes: theme-accent-btn from global;
  background: transparent;
  border: 0;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  font-size: 1rem;
  width: 48px;
  height: 48px;
  position: relative;
}
.sidebarButton:hover {
  background: linear-gradient(rgba(34, 41, 48, 0.5), rgba(34, 41, 48, 0.5));
}
.sidebarButtonFixedColor {
  border-bottom: 1px solid #909090 !important;
  color: #f8f8f8;
}
.sidebarButtonFixedColor:first-child {
  border-top-width: 0 !important;
}
.sidebarPanel {
  min-height: 1px;
  width: 432px;
  -webkit-transition: width linear 0.5s;
  transition: width linear 0.5s;
  border-left-width: 1px;
  border-left-style: solid;
}
.sidebarPanel:not(:global(.open)) {
  width: 0;
  overflow-y: hidden;
}
.sidebarPanel:not(:global(.open)) .sidebarPanelLayout {
  width: 0;
}
@media (max-width: 991px) {
  .sidebarPanel {
    border: 0;
  }
}
.sidebarPanelFixedColor {
  background: #636363;
  color: #f5f5f5;
}
.sidebarPanelLayout {
  height: 100%;
  overflow: hidden;
  position: absolute;
  width: 432px;
}
.header {
  composes: theme-accent theme-primary-font-border-fade-50 from global;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  font-size: 1rem;
  line-height: 47px;
  padding: 0 1rem;
  width: 432px;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
}
.sidebarPanelBody {
  height: 100%;
  overflow-y: auto;
}
.notificationCount {
  border-radius: 3px;
  padding: 3px;
  font-size: 0.625rem;
  min-width: 1.5em;
  line-height: 1.25em;
  position: absolute;
  right: 5px;
  bottom: 9px;
  border-width: 1px;
  border-style: solid;
  composes: theme-accent theme-accent-font-border from global;
}
.notificationIcon {
  composes: theme-accent theme-accent-font-border from global;
  position: absolute;
  top: 6px;
  right: 2px;
  border-width: 1px;
  border-style: solid;
  border-radius: 50%;
  height: 20px;
  width: 20px;
}
.notificationIcon:before {
  font-size: 10px;
}
@media (max-width: 991px) {
  .root:not(.mobileLayoutDisabled) .header {
    width: 100%;
  }
  .root:not(.mobileLayoutDisabled):global(.vbUiSidebarRootOpen) {
    -webkit-flex: 100%;
    flex: 100%;
  }
  .root:not(.mobileLayoutDisabled) .sidebarButton {
    border-bottom: transparent;
  }
  .root:not(.mobileLayoutDisabled) .sidebarButtonContainer {
    border-left: transparent;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    justify-content: space-around;
    width: 100%;
  }
  .root:not(.mobileLayoutDisabled) .sidebarPanel {
    width: 100%;
    -webkit-transition: none;
    transition: none;
  }
  .root:not(.mobileLayoutDisabled) .sidebarPanel:not(:global(.open)) {
    height: 0;
  }
  .root:not(.mobileLayoutDisabled) .sidebarPanelLayout {
    width: 100%;
  }
}
