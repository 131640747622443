.root {
  bottom: 0;
  display: block;
  height: 36px;
  position: absolute;
  width: 100%;
  z-index: 50;
}
.controlsContainer {
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  height: 50px;
  position: absolute;
  width: calc(100% + 1px);
  zoom: 1;
}
.hideAnimation {
  animation: hideControlsAnimationFrames ease-out 0.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
}
.hideAnimation:not(.showAnimation) {
  pointer-events: none;
}
.controlsContainer.hideAnimation > span {
  visibility: hidden;
}
.showAnimation {
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation: showControlsAnimationFrames ease-out 0.5s;
  background-color: #303030;
  height: 36px;
}
@keyframes hideControlsAnimationFrames {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes hideControlsAnimationFrames {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
  }
}
@keyframes showControlsAnimationFrames {
  0% {
    background-color: transparent;
  }
  100% {
    background-color: #303030;
  }
}
@-webkit-keyframes showControlsAnimationFrames {
  0% {
    background-color: transparent;
  }
  100% {
    background-color: #303030;
  }
}
