@import (reference) "~rev-less/global/variables";
@import (reference) "~rev-less/global/mixins";

public-video-password {
	&:extend(.FixedFill);

	.system-login-shell {
		display: table;
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
	}

	.description {
		background: @white;
		border: 1px solid @borderColor;
		height: 25vh;
		margin-bottom: 2em;
		overflow-y: auto;
		padding: 2%;
	}

	.registered-user {
		margin: 1em 0 -1em 0;
		font-size: @type16;

		a {
			color: @accentColor;
		}
	}
}
