@import (reference) "~rev-less/global/variables";

@statusHeight: 50px;

.root {
	position: relative;

	&:global(.infiniteGridEnableStatusBar) {
		margin-bottom: @statusHeight;
	}
}


.scrollExpiredMsg {
	position: absolute;
	width: 100%;
	bottom: -@statusHeight;
	left: 0;
	height: @statusHeight;
	text-align: center;
	padding-top: 20px;
}
