@import (reference) '~rev-less/global/variables';
@import (reference) '~rev-less/global/mixins';

.system-login-shell {
	position: absolute;
	height: 100%;
	width: 100%;
	padding: 0 15px;
	-webkit-transform: translateZ(0);

	.system-login {
		margin-top: -50px; /* makes up for nav row */

		.system-form {
			padding: 30px;
			.RoundedShape();
			.BoxShadow(0 0 5px 0 @blackFade25);
			.VerticalGradient(rgba(247,247,247,1), rgba(233,233,233,1));

			.form-control:not(:focus) {
				.BoxShadow(inset 0 1px 1px fade(@black, 7.5%));
			}
		}

		.username,
		.password {
			position: relative;
		}

		.glyphicons.user,
		.glyphicons.lock,
		.glyphicon.glyphicon-lock {
			position: absolute;
			top: 12px;
			left: 12px;
			font-size: 16px;
			color: @color1LightText;
		}

		.error-msg .glyphicons {
			position: relative;
			right: auto;
			font-size: 18px;
		}

		input,
		.has-success input,
		.has-error input {
			margin: 0 0 17px 0;
			font-size: @type16;
			height: auto;
			color: @color1;
			padding: 10px 15px;
		}

		select {
			.VerticalGradient(@white, #f8fbfd);
			.BoxShadow(0 1px 2px @blackFade10);
			color: @color1;
			height: 40px;
			margin: 0 0 17px 0;
			width: 100%;
		}

		input {
			&.input-with-icon {
				padding: 0 15px 0 40px;
				height: 40px;
				line-height: 40px;
				vertical-align: middle;
			}

			&.form-control:focus {
				background: @white;
			}

			&.ng-dirty.ng-invalid {
				border-color: @borderColorDark !important;
			}
		}

		.btn-primary,
		.btn-lg {
			font-size: 18px;
			padding: 9px 15px;
			.RoundedShape();
		}

		.btn-lg.btn-white {
			color: #7d7d7d;
		}

		.btn-primary:hover {
			color: @white;
		}

		label {
			font-weight: 400;
			font-size: @type16;

			&.error-msg {
				font-size: 12px;
				background-color: @white;
				padding: 5px 10px;
				margin: 1px 0 0 0;
				color: rgba(218, 98, 98, 1);
				border: 1px solid rgba(218, 98, 98, 0.2);
			}
		}

		.form-control-feedback {
			display: none;
		}

		.required .required-symbol.form-control-feedback {
			display: block;
			font-size: 36px;
			line-height: 58px;
			color: @warningColor;
		}

		.has-success .ok_2.form-control-feedback,
		.has-error .remove_2.form-control-feedback {
			display: block;
			position: absolute;
		}

		.has-feedback .form-control-feedback {
			width: 40px;
			height: 40px;
			line-height: 42px;
			font-size: @type16;
		}

	}

	>.table-display {
		height: 100%;
	}

	.table-cell-centered-form {
		vertical-align: middle;
	}

	.system-login-nav {
		height: 50px;

		.main-nav {
			background: none !important;
			border-bottom: none;
			width: 100%;

			.navbar {
				margin-bottom: 0;
				display: table;
				width: 100%;
				height: 59px;

				@media all and (max-width: @viewPortMedWidth - 1) {
					min-height: 53px;
				}
			}

			.brand-cell {
				width: 100%;
			}

			.btn {
				background: none;
				.BoxShadow(none);
			}
		}
	}
}

.forgot-password {
	font-weight: normal;
	font-size: 12px;
	margin-left: 5px;
	text-align: right;
	margin-bottom: 15px;
	position: relative;
	top: -10px;
}

.status-msg:not(.vb-loading-spinner-status-msg) {
	position: relative;
	text-align: center;
	color: @whiteFade75;

	&-dark {
		color: @blackFade25;
	}

	h3 {
		margin-top: 0;

		.glyphicons {
			top: 5px;
			margin-right: 5px;
		}
	}
}

.system-msg {
	font-size: 18px;
	margin-bottom: 30px;
	line-height: 1.3;

	.glyphicons {
		margin-right: 5px;
		top: 2px;
	}
}

.system-form {
	h1 {
		text-align: center;
		margin: -15px -30px 30px -30px;
		padding: 0 30px 15px 30px;
		border-bottom: 1px solid @blackFade10;
		font-weight: normal;
		color: @blackFade50;
		text-shadow: 0 1px 0 @whiteFade75;
		font-size: 24px;
	}

	h4 {
		color: #a2a2a2;
		text-transform: uppercase;
		font-size: @type16;
		margin: 0 0 8px 0;
	}

	label {
		display: block;
		font-size: 14px;
	}
}

.system-form-buttons {
	margin-top: 25px;

	.reset-password-form & {
		margin-top: 0;
	}
}

@media (max-width: 991px) {
	.system-login-shell {
		margin-top: 0;

		.system-login-nav .main-nav .brand-cell {
			width: 100% !important;
		}

		.system-form-buttons {
			.btn-toolbar>.btn{
				display: block;
				margin-bottom: 5px;
				width: 100%;
				margin-left: 0;
			}

			.responsive-block.pull-right {
				float: none !important;
				display: block;
			}
		}
	}
}

@media (min-width: 992px) {
	.system-login-shell {
		.system-form h1 {
			margin: -30px -45px 30px -45px;
			padding: 0 45px 15px 45px;
		}

		.system-login .system-form {
			padding: 45px 45px 30px 45px;
			.RoundedShape();
		}
	}
}

@media (min-width: 1200px){
	.system-login-shell {
		padding: 0 32px;
	}
}
