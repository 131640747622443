@import (reference) "~rev-less/global/mixins";
@import (reference) "~rev-less/global/variables";

@mobileMaxWidth: @viewPortMedWidth - 1px;

.root {
	min-height: 1px; // for IE / Edge

	:global([slot='mainContent']) {
		display: block;
		height: 100%;
	}
}

.mainContentSlot {
	position: relative;

	@media (max-width: @mobileMaxWidth) {
		> :global([slot='mainContent']) {
			.AbsoluteFill;
			overflow: hidden;
		}
	}
}

.mainContentSidebarOpen {
	@media (max-width: @mobileMaxWidth) {
		.flexItem(none);
	}
}