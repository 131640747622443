/*!
 *
 *  Project:  GLYPHICONS HALFLINGS
 *  Author:   Jan Kovarik - www.glyphicons.com
 *  Twitter:  @glyphicons
 *
 */
@font-face {
  font-family: 'Glyphicons Regular';
  src: url('../fonts/glyphicons-regular.eot');
  src: url('../fonts/glyphicons-regular.eot?#iefix') format('embedded-opentype'), url('../fonts/glyphicons-regular.woff') format('woff'), url('../fonts/glyphicons-regular.ttf') format('truetype'), url('../fonts/glyphicons-regular.svg#glyphiconsregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

/* edited to match bootstrap */
.glyphicons {
	position: relative;
	top: 1px;
	display: inline-block;
	font-family: 'Glyphicons Regular';
	-webkit-font-smoothing: antialiased;
	font-style: normal;
	font-weight: normal;
	line-height: 1;
}

.glyphicons.white:before {
  color: #fff;
}
.glyphicons.glass:before {
  content: "\E001";
}
.glyphicons.leaf:before {
  content: "\E002";
}
.glyphicons.dog:before {
  content: "\1F415";
}
.glyphicons.user:before {
  content: "\E004";
}
.glyphicons.girl:before {
  content: "\1F467";
}
.glyphicons.car:before {
  content: "\E006";
}
.glyphicons.user_add:before {
  content: "\E007";
}
.glyphicons.user_remove:before {
  content: "\E008";
}
.glyphicons.film:before {
  content: "\E009";
}
.glyphicons.magic:before {
  content: "\E010";
}
.glyphicons.envelope:before {
  content: "\2709";
}
.glyphicons.camera:before {
  content: "\1F4F7";
}
.glyphicons.heart:before {
  content: "\E013";
}
.glyphicons.beach_umbrella:before {
  content: "\E014";
}
.glyphicons.train:before {
  content: "\1F686";
}
.glyphicons.print:before {
  content: "\E016";
}
.glyphicons.bin:before {
  content: "\E017";
}
.glyphicons.music:before {
  content: "\E018";
}
.glyphicons.note:before {
  content: "\E019";
}
.glyphicons.heart_empty:before {
  content: "\E020";
}
.glyphicons.home:before {
  content: "\E021";
}
.glyphicons.snowflake:before {
  content: "\2744";
}
.glyphicons.fire:before {
  content: "\1F525";
}
.glyphicons.magnet:before {
  content: "\E024";
}
.glyphicons.parents:before {
  content: "\E025";
}
.glyphicons.binoculars:before {
  content: "\E026";
}
.glyphicons.road:before {
  content: "\E027";
}
.glyphicons.search:before {
  content: "\E028";
}
.glyphicons.cars:before {
  content: "\E029";
}
.glyphicons.notes_2:before {
  content: "\E030";
}
.glyphicons.pencil:before {
  content: "\270F";
}
.glyphicons.bus:before {
  content: "\1F68C";
}
.glyphicons.wifi_alt:before {
  content: "\E033";
}
.glyphicons.luggage:before {
  content: "\E034";
}
.glyphicons.old_man:before {
  content: "\E035";
}
.glyphicons.woman:before {
  content: "\1F469";
}
.glyphicons.file:before {
  content: "\E037";
}
.glyphicons.coins:before {
  content: "\E038";
}
.glyphicons.airplane:before {
  content: "\2708";
}
.glyphicons.notes:before {
  content: "\E040";
}
.glyphicons.stats:before {
  content: "\E041";
}
.glyphicons.charts:before {
  content: "\E042";
}
.glyphicons.pie_chart:before {
  content: "\E043";
}
.glyphicons.group:before {
  content: "\E044";
}
.glyphicons.keys:before {
  content: "\E045";
}
.glyphicons.calendar:before {
  content: "\1F4C5";
}
.glyphicons.router:before {
  content: "\E047";
}
.glyphicons.camera_small:before {
  content: "\E048";
}
.glyphicons.dislikes:before {
  content: "\E049";
}
.glyphicons.star:before {
  content: "\E050";
}
.glyphicons.link:before {
  content: "\E051";
}
.glyphicons.eye_open:before {
  content: "\E052";
}
.glyphicons.eye_close:before {
  content: "\E053";
}
.glyphicons.alarm:before {
  content: "\E054";
}
.glyphicons.clock:before {
  content: "\E055";
}
.glyphicons.stopwatch:before {
  content: "\E056";
}
.glyphicons.projector:before {
  content: "\E057";
}
.glyphicons.history:before {
  content: "\E058";
}
.glyphicons.truck:before {
  content: "\E059";
}
.glyphicons.cargo:before {
  content: "\E060";
}
.glyphicons.compass:before {
  content: "\E061";
}
.glyphicons.keynote:before {
  content: "\E062";
}
.glyphicons.paperclip:before {
  content: "\1F4CE";
}
.glyphicons.power:before {
  content: "\E064";
}
.glyphicons.lightbulb:before {
  content: "\E065";
}
.glyphicons.tag:before {
  content: "\E066";
}
.glyphicons.tags:before {
  content: "\E067";
}
.glyphicons.cleaning:before {
  content: "\E068";
}
.glyphicons.ruller:before {
  content: "\E069";
}
.glyphicons.gift:before {
  content: "\E070";
}
.glyphicons.umbrella:before {
  content: "\2602";
}
.glyphicons.book:before {
  content: "\E072";
}
.glyphicons.bookmark:before {
  content: "\1F516";
}
.glyphicons.wifi:before {
  content: "\E074";
}
.glyphicons.cup:before {
  content: "\E075";
}
.glyphicons.stroller:before {
  content: "\E076";
}
.glyphicons.headphones:before {
  content: "\E077";
}
.glyphicons.headset:before {
  content: "\E078";
}
.glyphicons.warning_sign:before {
  content: "\E079";
}
.glyphicons.signal:before {
  content: "\E080";
}
.glyphicons.retweet:before {
  content: "\E081";
}
.glyphicons.refresh:before {
  content: "\E082";
}
.glyphicons.roundabout:before {
  content: "\E083";
}
.glyphicons.random:before {
  content: "\E084";
}
.glyphicons.heat:before {
  content: "\E085";
}
.glyphicons.repeat:before {
  content: "\E086";
}
.glyphicons.display:before {
  content: "\E087";
}
.glyphicons.log_book:before {
  content: "\E088";
}
.glyphicons.address_book:before {
  content: "\E089";
}
.glyphicons.building:before {
  content: "\E090";
}
.glyphicons.eyedropper:before {
  content: "\E091";
}
.glyphicons.adjust:before {
  content: "\E092";
}
.glyphicons.tint:before {
  content: "\E093";
}
.glyphicons.crop:before {
  content: "\E094";
}
.glyphicons.vector_path_square:before {
  content: "\E095";
}
.glyphicons.vector_path_circle:before {
  content: "\E096";
}
.glyphicons.vector_path_polygon:before {
  content: "\E097";
}
.glyphicons.vector_path_line:before {
  content: "\E098";
}
.glyphicons.vector_path_curve:before {
  content: "\E099";
}
.glyphicons.vector_path_all:before {
  content: "\E100";
}
.glyphicons.font:before {
  content: "\E101";
}
.glyphicons.italic:before {
  content: "\E102";
}
.glyphicons.bold:before {
  content: "\E103";
}
.glyphicons.text_underline:before {
  content: "\E104";
}
.glyphicons.text_strike:before {
  content: "\E105";
}
.glyphicons.text_height:before {
  content: "\E106";
}
.glyphicons.text_width:before {
  content: "\E107";
}
.glyphicons.text_resize:before {
  content: "\E108";
}
.glyphicons.left_indent:before {
  content: "\E109";
}
.glyphicons.right_indent:before {
  content: "\E110";
}
.glyphicons.align_left:before {
  content: "\E111";
}
.glyphicons.align_center:before {
  content: "\E112";
}
.glyphicons.align_right:before {
  content: "\E113";
}
.glyphicons.justify:before {
  content: "\E114";
}
.glyphicons.list:before {
  content: "\E115";
}
.glyphicons.text_smaller:before {
  content: "\E116";
}
.glyphicons.text_bigger:before {
  content: "\E117";
}
.glyphicons.embed:before {
  content: "\E118";
}
.glyphicons.embed_close:before {
  content: "\E119";
}
.glyphicons.table:before {
  content: "\E120";
}
.glyphicons.message_full:before {
  content: "\E121";
}
.glyphicons.message_empty:before {
  content: "\E122";
}
.glyphicons.message_in:before {
  content: "\E123";
}
.glyphicons.message_out:before {
  content: "\E124";
}
.glyphicons.message_plus:before {
  content: "\E125";
}
.glyphicons.message_minus:before {
  content: "\E126";
}
.glyphicons.message_ban:before {
  content: "\E127";
}
.glyphicons.message_flag:before {
  content: "\E128";
}
.glyphicons.message_lock:before {
  content: "\E129";
}
.glyphicons.message_new:before {
  content: "\E130";
}
.glyphicons.inbox:before {
  content: "\E131";
}
.glyphicons.inbox_plus:before {
  content: "\E132";
}
.glyphicons.inbox_minus:before {
  content: "\E133";
}
.glyphicons.inbox_lock:before {
  content: "\E134";
}
.glyphicons.inbox_in:before {
  content: "\E135";
}
.glyphicons.inbox_out:before {
  content: "\E136";
}
.glyphicons.cogwheel:before {
  content: "\E137";
}
.glyphicons.cogwheels:before {
  content: "\E138";
}
.glyphicons.picture:before {
  content: "\E139";
}
.glyphicons.adjust_alt:before {
  content: "\E140";
}
.glyphicons.database_lock:before {
  content: "\E141";
}
.glyphicons.database_plus:before {
  content: "\E142";
}
.glyphicons.database_minus:before {
  content: "\E143";
}
.glyphicons.database_ban:before {
  content: "\E144";
}
.glyphicons.folder_open:before {
  content: "\E145";
}
.glyphicons.folder_plus:before {
  content: "\E146";
}
.glyphicons.folder_minus:before {
  content: "\E147";
}
.glyphicons.folder_lock:before {
  content: "\E148";
}
.glyphicons.folder_flag:before {
  content: "\E149";
}
.glyphicons.folder_new:before {
  content: "\E150";
}
.glyphicons.edit:before {
  content: "\E151";
}
.glyphicons.new_window:before {
  content: "\E152";
}
.glyphicons.check:before {
  content: "\E153";
}
.glyphicons.unchecked:before {
  content: "\E154";
}
.glyphicons.more_windows:before {
  content: "\E155";
}
.glyphicons.show_big_thumbnails:before {
  content: "\E156";
}
.glyphicons.show_thumbnails:before {
  content: "\E157";
}
.glyphicons.show_thumbnails_with_lines:before {
  content: "\E158";
}
.glyphicons.show_lines:before {
  content: "\E159";
}
.glyphicons.playlist:before {
  content: "\E160";
}
.glyphicons.imac:before {
  content: "\E161";
}
.glyphicons.macbook:before {
  content: "\E162";
}
.glyphicons.ipad:before {
  content: "\E163";
}
.glyphicons.iphone:before {
  content: "\E164";
}
.glyphicons.iphone_transfer:before {
  content: "\E165";
}
.glyphicons.iphone_exchange:before {
  content: "\E166";
}
.glyphicons.ipod:before {
  content: "\E167";
}
.glyphicons.ipod_shuffle:before {
  content: "\E168";
}
.glyphicons.ear_plugs:before {
  content: "\E169";
}
.glyphicons.record:before {
  content: "\E170";
}
.glyphicons.step_backward:before {
  content: "\E171";
}
.glyphicons.fast_backward:before {
  content: "\E172";
}
.glyphicons.rewind:before {
  content: "\E173";
}
.glyphicons.play:before {
  content: "\E174";
}
.glyphicons.pause:before {
  content: "\E175";
}
.glyphicons.stop:before {
  content: "\E176";
}
.glyphicons.forward:before {
  content: "\E177";
}
.glyphicons.fast_forward:before {
  content: "\E178";
}
.glyphicons.step_forward:before {
  content: "\E179";
}
.glyphicons.eject:before {
  content: "\E180";
}
.glyphicons.facetime_video:before {
  content: "\E181";
}
.glyphicons.download_alt:before {
  content: "\E182";
}
.glyphicons.mute:before {
  content: "\E183";
}
.glyphicons.volume_down:before {
  content: "\E184";
}
.glyphicons.volume_up:before {
  content: "\E185";
}
.glyphicons.screenshot:before {
  content: "\E186";
}
.glyphicons.move:before {
  content: "\E187";
}
.glyphicons.more:before {
  content: "\E188";
}
.glyphicons.brightness_reduce:before {
  content: "\E189";
}
.glyphicons.brightness_increase:before {
  content: "\E190";
}
.glyphicons.circle_plus:before {
  content: "\E191";
}
.glyphicons.circle_minus:before {
  content: "\E192";
}
.glyphicons.circle_remove:before {
  content: "\E193";
}
.glyphicons.circle_ok:before {
  content: "\E194";
}
.glyphicons.circle_question_mark:before {
  content: "\E195";
}
.glyphicons.circle_info:before {
  content: "\E196";
}
.glyphicons.circle_exclamation_mark:before {
  content: "\E197";
}
.glyphicons.remove:before {
  content: "\E198";
}
.glyphicons.ok:before {
  content: "\E199";
}
.glyphicons.ban:before {
  content: "\E200";
}
.glyphicons.download:before {
  content: "\E201";
}
.glyphicons.upload:before {
  content: "\E202";
}
.glyphicons.shopping_cart:before {
  content: "\E203";
}
.glyphicons.lock:before {
  content: "\1F512";
}
.glyphicons.unlock:before {
  content: "\E205";
}
.glyphicons.electricity:before {
  content: "\E206";
}
.glyphicons.ok_2:before {
  content: "\E207";
}
.glyphicons.remove_2:before {
  content: "\E208";
}
.glyphicons.cart_out:before {
  content: "\E209";
}
.glyphicons.cart_in:before {
  content: "\E210";
}
.glyphicons.left_arrow:before {
  content: "\E211";
}
.glyphicons.right_arrow:before {
  content: "\E212";
}
.glyphicons.down_arrow:before {
  content: "\E213";
}
.glyphicons.up_arrow:before {
  content: "\E214";
}
.glyphicons.resize_small:before {
  content: "\E215";
}
.glyphicons.resize_full:before {
  content: "\E216";
}
.glyphicons.circle_arrow_left:before {
  content: "\E217";
}
.glyphicons.circle_arrow_right:before {
  content: "\E218";
}
.glyphicons.circle_arrow_top:before {
  content: "\E219";
}
.glyphicons.circle_arrow_down:before {
  content: "\E220";
}
.glyphicons.play_button:before {
  content: "\E221";
}
.glyphicons.unshare:before {
  content: "\E222";
}
.glyphicons.share:before {
  content: "\E223";
}
.glyphicons.chevron-right:before {
  content: "\E224";
}
.glyphicons.chevron-left:before {
  content: "\E225";
}
.glyphicons.bluetooth:before {
  content: "\E226";
}
.glyphicons.euro:before {
  content: "\20AC";
}
.glyphicons.usd:before {
  content: "\E228";
}
.glyphicons.gbp:before {
  content: "\E229";
}
.glyphicons.retweet_2:before {
  content: "\E230";
}
.glyphicons.moon:before {
  content: "\E231";
}
.glyphicons.sun:before {
  content: "\2609";
}
.glyphicons.cloud:before {
  content: "\2601";
}
.glyphicons.direction:before {
  content: "\E234";
}
.glyphicons.brush:before {
  content: "\E235";
}
.glyphicons.pen:before {
  content: "\E236";
}
.glyphicons.zoom_in:before {
  content: "\E237";
}
.glyphicons.zoom_out:before {
  content: "\E238";
}
.glyphicons.pin:before {
  content: "\E239";
}
.glyphicons.albums:before {
  content: "\E240";
}
.glyphicons.rotation_lock:before {
  content: "\E241";
}
.glyphicons.flash:before {
  content: "\E242";
}
.glyphicons.google_maps:before {
  content: "\E243";
}
.glyphicons.anchor:before {
  content: "\2693";
}
.glyphicons.conversation:before {
  content: "\E245";
}
.glyphicons.chat:before {
  content: "\E246";
}
.glyphicons.male:before {
  content: "\E247";
}
.glyphicons.female:before {
  content: "\E248";
}
.glyphicons.asterisk:before {
  content: "\002A";
}
.glyphicons.divide:before {
  content: "\00F7";
}
.glyphicons.snorkel_diving:before {
  content: "\E251";
}
.glyphicons.scuba_diving:before {
  content: "\E252";
}
.glyphicons.oxygen_bottle:before {
  content: "\E253";
}
.glyphicons.fins:before {
  content: "\E254";
}
.glyphicons.fishes:before {
  content: "\E255";
}
.glyphicons.boat:before {
  content: "\E256";
}
.glyphicons.delete:before {
  content: "\E257";
}
.glyphicons.sheriffs_star:before {
  content: "\E258";
}
.glyphicons.qrcode:before {
  content: "\E259";
}
.glyphicons.barcode:before {
  content: "\E260";
}
.glyphicons.pool:before {
  content: "\E261";
}
.glyphicons.buoy:before {
  content: "\E262";
}
.glyphicons.spade:before {
  content: "\E263";
}
.glyphicons.bank:before {
  content: "\1F3E6";
}
.glyphicons.vcard:before {
  content: "\E265";
}
.glyphicons.electrical_plug:before {
  content: "\E266";
}
.glyphicons.flag:before {
  content: "\E267";
}
.glyphicons.credit_card:before {
  content: "\E268";
}
.glyphicons.keyboard-wireless:before {
  content: "\E269";
}
.glyphicons.keyboard-wired:before {
  content: "\E270";
}
.glyphicons.shield:before {
  content: "\E271";
}
.glyphicons.ring:before {
  content: "\02DA";
}
.glyphicons.cake:before {
  content: "\E273";
}
.glyphicons.drink:before {
  content: "\E274";
}
.glyphicons.beer:before {
  content: "\E275";
}
.glyphicons.fast_food:before {
  content: "\E276";
}
.glyphicons.cutlery:before {
  content: "\E277";
}
.glyphicons.pizza:before {
  content: "\E278";
}
.glyphicons.birthday_cake:before {
  content: "\E279";
}
.glyphicons.tablet:before {
  content: "\E280";
}
.glyphicons.settings:before {
  content: "\E281";
}
.glyphicons.bullets:before {
  content: "\E282";
}
.glyphicons.cardio:before {
  content: "\E283";
}
.glyphicons.t-shirt:before {
  content: "\E284";
}
.glyphicons.pants:before {
  content: "\E285";
}
.glyphicons.sweater:before {
  content: "\E286";
}
.glyphicons.fabric:before {
  content: "\E287";
}
.glyphicons.leather:before {
  content: "\E288";
}
.glyphicons.scissors:before {
  content: "\E289";
}
.glyphicons.bomb:before {
  content: "\1F4A3";
}
.glyphicons.skull:before {
  content: "\1F480";
}
.glyphicons.celebration:before {
  content: "\E292";
}
.glyphicons.tea_kettle:before {
  content: "\E293";
}
.glyphicons.french_press:before {
  content: "\E294";
}
.glyphicons.coffe_cup:before {
  content: "\E295";
}
.glyphicons.pot:before {
  content: "\E296";
}
.glyphicons.grater:before {
  content: "\E297";
}
.glyphicons.kettle:before {
  content: "\E298";
}
.glyphicons.hospital:before {
  content: "\1F3E5";
}
.glyphicons.hospital_h:before {
  content: "\E300";
}
.glyphicons.microphone:before {
  content: "\1F3A4";
}
.glyphicons.webcam:before {
  content: "\E302";
}
.glyphicons.temple_christianity_church:before {
  content: "\E303";
}
.glyphicons.temple_islam:before {
  content: "\E304";
}
.glyphicons.temple_hindu:before {
  content: "\E305";
}
.glyphicons.temple_buddhist:before {
  content: "\E306";
}
.glyphicons.bicycle:before {
  content: "\1F6B2";
}
.glyphicons.life_preserver:before {
  content: "\E308";
}
.glyphicons.share_alt:before {
  content: "\E309";
}
.glyphicons.comments:before {
  content: "\E310";
}
.glyphicons.flower:before {
  content: "\2698";
}
.glyphicons.baseball:before {
  content: "\26BE";
}
.glyphicons.rugby:before {
  content: "\E313";
}
.glyphicons.ax:before {
  content: "\E314";
}
.glyphicons.table_tennis:before {
  content: "\E315";
}
.glyphicons.bowling:before {
  content: "\1F3B3";
}
.glyphicons.tree_conifer:before {
  content: "\E317";
}
.glyphicons.tree_deciduous:before {
  content: "\E318";
}
.glyphicons.more_items:before {
  content: "\E319";
}
.glyphicons.sort:before {
  content: "\E320";
}
.glyphicons.filter:before {
  content: "\E321";
}
.glyphicons.gamepad:before {
  content: "\E322";
}
.glyphicons.playing_dices:before {
  content: "\E323";
}
.glyphicons.calculator:before {
  content: "\E324";
}
.glyphicons.tie:before {
  content: "\E325";
}
.glyphicons.wallet:before {
  content: "\E326";
}
.glyphicons.piano:before {
  content: "\E327";
}
.glyphicons.sampler:before {
  content: "\E328";
}
.glyphicons.podium:before {
  content: "\E329";
}
.glyphicons.soccer_ball:before {
  content: "\E330";
}
.glyphicons.blog:before {
  content: "\E331";
}
.glyphicons.dashboard:before {
  content: "\E332";
}
.glyphicons.certificate:before {
  content: "\E333";
}
.glyphicons.bell:before {
  content: "\1F514";
}
.glyphicons.candle:before {
  content: "\E335";
}
.glyphicons.pushpin:before {
  content: "\1F4CC";
}
.glyphicons.iphone_shake:before {
  content: "\E337";
}
.glyphicons.pin_flag:before {
  content: "\E338";
}
.glyphicons.turtle:before {
  content: "\1F422";
}
.glyphicons.rabbit:before {
  content: "\1F407";
}
.glyphicons.globe:before {
  content: "\E341";
}
.glyphicons.briefcase:before {
  content: "\1F4BC";
}
.glyphicons.hdd:before {
  content: "\E343";
}
.glyphicons.thumbs_up:before {
  content: "\E344";
}
.glyphicons.thumbs_down:before {
  content: "\E345";
}
.glyphicons.hand_right:before {
  content: "\E346";
}
.glyphicons.hand_left:before {
  content: "\E347";
}
.glyphicons.hand_up:before {
  content: "\E348";
}
.glyphicons.hand_down:before {
  content: "\E349";
}
.glyphicons.fullscreen:before {
  content: "\E350";
}
.glyphicons.shopping_bag:before {
  content: "\E351";
}
.glyphicons.book_open:before {
  content: "\E352";
}
.glyphicons.nameplate:before {
  content: "\E353";
}
.glyphicons.nameplate_alt:before {
  content: "\E354";
}
.glyphicons.vases:before {
  content: "\E355";
}
.glyphicons.bullhorn:before {
  content: "\E356";
}
.glyphicons.dumbbell:before {
  content: "\E357";
}
.glyphicons.suitcase:before {
  content: "\E358";
}
.glyphicons.file_import:before {
  content: "\E359";
}
.glyphicons.file_export:before {
  content: "\E360";
}
.glyphicons.bug:before {
  content: "\1F41B";
}
.glyphicons.crown:before {
  content: "\1F451";
}
.glyphicons.smoking:before {
  content: "\E363";
}
.glyphicons.cloud-upload:before {
  content: "\E364";
}
.glyphicons.cloud-download:before {
  content: "\E365";
}
.glyphicons.restart:before {
  content: "\E366";
}
.glyphicons.security_camera:before {
  content: "\E367";
}
.glyphicons.expand:before {
  content: "\E368";
}
.glyphicons.collapse:before {
  content: "\E369";
}
.glyphicons.collapse_top:before {
  content: "\E370";
}
.glyphicons.globe_af:before {
  content: "\E371";
}
.glyphicons.global:before {
  content: "\E372";
}
.glyphicons.spray:before {
  content: "\E373";
}
.glyphicons.nails:before {
  content: "\E374";
}
.glyphicons.claw_hammer:before {
  content: "\E375";
}
.glyphicons.classic_hammer:before {
  content: "\E376";
}
.glyphicons.hand_saw:before {
  content: "\E377";
}
.glyphicons.riflescope:before {
  content: "\E378";
}
.glyphicons.electrical_socket_eu:before {
  content: "\E379";
}
.glyphicons.electrical_socket_us:before {
  content: "\E380";
}
.glyphicons.message_forward:before {
  content: "\E381";
}
.glyphicons.coat_hanger:before {
  content: "\E382";
}
.glyphicons.dress:before {
  content: "\1F457";
}
.glyphicons.bathrobe:before {
  content: "\E384";
}
.glyphicons.shirt:before {
  content: "\E385";
}
.glyphicons.underwear:before {
  content: "\E386";
}
.glyphicons.log_in:before {
  content: "\E387";
}
.glyphicons.log_out:before {
  content: "\E388";
}
.glyphicons.exit:before {
  content: "\E389";
}
.glyphicons.new_window_alt:before {
  content: "\E390";
}
.glyphicons.video_sd:before {
  content: "\E391";
}
.glyphicons.video_hd:before {
  content: "\E392";
}
.glyphicons.subtitles:before {
  content: "\E393";
}
.glyphicons.sound_stereo:before {
  content: "\E394";
}
.glyphicons.sound_dolby:before {
  content: "\E395";
}
.glyphicons.sound_5_1:before {
  content: "\E396";
}
.glyphicons.sound_6_1:before {
  content: "\E397";
}
.glyphicons.sound_7_1:before {
  content: "\E398";
}
.glyphicons.copyright_mark:before {
  content: "\E399";
}
.glyphicons.registration_mark:before {
  content: "\E400";
}
.glyphicons.radar:before {
  content: "\E401";
}
.glyphicons.skateboard:before {
  content: "\E402";
}
.glyphicons.golf_course:before {
  content: "\E403";
}
.glyphicons.sorting:before {
  content: "\E404";
}
.glyphicons.sort-by-alphabet:before {
  content: "\E405";
}
.glyphicons.sort-by-alphabet-alt:before {
  content: "\E406";
}
.glyphicons.sort-by-order:before {
  content: "\E407";
}
.glyphicons.sort-by-order-alt:before {
  content: "\E408";
}
.glyphicons.sort-by-attributes:before {
  content: "\E409";
}
.glyphicons.sort-by-attributes-alt:before {
  content: "\E410";
}
.glyphicons.compressed:before {
  content: "\E411";
}
.glyphicons.package:before {
  content: "\1F4E6";
}
.glyphicons.cloud_plus:before {
  content: "\E413";
}
.glyphicons.cloud_minus:before {
  content: "\E414";
}
.glyphicons.disk_save:before {
  content: "\E415";
}
.glyphicons.disk_open:before {
  content: "\E416";
}
.glyphicons.disk_saved:before {
  content: "\E417";
}
.glyphicons.disk_remove:before {
  content: "\E418";
}
.glyphicons.disk_import:before {
  content: "\E419";
}
.glyphicons.disk_export:before {
  content: "\E420";
}
.glyphicons.tower:before {
  content: "\E421";
}
.glyphicons.send:before {
  content: "\E422";
}
.glyphicons.git_branch:before {
  content: "\E423";
}
.glyphicons.git_create:before {
  content: "\E424";
}
.glyphicons.git_private:before {
  content: "\E425";
}
.glyphicons.git_delete:before {
  content: "\E426";
}
.glyphicons.git_merge:before {
  content: "\E427";
}
.glyphicons.git_pull_request:before {
  content: "\E428";
}
.glyphicons.git_compare:before {
  content: "\E429";
}
.glyphicons.git_commit:before {
  content: "\E430";
}
.glyphicons.construction_cone:before {
  content: "\E431";
}
.glyphicons.shoe_steps:before {
  content: "\E432";
}
.glyphicons.plus:before {
  content: "\002B";
}
.glyphicons.minus:before {
  content: "\2212";
}
.glyphicons.redo:before {
  content: "\E435";
}
.glyphicons.undo:before {
  content: "\E436";
}
.glyphicons.golf:before {
  content: "\E437";
}
.glyphicons.hockey:before {
  content: "\E438";
}
.glyphicons.pipe:before {
  content: "\E439";
}
.glyphicons.wrench:before {
  content: "\1F527";
}
.glyphicons.folder_closed:before {
  content: "\E441";
}
.glyphicons.phone_alt:before {
  content: "\E442";
}
.glyphicons.earphone:before {
  content: "\E443";
}
.glyphicons.floppy_disk:before {
  content: "\E444";
}
.glyphicons.floppy_saved:before {
  content: "\E445";
}
.glyphicons.floppy_remove:before {
  content: "\E446";
}
.glyphicons.floppy_save:before {
  content: "\E447";
}
.glyphicons.floppy_open:before {
  content: "\E448";
}
.glyphicons.translate:before {
  content: "\E449";
}
.glyphicons.fax:before {
  content: "\E450";
}
.glyphicons.factory:before {
  content: "\1F3ED";
}
.glyphicons.shop_window:before {
  content: "\E452";
}
.glyphicons.shop:before {
  content: "\E453";
}
.glyphicons.kiosk:before {
  content: "\E454";
}
.glyphicons.kiosk_wheels:before {
  content: "\E455";
}
.glyphicons.kiosk_light:before {
  content: "\E456";
}
.glyphicons.kiosk_food:before {
  content: "\E457";
}
.glyphicons.transfer:before {
  content: "\E458";
}
.glyphicons.money:before {
  content: "\E459";
}
.glyphicons.header:before {
  content: "\E460";
}
.glyphicons.blacksmith:before {
  content: "\E461";
}
.glyphicons.saw_blade:before {
  content: "\E462";
}
.glyphicons.basketball:before {
  content: "\E463";
}
.glyphicons.server:before {
  content: "\E464";
}
.glyphicons.server_plus:before {
  content: "\E465";
}
.glyphicons.server_minus:before {
  content: "\E466";
}
.glyphicons.server_ban:before {
  content: "\E467";
}
.glyphicons.server_flag:before {
  content: "\E468";
}
.glyphicons.server_lock:before {
  content: "\E469";
}
.glyphicons.server_new:before {
  content: "\E470";
}
.glyphicons-icon {
  display: inline-block;
  width: 48px;
  height: 48px;
  margin: 0 8px 0 0;
  line-height: 14px;
  vertical-align: text-top;
  background-image: url(../images/glyphicons.svg);
  background-position: 0 0;
  background-repeat: no-repeat;
  vertical-align: top;
  *display: inline;
  *zoom: 1;
  *margin-right: .3em;
}
.glyphicons-icon _:-o-prefocus,
.glyphicons-icon {
  background-image: url(../images/glyphicons.png);
}
.no-inlinesvg .glyphicons-icon {
  background-image: url(../images/glyphicons.png);
}
.glyphicons-icon.white {
  background-image: url(../images/glyphicons-white.svg);
}
.glyphicons-icon.white _:-o-prefocus,
.glyphicons-icon.white {
  background-image: url(../images/glyphicons-white.png);
}
.no-inlinesvg .glyphicons-icon.white {
  background-image: url(../images/glyphicons-white.png);
}
.glyphicons-icon.glass {
  background-position: 4px 11px;
}
.glyphicons-icon.leaf {
  background-position: -44px 11px;
}
.glyphicons-icon.dog {
  background-position: -92px 11px;
}
.glyphicons-icon.user {
  background-position: -140px 11px;
}
.glyphicons-icon.girl {
  background-position: -188px 11px;
}
.glyphicons-icon.car {
  background-position: -236px 11px;
}
.glyphicons-icon.user_add {
  background-position: -284px 11px;
}
.glyphicons-icon.user_remove {
  background-position: -332px 11px;
}
.glyphicons-icon.film {
  background-position: -380px 11px;
}
.glyphicons-icon.magic {
  background-position: -428px 11px;
}
.glyphicons-icon.envelope {
  background-position: 4px -37px;
}
.glyphicons-icon.camera {
  background-position: -44px -37px;
}
.glyphicons-icon.heart {
  background-position: -92px -37px;
}
.glyphicons-icon.beach_umbrella {
  background-position: -140px -37px;
}
.glyphicons-icon.train {
  background-position: -188px -37px;
}
.glyphicons-icon.print {
  background-position: -236px -37px;
}
.glyphicons-icon.bin {
  background-position: -284px -37px;
}
.glyphicons-icon.music {
  background-position: -332px -37px;
}
.glyphicons-icon.note {
  background-position: -380px -37px;
}
.glyphicons-icon.heart_empty {
  background-position: -428px -37px;
}
.glyphicons-icon.home {
  background-position: 4px -85px;
}
.glyphicons-icon.snowflake {
  background-position: -44px -85px;
}
.glyphicons-icon.fire {
  background-position: -92px -85px;
}
.glyphicons-icon.magnet {
  background-position: -140px -85px;
}
.glyphicons-icon.parents {
  background-position: -188px -85px;
}
.glyphicons-icon.binoculars {
  background-position: -236px -85px;
}
.glyphicons-icon.road {
  background-position: -284px -85px;
}
.glyphicons-icon.search {
  background-position: -332px -85px;
}
.glyphicons-icon.cars {
  background-position: -380px -85px;
}
.glyphicons-icon.notes_2 {
  background-position: -428px -85px;
}
.glyphicons-icon.pencil {
  background-position: 4px -133px;
}
.glyphicons-icon.bus {
  background-position: -44px -133px;
}
.glyphicons-icon.wifi_alt {
  background-position: -92px -133px;
}
.glyphicons-icon.luggage {
  background-position: -140px -133px;
}
.glyphicons-icon.old_man {
  background-position: -188px -133px;
}
.glyphicons-icon.woman {
  background-position: -236px -133px;
}
.glyphicons-icon.file {
  background-position: -284px -133px;
}
.glyphicons-icon.coins {
  background-position: -332px -133px;
}
.glyphicons-icon.airplane {
  background-position: -380px -133px;
}
.glyphicons-icon.notes {
  background-position: -428px -133px;
}
.glyphicons-icon.stats {
  background-position: 4px -181px;
}
.glyphicons-icon.charts {
  background-position: -44px -181px;
}
.glyphicons-icon.pie_chart {
  background-position: -92px -181px;
}
.glyphicons-icon.group {
  background-position: -140px -181px;
}
.glyphicons-icon.keys {
  background-position: -188px -181px;
}
.glyphicons-icon.calendar {
  background-position: -236px -181px;
}
.glyphicons-icon.router {
  background-position: -284px -181px;
}
.glyphicons-icon.camera_small {
  background-position: -332px -181px;
}
.glyphicons-icon.dislikes {
  background-position: -380px -181px;
}
.glyphicons-icon.star {
  background-position: -428px -181px;
}
.glyphicons-icon.link {
  background-position: 4px -229px;
}
.glyphicons-icon.eye_open {
  background-position: -44px -229px;
}
.glyphicons-icon.eye_close {
  background-position: -92px -229px;
}
.glyphicons-icon.alarm {
  background-position: -140px -229px;
}
.glyphicons-icon.clock {
  background-position: -188px -229px;
}
.glyphicons-icon.stopwatch {
  background-position: -236px -229px;
}
.glyphicons-icon.projector {
  background-position: -284px -229px;
}
.glyphicons-icon.history {
  background-position: -332px -229px;
}
.glyphicons-icon.truck {
  background-position: -380px -229px;
}
.glyphicons-icon.cargo {
  background-position: -428px -229px;
}
.glyphicons-icon.compass {
  background-position: 4px -277px;
}
.glyphicons-icon.keynote {
  background-position: -44px -277px;
}
.glyphicons-icon.paperclip {
  background-position: -92px -277px;
}
.glyphicons-icon.power {
  background-position: -140px -277px;
}
.glyphicons-icon.lightbulb {
  background-position: -188px -277px;
}
.glyphicons-icon.tag {
  background-position: -236px -277px;
}
.glyphicons-icon.tags {
  background-position: -284px -277px;
}
.glyphicons-icon.cleaning {
  background-position: -332px -277px;
}
.glyphicons-icon.ruller {
  background-position: -380px -277px;
}
.glyphicons-icon.gift {
  background-position: -428px -277px;
}
.glyphicons-icon.umbrella {
  background-position: 4px -325px;
}
.glyphicons-icon.book {
  background-position: -44px -325px;
}
.glyphicons-icon.bookmark {
  background-position: -92px -325px;
}
.glyphicons-icon.wifi {
  background-position: -140px -325px;
}
.glyphicons-icon.cup {
  background-position: -188px -325px;
}
.glyphicons-icon.stroller {
  background-position: -236px -325px;
}
.glyphicons-icon.headphones {
  background-position: -284px -325px;
}
.glyphicons-icon.headset {
  background-position: -332px -325px;
}
.glyphicons-icon.warning_sign {
  background-position: -380px -325px;
}
.glyphicons-icon.signal {
  background-position: -428px -325px;
}
.glyphicons-icon.retweet {
  background-position: 4px -373px;
}
.glyphicons-icon.refresh {
  background-position: -44px -373px;
}
.glyphicons-icon.roundabout {
  background-position: -92px -373px;
}
.glyphicons-icon.random {
  background-position: -140px -373px;
}
.glyphicons-icon.heat {
  background-position: -188px -373px;
}
.glyphicons-icon.repeat {
  background-position: -236px -373px;
}
.glyphicons-icon.display {
  background-position: -284px -373px;
}
.glyphicons-icon.log_book {
  background-position: -332px -373px;
}
.glyphicons-icon.address_book {
  background-position: -380px -373px;
}
.glyphicons-icon.building {
  background-position: -428px -373px;
}
.glyphicons-icon.eyedropper {
  background-position: 4px -421px;
}
.glyphicons-icon.adjust {
  background-position: -44px -421px;
}
.glyphicons-icon.tint {
  background-position: -92px -421px;
}
.glyphicons-icon.crop {
  background-position: -140px -421px;
}
.glyphicons-icon.vector_path_square {
  background-position: -188px -421px;
}
.glyphicons-icon.vector_path_circle {
  background-position: -236px -421px;
}
.glyphicons-icon.vector_path_polygon {
  background-position: -284px -421px;
}
.glyphicons-icon.vector_path_line {
  background-position: -332px -421px;
}
.glyphicons-icon.vector_path_curve {
  background-position: -380px -421px;
}
.glyphicons-icon.vector_path_all {
  background-position: -428px -421px;
}
.glyphicons-icon.font {
  background-position: 4px -469px;
}
.glyphicons-icon.italic {
  background-position: -44px -469px;
}
.glyphicons-icon.bold {
  background-position: -92px -469px;
}
.glyphicons-icon.text_underline {
  background-position: -140px -469px;
}
.glyphicons-icon.text_strike {
  background-position: -188px -469px;
}
.glyphicons-icon.text_height {
  background-position: -236px -469px;
}
.glyphicons-icon.text_width {
  background-position: -284px -469px;
}
.glyphicons-icon.text_resize {
  background-position: -332px -469px;
}
.glyphicons-icon.left_indent {
  background-position: -380px -469px;
}
.glyphicons-icon.right_indent {
  background-position: -428px -469px;
}
.glyphicons-icon.align_left {
  background-position: 4px -517px;
}
.glyphicons-icon.align_center {
  background-position: -44px -517px;
}
.glyphicons-icon.align_right {
  background-position: -92px -517px;
}
.glyphicons-icon.justify {
  background-position: -140px -517px;
}
.glyphicons-icon.list {
  background-position: -188px -517px;
}
.glyphicons-icon.text_smaller {
  background-position: -236px -517px;
}
.glyphicons-icon.text_bigger {
  background-position: -284px -517px;
}
.glyphicons-icon.embed {
  background-position: -332px -517px;
}
.glyphicons-icon.embed_close {
  background-position: -380px -517px;
}
.glyphicons-icon.table {
  background-position: -428px -517px;
}
.glyphicons-icon.message_full {
  background-position: 4px -565px;
}
.glyphicons-icon.message_empty {
  background-position: -44px -565px;
}
.glyphicons-icon.message_in {
  background-position: -92px -565px;
}
.glyphicons-icon.message_out {
  background-position: -140px -565px;
}
.glyphicons-icon.message_plus {
  background-position: -188px -565px;
}
.glyphicons-icon.message_minus {
  background-position: -236px -565px;
}
.glyphicons-icon.message_ban {
  background-position: -284px -565px;
}
.glyphicons-icon.message_flag {
  background-position: -332px -565px;
}
.glyphicons-icon.message_lock {
  background-position: -380px -565px;
}
.glyphicons-icon.message_new {
  background-position: -428px -565px;
}
.glyphicons-icon.inbox {
  background-position: 4px -613px;
}
.glyphicons-icon.inbox_plus {
  background-position: -44px -613px;
}
.glyphicons-icon.inbox_minus {
  background-position: -92px -613px;
}
.glyphicons-icon.inbox_lock {
  background-position: -140px -613px;
}
.glyphicons-icon.inbox_in {
  background-position: -188px -613px;
}
.glyphicons-icon.inbox_out {
  background-position: -236px -613px;
}
.glyphicons-icon.cogwheel {
  background-position: -284px -613px;
}
.glyphicons-icon.cogwheels {
  background-position: -332px -613px;
}
.glyphicons-icon.picture {
  background-position: -380px -613px;
}
.glyphicons-icon.adjust_alt {
  background-position: -428px -613px;
}
.glyphicons-icon.database_lock {
  background-position: 4px -661px;
}
.glyphicons-icon.database_plus {
  background-position: -44px -661px;
}
.glyphicons-icon.database_minus {
  background-position: -92px -661px;
}
.glyphicons-icon.database_ban {
  background-position: -140px -661px;
}
.glyphicons-icon.folder_open {
  background-position: -188px -661px;
}
.glyphicons-icon.folder_plus {
  background-position: -236px -661px;
}
.glyphicons-icon.folder_minus {
  background-position: -284px -661px;
}
.glyphicons-icon.folder_lock {
  background-position: -332px -661px;
}
.glyphicons-icon.folder_flag {
  background-position: -380px -661px;
}
.glyphicons-icon.folder_new {
  background-position: -428px -661px;
}
.glyphicons-icon.edit {
  background-position: 4px -709px;
}
.glyphicons-icon.new_window {
  background-position: -44px -709px;
}
.glyphicons-icon.check {
  background-position: -92px -709px;
}
.glyphicons-icon.unchecked {
  background-position: -140px -709px;
}
.glyphicons-icon.more_windows {
  background-position: -188px -709px;
}
.glyphicons-icon.show_big_thumbnails {
  background-position: -236px -709px;
}
.glyphicons-icon.show_thumbnails {
  background-position: -284px -709px;
}
.glyphicons-icon.show_thumbnails_with_lines {
  background-position: -332px -709px;
}
.glyphicons-icon.show_lines {
  background-position: -380px -709px;
}
.glyphicons-icon.playlist {
  background-position: -428px -709px;
}
.glyphicons-icon.imac {
  background-position: 4px -757px;
}
.glyphicons-icon.macbook {
  background-position: -44px -757px;
}
.glyphicons-icon.ipad {
  background-position: -92px -757px;
}
.glyphicons-icon.iphone {
  background-position: -140px -757px;
}
.glyphicons-icon.iphone_transfer {
  background-position: -188px -757px;
}
.glyphicons-icon.iphone_exchange {
  background-position: -236px -757px;
}
.glyphicons-icon.ipod {
  background-position: -284px -757px;
}
.glyphicons-icon.ipod_shuffle {
  background-position: -332px -757px;
}
.glyphicons-icon.ear_plugs {
  background-position: -380px -757px;
}
.glyphicons-icon.record {
  background-position: -428px -757px;
}
.glyphicons-icon.step_backward {
  background-position: 4px -805px;
}
.glyphicons-icon.fast_backward {
  background-position: -44px -805px;
}
.glyphicons-icon.rewind {
  background-position: -92px -805px;
}
.glyphicons-icon.play {
  background-position: -140px -805px;
}
.glyphicons-icon.pause {
  background-position: -188px -805px;
}
.glyphicons-icon.stop {
  background-position: -236px -805px;
}
.glyphicons-icon.forward {
  background-position: -284px -805px;
}
.glyphicons-icon.fast_forward {
  background-position: -332px -805px;
}
.glyphicons-icon.step_forward {
  background-position: -380px -805px;
}
.glyphicons-icon.eject {
  background-position: -428px -805px;
}
.glyphicons-icon.facetime_video {
  background-position: 4px -853px;
}
.glyphicons-icon.download_alt {
  background-position: -44px -853px;
}
.glyphicons-icon.mute {
  background-position: -92px -853px;
}
.glyphicons-icon.volume_down {
  background-position: -140px -853px;
}
.glyphicons-icon.volume_up {
  background-position: -188px -853px;
}
.glyphicons-icon.screenshot {
  background-position: -236px -853px;
}
.glyphicons-icon.move {
  background-position: -284px -853px;
}
.glyphicons-icon.more {
  background-position: -332px -853px;
}
.glyphicons-icon.brightness_reduce {
  background-position: -380px -853px;
}
.glyphicons-icon.brightness_increase {
  background-position: -428px -853px;
}
.glyphicons-icon.circle_plus {
  background-position: 4px -901px;
}
.glyphicons-icon.circle_minus {
  background-position: -44px -901px;
}
.glyphicons-icon.circle_remove {
  background-position: -92px -901px;
}
.glyphicons-icon.circle_ok {
  background-position: -140px -901px;
}
.glyphicons-icon.circle_question_mark {
  background-position: -188px -901px;
}
.glyphicons-icon.circle_info {
  background-position: -236px -901px;
}
.glyphicons-icon.circle_exclamation_mark {
  background-position: -284px -901px;
}
.glyphicons-icon.remove {
  background-position: -332px -901px;
}
.glyphicons-icon.ok {
  background-position: -380px -901px;
}
.glyphicons-icon.ban {
  background-position: -428px -901px;
}
.glyphicons-icon.download {
  background-position: 4px -949px;
}
.glyphicons-icon.upload {
  background-position: -44px -949px;
}
.glyphicons-icon.shopping_cart {
  background-position: -92px -949px;
}
.glyphicons-icon.lock {
  background-position: -140px -949px;
}
.glyphicons-icon.unlock {
  background-position: -188px -949px;
}
.glyphicons-icon.electricity {
  background-position: -236px -949px;
}
.glyphicons-icon.ok_2 {
  background-position: -284px -949px;
}
.glyphicons-icon.remove_2 {
  background-position: -332px -949px;
}
.glyphicons-icon.cart_out {
  background-position: -380px -949px;
}
.glyphicons-icon.cart_in {
  background-position: -428px -949px;
}
.glyphicons-icon.left_arrow {
  background-position: 4px -997px;
}
.glyphicons-icon.right_arrow {
  background-position: -44px -997px;
}
.glyphicons-icon.down_arrow {
  background-position: -92px -997px;
}
.glyphicons-icon.up_arrow {
  background-position: -140px -997px;
}
.glyphicons-icon.resize_small {
  background-position: -188px -997px;
}
.glyphicons-icon.resize_full {
  background-position: -236px -997px;
}
.glyphicons-icon.circle_arrow_left {
  background-position: -284px -997px;
}
.glyphicons-icon.circle_arrow_right {
  background-position: -332px -997px;
}
.glyphicons-icon.circle_arrow_top {
  background-position: -380px -997px;
}
.glyphicons-icon.circle_arrow_down {
  background-position: -428px -997px;
}
.glyphicons-icon.play_button {
  background-position: 4px -1045px;
}
.glyphicons-icon.unshare {
  background-position: -44px -1045px;
}
.glyphicons-icon.share {
  background-position: -92px -1045px;
}
.glyphicons-icon.chevron-right {
  background-position: -140px -1045px;
}
.glyphicons-icon.chevron-left {
  background-position: -188px -1045px;
}
.glyphicons-icon.bluetooth {
  background-position: -236px -1045px;
}
.glyphicons-icon.euro {
  background-position: -284px -1045px;
}
.glyphicons-icon.usd {
  background-position: -332px -1045px;
}
.glyphicons-icon.gbp {
  background-position: -380px -1045px;
}
.glyphicons-icon.retweet_2 {
  background-position: -428px -1045px;
}
.glyphicons-icon.moon {
  background-position: 4px -1093px;
}
.glyphicons-icon.sun {
  background-position: -44px -1093px;
}
.glyphicons-icon.cloud {
  background-position: -92px -1093px;
}
.glyphicons-icon.direction {
  background-position: -140px -1093px;
}
.glyphicons-icon.brush {
  background-position: -188px -1093px;
}
.glyphicons-icon.pen {
  background-position: -236px -1093px;
}
.glyphicons-icon.zoom_in {
  background-position: -284px -1093px;
}
.glyphicons-icon.zoom_out {
  background-position: -332px -1093px;
}
.glyphicons-icon.pin {
  background-position: -380px -1093px;
}
.glyphicons-icon.albums {
  background-position: -428px -1093px;
}
.glyphicons-icon.rotation_lock {
  background-position: 4px -1141px;
}
.glyphicons-icon.flash {
  background-position: -44px -1141px;
}
.glyphicons-icon.google_maps {
  background-position: -92px -1141px;
}
.glyphicons-icon.anchor {
  background-position: -140px -1141px;
}
.glyphicons-icon.conversation {
  background-position: -188px -1141px;
}
.glyphicons-icon.chat {
  background-position: -236px -1141px;
}
.glyphicons-icon.male {
  background-position: -284px -1141px;
}
.glyphicons-icon.female {
  background-position: -332px -1141px;
}
.glyphicons-icon.asterisk {
  background-position: -380px -1141px;
}
.glyphicons-icon.divide {
  background-position: -428px -1141px;
}
.glyphicons-icon.snorkel_diving {
  background-position: 4px -1189px;
}
.glyphicons-icon.scuba_diving {
  background-position: -44px -1189px;
}
.glyphicons-icon.oxygen_bottle {
  background-position: -92px -1189px;
}
.glyphicons-icon.fins {
  background-position: -140px -1189px;
}
.glyphicons-icon.fishes {
  background-position: -188px -1189px;
}
.glyphicons-icon.boat {
  background-position: -236px -1189px;
}
.glyphicons-icon.delete {
  background-position: -284px -1189px;
}
.glyphicons-icon.sheriffs_star {
  background-position: -332px -1189px;
}
.glyphicons-icon.qrcode {
  background-position: -380px -1189px;
}
.glyphicons-icon.barcode {
  background-position: -428px -1189px;
}
.glyphicons-icon.pool {
  background-position: 4px -1237px;
}
.glyphicons-icon.buoy {
  background-position: -44px -1237px;
}
.glyphicons-icon.spade {
  background-position: -92px -1237px;
}
.glyphicons-icon.bank {
  background-position: -140px -1237px;
}
.glyphicons-icon.vcard {
  background-position: -188px -1237px;
}
.glyphicons-icon.electrical_plug {
  background-position: -236px -1237px;
}
.glyphicons-icon.flag {
  background-position: -284px -1237px;
}
.glyphicons-icon.credit_card {
  background-position: -332px -1237px;
}
.glyphicons-icon.keyboard-wireless {
  background-position: -380px -1237px;
}
.glyphicons-icon.keyboard-wired {
  background-position: -428px -1237px;
}
.glyphicons-icon.shield {
  background-position: 4px -1285px;
}
.glyphicons-icon.ring {
  background-position: -44px -1285px;
}
.glyphicons-icon.cake {
  background-position: -92px -1285px;
}
.glyphicons-icon.drink {
  background-position: -140px -1285px;
}
.glyphicons-icon.beer {
  background-position: -188px -1285px;
}
.glyphicons-icon.fast_food {
  background-position: -236px -1285px;
}
.glyphicons-icon.cutlery {
  background-position: -284px -1285px;
}
.glyphicons-icon.pizza {
  background-position: -332px -1285px;
}
.glyphicons-icon.birthday_cake {
  background-position: -380px -1285px;
}
.glyphicons-icon.tablet {
  background-position: -428px -1285px;
}
.glyphicons-icon.settings {
  background-position: 4px -1333px;
}
.glyphicons-icon.bullets {
  background-position: -44px -1333px;
}
.glyphicons-icon.cardio {
  background-position: -92px -1333px;
}
.glyphicons-icon.t-shirt {
  background-position: -140px -1333px;
}
.glyphicons-icon.pants {
  background-position: -188px -1333px;
}
.glyphicons-icon.sweater {
  background-position: -236px -1333px;
}
.glyphicons-icon.fabric {
  background-position: -284px -1333px;
}
.glyphicons-icon.leather {
  background-position: -332px -1333px;
}
.glyphicons-icon.scissors {
  background-position: -380px -1333px;
}
.glyphicons-icon.bomb {
  background-position: -428px -1333px;
}
.glyphicons-icon.skull {
  background-position: 4px -1381px;
}
.glyphicons-icon.celebration {
  background-position: -44px -1381px;
}
.glyphicons-icon.tea_kettle {
  background-position: -92px -1381px;
}
.glyphicons-icon.french_press {
  background-position: -140px -1381px;
}
.glyphicons-icon.coffe_cup {
  background-position: -188px -1381px;
}
.glyphicons-icon.pot {
  background-position: -236px -1381px;
}
.glyphicons-icon.grater {
  background-position: -284px -1381px;
}
.glyphicons-icon.kettle {
  background-position: -332px -1381px;
}
.glyphicons-icon.hospital {
  background-position: -380px -1381px;
}
.glyphicons-icon.hospital_h {
  background-position: -428px -1381px;
}
.glyphicons-icon.microphone {
  background-position: 4px -1429px;
}
.glyphicons-icon.webcam {
  background-position: -44px -1429px;
}
.glyphicons-icon.temple_christianity_church {
  background-position: -92px -1429px;
}
.glyphicons-icon.temple_islam {
  background-position: -140px -1429px;
}
.glyphicons-icon.temple_hindu {
  background-position: -188px -1429px;
}
.glyphicons-icon.temple_buddhist {
  background-position: -236px -1429px;
}
.glyphicons-icon.bicycle {
  background-position: -284px -1429px;
}
.glyphicons-icon.life_preserver {
  background-position: -332px -1429px;
}
.glyphicons-icon.share_alt {
  background-position: -380px -1429px;
}
.glyphicons-icon.comments {
  background-position: -428px -1429px;
}
.glyphicons-icon.flower {
  background-position: 4px -1477px;
}
.glyphicons-icon.baseball {
  background-position: -44px -1477px;
}
.glyphicons-icon.rugby {
  background-position: -92px -1477px;
}
.glyphicons-icon.ax {
  background-position: -140px -1477px;
}
.glyphicons-icon.table_tennis {
  background-position: -188px -1477px;
}
.glyphicons-icon.bowling {
  background-position: -236px -1477px;
}
.glyphicons-icon.tree_conifer {
  background-position: -284px -1477px;
}
.glyphicons-icon.tree_deciduous {
  background-position: -332px -1477px;
}
.glyphicons-icon.more_items {
  background-position: -380px -1477px;
}
.glyphicons-icon.sort {
  background-position: -428px -1477px;
}
.glyphicons-icon.filter {
  background-position: 4px -1525px;
}
.glyphicons-icon.gamepad {
  background-position: -44px -1525px;
}
.glyphicons-icon.playing_dices {
  background-position: -92px -1525px;
}
.glyphicons-icon.calculator {
  background-position: -140px -1525px;
}
.glyphicons-icon.tie {
  background-position: -188px -1525px;
}
.glyphicons-icon.wallet {
  background-position: -236px -1525px;
}
.glyphicons-icon.piano {
  background-position: -284px -1525px;
}
.glyphicons-icon.sampler {
  background-position: -332px -1525px;
}
.glyphicons-icon.podium {
  background-position: -380px -1525px;
}
.glyphicons-icon.soccer_ball {
  background-position: -428px -1525px;
}
.glyphicons-icon.blog {
  background-position: 4px -1573px;
}
.glyphicons-icon.dashboard {
  background-position: -44px -1573px;
}
.glyphicons-icon.certificate {
  background-position: -92px -1573px;
}
.glyphicons-icon.bell {
  background-position: -140px -1573px;
}
.glyphicons-icon.candle {
  background-position: -188px -1573px;
}
.glyphicons-icon.pushpin {
  background-position: -236px -1573px;
}
.glyphicons-icon.iphone_shake {
  background-position: -284px -1573px;
}
.glyphicons-icon.pin_flag {
  background-position: -332px -1573px;
}
.glyphicons-icon.turtle {
  background-position: -380px -1573px;
}
.glyphicons-icon.rabbit {
  background-position: -428px -1573px;
}
.glyphicons-icon.globe {
  background-position: 4px -1621px;
}
.glyphicons-icon.briefcase {
  background-position: -44px -1621px;
}
.glyphicons-icon.hdd {
  background-position: -92px -1621px;
}
.glyphicons-icon.thumbs_up {
  background-position: -140px -1621px;
}
.glyphicons-icon.thumbs_down {
  background-position: -188px -1621px;
}
.glyphicons-icon.hand_right {
  background-position: -236px -1621px;
}
.glyphicons-icon.hand_left {
  background-position: -284px -1621px;
}
.glyphicons-icon.hand_up {
  background-position: -332px -1621px;
}
.glyphicons-icon.hand_down {
  background-position: -380px -1621px;
}
.glyphicons-icon.fullscreen {
  background-position: -428px -1621px;
}
.glyphicons-icon.shopping_bag {
  background-position: 4px -1669px;
}
.glyphicons-icon.book_open {
  background-position: -44px -1669px;
}
.glyphicons-icon.nameplate {
  background-position: -92px -1669px;
}
.glyphicons-icon.nameplate_alt {
  background-position: -140px -1669px;
}
.glyphicons-icon.vases {
  background-position: -188px -1669px;
}
.glyphicons-icon.bullhorn {
  background-position: -236px -1669px;
}
.glyphicons-icon.dumbbell {
  background-position: -284px -1669px;
}
.glyphicons-icon.suitcase {
  background-position: -332px -1669px;
}
.glyphicons-icon.file_import {
  background-position: -380px -1669px;
}
.glyphicons-icon.file_export {
  background-position: -428px -1669px;
}
.glyphicons-icon.bug {
  background-position: 4px -1717px;
}
.glyphicons-icon.crown {
  background-position: -44px -1717px;
}
.glyphicons-icon.smoking {
  background-position: -92px -1717px;
}
.glyphicons-icon.cloud-upload {
  background-position: -140px -1717px;
}
.glyphicons-icon.cloud-download {
  background-position: -188px -1717px;
}
.glyphicons-icon.restart {
  background-position: -236px -1717px;
}
.glyphicons-icon.security_camera {
  background-position: -284px -1717px;
}
.glyphicons-icon.expand {
  background-position: -332px -1717px;
}
.glyphicons-icon.collapse {
  background-position: -380px -1717px;
}
.glyphicons-icon.collapse_top {
  background-position: -428px -1717px;
}
.glyphicons-icon.globe_af {
  background-position: 4px -1765px;
}
.glyphicons-icon.global {
  background-position: -44px -1765px;
}
.glyphicons-icon.spray {
  background-position: -92px -1765px;
}
.glyphicons-icon.nails {
  background-position: -140px -1765px;
}
.glyphicons-icon.claw_hammer {
  background-position: -188px -1765px;
}
.glyphicons-icon.classic_hammer {
  background-position: -236px -1765px;
}
.glyphicons-icon.hand_saw {
  background-position: -284px -1765px;
}
.glyphicons-icon.riflescope {
  background-position: -332px -1765px;
}
.glyphicons-icon.electrical_socket_eu {
  background-position: -380px -1765px;
}
.glyphicons-icon.electrical_socket_us {
  background-position: -428px -1765px;
}
.glyphicons-icon.message_forward {
  background-position: 4px -1813px;
}
.glyphicons-icon.coat_hanger {
  background-position: -44px -1813px;
}
.glyphicons-icon.dress {
  background-position: -92px -1813px;
}
.glyphicons-icon.bathrobe {
  background-position: -140px -1813px;
}
.glyphicons-icon.shirt {
  background-position: -188px -1813px;
}
.glyphicons-icon.underwear {
  background-position: -236px -1813px;
}
.glyphicons-icon.log_in {
  background-position: -284px -1813px;
}
.glyphicons-icon.log_out {
  background-position: -332px -1813px;
}
.glyphicons-icon.exit {
  background-position: -380px -1813px;
}
.glyphicons-icon.new_window_alt {
  background-position: -428px -1813px;
}
.glyphicons-icon.video_sd {
  background-position: 4px -1861px;
}
.glyphicons-icon.video_hd {
  background-position: -44px -1861px;
}
.glyphicons-icon.subtitles {
  background-position: -92px -1861px;
}
.glyphicons-icon.sound_stereo {
  background-position: -140px -1861px;
}
.glyphicons-icon.sound_dolby {
  background-position: -188px -1861px;
}
.glyphicons-icon.sound_5_1 {
  background-position: -236px -1861px;
}
.glyphicons-icon.sound_6_1 {
  background-position: -284px -1861px;
}
.glyphicons-icon.sound_7_1 {
  background-position: -332px -1861px;
}
.glyphicons-icon.copyright_mark {
  background-position: -380px -1861px;
}
.glyphicons-icon.registration_mark {
  background-position: -428px -1861px;
}
.glyphicons-icon.radar {
  background-position: 4px -1909px;
}
.glyphicons-icon.skateboard {
  background-position: -44px -1909px;
}
.glyphicons-icon.golf_course {
  background-position: -92px -1909px;
}
.glyphicons-icon.sorting {
  background-position: -140px -1909px;
}
.glyphicons-icon.sort-by-alphabet {
  background-position: -188px -1909px;
}
.glyphicons-icon.sort-by-alphabet-alt {
  background-position: -236px -1909px;
}
.glyphicons-icon.sort-by-order {
  background-position: -284px -1909px;
}
.glyphicons-icon.sort-by-order-alt {
  background-position: -332px -1909px;
}
.glyphicons-icon.sort-by-attributes {
  background-position: -380px -1909px;
}
.glyphicons-icon.sort-by-attributes-alt {
  background-position: -428px -1909px;
}
.glyphicons-icon.compressed {
  background-position: 4px -1957px;
}
.glyphicons-icon.package {
  background-position: -44px -1957px;
}
.glyphicons-icon.cloud_plus {
  background-position: -92px -1957px;
}
.glyphicons-icon.cloud_minus {
  background-position: -140px -1957px;
}
.glyphicons-icon.disk_save {
  background-position: -188px -1957px;
}
.glyphicons-icon.disk_open {
  background-position: -236px -1957px;
}
.glyphicons-icon.disk_saved {
  background-position: -284px -1957px;
}
.glyphicons-icon.disk_remove {
  background-position: -332px -1957px;
}
.glyphicons-icon.disk_import {
  background-position: -380px -1957px;
}
.glyphicons-icon.disk_export {
  background-position: -428px -1957px;
}
.glyphicons-icon.tower {
  background-position: 4px -2005px;
}
.glyphicons-icon.send {
  background-position: -44px -2005px;
}
.glyphicons-icon.git_branch {
  background-position: -92px -2005px;
}
.glyphicons-icon.git_create {
  background-position: -140px -2005px;
}
.glyphicons-icon.git_private {
  background-position: -188px -2005px;
}
.glyphicons-icon.git_delete {
  background-position: -236px -2005px;
}
.glyphicons-icon.git_merge {
  background-position: -284px -2005px;
}
.glyphicons-icon.git_pull_request {
  background-position: -332px -2005px;
}
.glyphicons-icon.git_compare {
  background-position: -380px -2005px;
}
.glyphicons-icon.git_commit {
  background-position: -428px -2005px;
}
.glyphicons-icon.construction_cone {
  background-position: 4px -2053px;
}
.glyphicons-icon.shoe_steps {
  background-position: -44px -2053px;
}
.glyphicons-icon.plus {
  background-position: -92px -2053px;
}
.glyphicons-icon.minus {
  background-position: -140px -2053px;
}
.glyphicons-icon.redo {
  background-position: -188px -2053px;
}
.glyphicons-icon.undo {
  background-position: -236px -2053px;
}
.glyphicons-icon.golf {
  background-position: -284px -2053px;
}
.glyphicons-icon.hockey {
  background-position: -332px -2053px;
}
.glyphicons-icon.pipe {
  background-position: -380px -2053px;
}
.glyphicons-icon.wrench {
  background-position: -428px -2053px;
}
.glyphicons-icon.folder_closed {
  background-position: 4px -2101px;
}
.glyphicons-icon.phone_alt {
  background-position: -44px -2101px;
}
.glyphicons-icon.earphone {
  background-position: -92px -2101px;
}
.glyphicons-icon.floppy_disk {
  background-position: -140px -2101px;
}
.glyphicons-icon.floppy_saved {
  background-position: -188px -2101px;
}
.glyphicons-icon.floppy_remove {
  background-position: -236px -2101px;
}
.glyphicons-icon.floppy_save {
  background-position: -284px -2101px;
}
.glyphicons-icon.floppy_open {
  background-position: -332px -2101px;
}
.glyphicons-icon.translate {
  background-position: -380px -2101px;
}
.glyphicons-icon.fax {
  background-position: -428px -2101px;
}
.glyphicons-icon.factory {
  background-position: 4px -2149px;
}
.glyphicons-icon.shop_window {
  background-position: -44px -2149px;
}
.glyphicons-icon.shop {
  background-position: -92px -2149px;
}
.glyphicons-icon.kiosk {
  background-position: -140px -2149px;
}
.glyphicons-icon.kiosk_wheels {
  background-position: -188px -2149px;
}
.glyphicons-icon.kiosk_light {
  background-position: -236px -2149px;
}
.glyphicons-icon.kiosk_food {
  background-position: -284px -2149px;
}
.glyphicons-icon.transfer {
  background-position: -332px -2149px;
}
.glyphicons-icon.money {
  background-position: -380px -2149px;
}
.glyphicons-icon.header {
  background-position: -428px -2149px;
}
.glyphicons-icon.blacksmith {
  background-position: 4px -2197px;
}
.glyphicons-icon.saw_blade {
  background-position: -44px -2197px;
}
.glyphicons-icon.basketball {
  background-position: -92px -2197px;
}
.glyphicons-icon.server {
  background-position: -140px -2197px;
}
.glyphicons-icon.server_plus {
  background-position: -188px -2197px;
}
.glyphicons-icon.server_minus {
  background-position: -236px -2197px;
}
.glyphicons-icon.server_ban {
  background-position: -284px -2197px;
}
.glyphicons-icon.server_flag {
  background-position: -332px -2197px;
}
.glyphicons-icon.server_lock {
  background-position: -380px -2197px;
}
.glyphicons-icon.server_new {
  background-position: -428px -2197px;
}
