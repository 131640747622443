@import (reference) '~rev-less/global/variables';
@import (reference) '~rev-less/global/mixins';

@smallSize: 25px;
@largeSize: 40px;
@hilightBorderWidth: 2px;

.root {
	display: inline-block;
	position: relative;
	vertical-align: middle;
	line-height: 0;
}

.profilePicture {
	object-fit: cover;
	vertical-align: baseline;
}

.profileIcon {
	vertical-align: middle;
	top: -2px;
}

.wrap {
	overflow: hidden;
	display: inline-block;
	background-color: @lightBorder;
	border: @hilightBorderWidth solid transparent;
	text-align: center;
	.BackgroundClip(content-box);
}

.imageSize(@smallSize);

.large {
	.imageSize(@largeSize);
}

:global(.vbProfilePictureLarge) {
	.large;
}

.imageSize(@size) {

	.wrap {
		height: @size;
		width: @size;
		.RoundedShape(@size / 2);
		line-height: @size - 2px !important;
	}

	.profilePicture {
		@innerSize: @size - 2 * @hilightBorderWidth;
		height: @innerSize;
		width: @innerSize;
		.RoundedShape(@innerSize / 2);
	}

	.profileIcon {
		font-size: @size * 0.6 !important;
		.RoundedShape(@size / 2);
	}
}
