.speakerTopRightLayout:not(:global(.stop)) vb-dual-playback-content-container,
.speakerBottomRightLayout:not(:global(.stop)) vb-dual-playback-content-container {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.speakerTopRightLayout:not(:global(.stop)) vb-dual-playback-content-container video,
.speakerBottomRightLayout:not(:global(.stop)) vb-dual-playback-content-container video {
  width: 100%;
  height: 100%;
}
.speakerTopRightLayout:not(:global(.stop)) vg-media video,
.speakerBottomRightLayout:not(:global(.stop)) vg-media video {
  border-color: #fff;
  border-style: solid;
  border-width: 4px;
  box-shadow: 4px 4px;
  box-sizing: content-box;
  height: auto;
  position: absolute;
  right: 0;
  transform-origin: right;
  transform: scale(0.25);
  transition: transform 1s, border-width 1s;
  z-index: 2;
}
.speakerBottomRightLayout:not(:global(.stop)) vg-media video {
  bottom: 36px;
  transform-origin: bottom right;
}
.speakerTopRightLayout:not(:global(.stop)) vg-media video {
  transform-origin: top right;
}
.sideBySideLayout:not(:global(.stop)) vb-dual-playback-content-container {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.sideBySideLayout:not(:global(.stop)) vb-dual-playback-content-container video {
  width: 50%;
  height: 100%;
  padding-right: 4px;
}
.sideBySideLayout:not(:global(.stop)) vg-media video {
  float: right;
  padding-left: 4px;
  transform-origin: right;
  transform: scale(0.5);
  transition: transform 1s;
}
vb-dual-playback-content-container video[style*="opacity: 0"] {
  width: 0 !important;
}
.dualPlaybackActive vb-dual-playback-content-container {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.dualPlaybackActive vb-dual-playback-content-container video {
  height: 100%;
  transition: width 1s;
}
.dualPlaybackActive vg-media video {
  float: right;
  padding-left: 4px;
  transform-origin: right;
  transition: transform 1s;
}
.speaker50Content50 vb-dual-playback-content-container video {
  width: 50%;
}
.speaker50Content50 vg-media video {
  transform: scale(0.5);
}
.onlySpeaker vg-media video {
  padding-left: 0;
}
.onlySpeaker vb-dual-playback-content-container video {
  width: 0%;
}
.onlySpeaker vg-media video {
  transform: scale(1);
}
.speaker80Content20 vb-dual-playback-content-container video {
  width: 20%;
}
.speaker80Content20 vg-media video {
  transform: scale(0.8);
}
.speaker20Content80 vb-dual-playback-content-container video {
  width: 80%;
}
.speaker20Content80 vg-media video {
  transform: scale(0.2);
}
.onlyContent vb-dual-playback-content-container video {
  width: 100%;
}
.onlyContent vg-media video {
  transform: scale(0);
}
