@import (reference) '~rev-less/global/variables';

.bs-rating-star {
	color: @blackFade25;

	&.active {
		color: @accentColor;
	}
}
video-tile {
	video-360-indicator {
		position: absolute;
		top: 2rem;
		right: 4px;
	}
}
