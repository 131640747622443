@import (reference) '~rev-less/global/variables';
@import (reference) '~rev-less/global/mixins';

.enableSubtitlesTooltip {
	:global(.tooltip-inner) {
		background-color: @highlight;
		color: @black;
		.BoxShadow(1px 1px @blackFade25);
	}

	:global(.tooltip-arrow) {
		border-top-color: @highlight !important;
		border-bottom-color: @highlight !important;
	}
}
