@import (reference) '~rev-less/global/variables';

.icon {
	color: @gray;
	font-size: @type16;
	line-height: @type12;
	margin-left: .25rem;
	vertical-align: top;
	width: 0;
}

.value {
	color: @blackFade75;
	font-size: @type26;
}

.valueDeltaPositive {
	color: @green;
}

.valueDeltaNegative {
	color: @warningColorFade75;
}