.header {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  margin: 1rem 0;
}
.headerText {
  padding: 10px 8px;
  margin: 0;
  font-size: 1.125rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
}
