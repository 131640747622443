@import "../global/variables";
@import "../global/mixins";



.video-playback-status-msg { // used by video-playback-status-msg and others attemping to mimic (featured carousel, featured videos, embed video)
	color: @white;

	&.centered-text-block {
		.alignItems(center);
		.displayFlex();

		> .centered-text-block-content {
			.flex(1);
			line-height: 2rem;
			position: static;
		}
	}

	.centered-text-block-content > h3:first-child {
		margin-top: 0;
	}

	h3 {
		.glyphicons {
			margin-right: @typeUnit1-4x;
		}
	}

	.video-playback-error-msg, &.video-playback-error-msg
	{
		&, a, h3 {
			color: @highlightLighten35;
		}
	}

	.restore-button {
		font-size: 1rem;
		margin-top: @type20;
	}
}
