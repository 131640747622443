@import (reference) "~rev-less/global/variables";
@import (reference) "~rev-less/global/mixins";

[uib-dropdown-menu].align-right {
	left: auto;
	right: 0;

	.BorderTopLeftRadius(4px);
	.BorderTopRightRadius(0);
}

/* bootstrap override of showing scrollbar always */
.dropdown-menu {
	&.navdrop-menu .scrollable-area {
		overflow-y: auto !important;
	}
}

.dropdown-menu-right {
	left: auto !important;
}


.open {
	.dropdown-menu {
		&.width-100 {
			min-width: 110px;
		}
	}
}

.dropdown-list {
	&-item {
	.RoundedShape(0);

		/* some specificity overriding that has to be done for now */
		> .btn {
			border-color: @white !important;

			&:hover, &.active {
				color: @white !important;
				background-color: @accentColor !important;
			}
		}
	}

	&-link {
		text-align: left;
	}
}
