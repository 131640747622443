.root {
  position: absolute;
  max-width: 200px;
  overflow: hidden;
  pointer-events: none;
  transition: opacity 1s;
  font-weight: 400;
  line-height: 1.4;
  line-break: auto;
  font-size: 12px;
  word-break: break-word;
}
.tooltipInner {
  padding: 3px 8px;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 4px;
}
.headerTooltipValue {
  border-top: 1px solid #909090;
}
