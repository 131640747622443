.root,
.formGroupWapper {
  display: block;
}
.label {
  display: inline-block;
}
.validation {
  margin-top: 10px;
}
.controlRequired {
  position: relative;
}
.controlRequired:after {
  content: '\002A';
  color: #c41616;
  position: absolute;
  top: 0;
  right: -17px;
  display: inline-block;
  font-size: 1.313rem;
  font-weight: 400;
  vertical-align: middle;
  text-transform: none;
  text-shadow: -1px 0 #909090, 0 1px #909090, 1px 0 #909090, 0 -1px #909090;
}
