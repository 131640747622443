@import "../global/variables";
@import "../global/mixins";

.thumbnail-picker-overlay { // used on webcast edit form?
	&:extend(.AbsoluteFill);
	z-index: 1;
	text-align: center;

	&:hover {
		background: @blackFade10;

		span.show-on-hover {
			display: inline;
		}

		div.show-on-hover {
			display: block;
		}

		.hide-on-hover {
			display: none;
		}

		.circle {
			background: @black;
		}
	}

	&.btn-file {
		position: absolute;
	}

	.table-display {
		height: 100%;
	}

	.table-cell {
		vertical-align: middle;
	}

	.circle {
		width: 50px;
		height: 50px;
		font-size: 32px;
		background: @blackFade50;
		color: @white;
		vertical-align: middle;

		.glyphicons {
			top: 8px
		}
	}
}