.root {
  display: block;
  position: relative;
}
.root :global(.cke_combo_button) {
  margin-bottom: 3px;
}
.root :global(.cke_wysiwyg_frame) {
  border-style: solid;
  box-sizing: border-box;
}
.root :global(.cke_wysiwyg_frame):global(:not(.has-focus)) {
  border-color: #909090;
  border-width: 1px;
}
.root :global(.cke_wysiwyg_frame):global(.has-focus) {
  border-width: 3px;
  box-shadow: inset 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
}
.editorLoading {
  height: 260px;
  width: 100%;
  position: absolute;
}
:global(.user-content) a {
  color: #007ebd;
}
:global(.user-content) a:hover {
  color: #00a0f0;
}
