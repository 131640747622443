@import (reference) "~rev-less/global/variables";
@import (reference) "~rev-less/global/mixins";

vb-loading-spinner {
	.loader {
		&.accent {
			border-color: @color1;
			border-left-color: @accentColor;
		}

		.margin-right-5 & {
			margin: 0;
		}

		.btn & {
			font-size: @type14;
			margin: 0 4px;
		}

		.btn.btn-info &.accent {
			border-color: @blackFade25;
			border-left-color: @blackFade75;
		}

		.white &.accent {
			border-color: @whiteFade25;
			border-left-color: @accentColor;
			margin-left: 0;
		}

		.tree-item &,
		.tree-item-child & {
			margin: 0 4px 0 16px;
		}

		.selectize-style & {
			margin-left: 0;
			margin-right: 4px;
			margin-top: -2px;
		}
	}
}
