.root {
  bottom: 55px;
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: 4;
}
.subtitles {
  background-color: #000;
  color: #fff;
  display: inline-block;
  font-size: 18px;
  line-height: 1;
  padding: 1rem;
  white-space: pre-line;
}
