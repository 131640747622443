.root {
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.65);
  color: #fff;
  display: inline-block;
  left: 1rem;
  padding: 0.5rem;
  position: absolute;
  top: 1rem;
}
.label {
  display: block;
}
.select {
  color: #000;
  float: right;
  font-weight: normal;
  margin-left: 0.25rem;
}
