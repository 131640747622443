.canvas {
  background: #000;
  position: absolute;
  cursor: default;
  cursor: hand;
  cursor: move;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  cursor: grab;
}
.canvas.mouseDown {
  cursor: default;
  cursor: hand;
  cursor: move;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
  cursor: grabbing;
}
.rotateControls {
  background-color: rgba(0, 0, 0, 0.65);
  border-radius: 9999px;
  border: 2px solid #666666;
  color: #fff;
  height: 80px;
  overflow: hidden;
  position: absolute;
  right: 2rem;
  top: 2rem;
  width: 80px;
  z-index: 4;
}
.container {
  height: 100%;
  position: relative;
}
.button,
.button:hover,
.button:active,
.button:focus {
  background: none;
  border: none;
  box-shadow: none;
  outline: 0;
  position: absolute;
}
.button:hover {
  color: #00a0f0;
}
.up {
  composes: button;
  top: 0;
}
.down {
  composes: button;
  bottom: 0;
}
.left {
  composes: button;
  left: 0;
}
.right {
  composes: button;
  right: 0;
}
.center {
  left: 0;
  top: 26px;
  font-weight: bold;
  text-transform: uppercase;
  width: 100%;
  font-size: 12px;
  padding: 0 20px;
}
.centerLabel {
  composes: center;
}
.center.centerLabel {
  font-size: 18px;
  font-weight: bold;
  left: 0;
  padding: 0;
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  top: 30px;
  width: 100%;
}
.centerButton {
  composes: center;
  composes: button;
}
.up,
.down {
  width: 100%;
  height: 20px;
  left: 0;
}
.left,
.right {
  width: 20px;
  height: 100%;
}
.left,
.right,
.center {
  padding: 0;
}
.resetIcon {
  font-size: 24px;
}
