@import (reference) '~rev-less/global/variables';
@import (reference) '~rev-less/global/mixins';

.RadioContainer {
	cursor: pointer;
	display: block;
	position: relative;
	.UserSelect();
}

.RadioContainer input {
	position: absolute;
	opacity: 0;
}

.RadioCheckmark {
	background-color: @white;
	border: 1px solid @lightBorder;
	.RoundedShape(50%);
	height: 24px;
	left: 0;
	position: absolute;
	top: 0;
	width: 24px;
}

.RadioContainer:hover input ~ .RadioCheckmark {
	background-color: @lightBorder;
}

.RadioContainer input:checked ~ .RadioCheckmark {
	background-color: @white
}

.RadioCheckmarkChecked {
	.RoundedShape(50%);
	display: block;
	left: 4px;
	height: 14px;
	position: absolute;
	top: 4px;
	width: 14px;
}

.RadioTextInput {
	line-height: 1.75;
	padding-left: 35px;
}
