recording-menu .recording-menu-component-form .form-control {
  width: 100%;
}
recording-menu .recording-menu-component-form .status-msg h3 {
  color: #303030;
}
recording-menu .recording-menu-component-form .link-url-wrapper .dropdown-menu {
  height: 150px !important;
  overflow-y: auto !important;
  position: relative !important;
  left: 0 !important;
  top: 0 !important;
  width: 100%;
}
recording-menu .recording-menu-component-footer {
  margin-top: 20px;
}
recording-menu .recording-menu-component-footer label {
  text-transform: none;
}
recording-menu .recording-menu-component-footer div.zoom-password {
  padding-right: 0px;
}
recording-menu .recording-menu-component-footer .zoom-password div {
  border-right: 1px solid #d8d8d8;
}
recording-menu .recording-menu-component-footer .zoom-password div input {
  width: 85%;
}
