@import (reference) "~rev-less/global/variables";
@import (reference) "~rev-less/global/mixins";

.root {
	display: block;
	.RoundedShape(4px);
	padding: 1rem;
	width: 50%;
	margin-left: 25vw;
	line-height: 100%;
}

.messageWrapper {
	background-color: @gray20;
	border-color: @gray50;
	font-size: @type24;
	text-align: center;
	border-radius: 4px;
	padding-bottom: 30px;
	line-height: 2rem;
	color: @black;
}

.frown {
	display: block;
	font-size: @type64;
	padding: @type64 / 2;
}
