@import (reference) "~rev-less/global/variables";

.actionButton {
	composes: dropdown-toggle from global;
	padding: 4px 6px;
}
.menuWrapper {
	composes: dropdown from global;
	position: relative;
}

.menuItemWrapper {
	composes: dropdown-menu from global;
	position: absolute;
	background: @white;
	min-width: 7.5vw;
	border: 1px solid rgba(0, 0, 0, 0.15);
	border-radius: 3px;
	left: -5vw;
	right: auto;

	&:before {
		content: '';
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0 10px 10px 10px;
		border-color: transparent transparent @borderColor transparent;
		position: absolute;
		right: 18px;
		top: -10px;
	}

	&:after {
		content: '';
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0 8px 8px 8px;
		border-color: transparent transparent @white transparent;
		position: absolute;
		right: 20px;
		top: -8px;
	}
}

.menuItem {
	composes: dropdown-item from global;
	display: block;
	padding: 10px 15px;
	line-height: 1;

	&:hover {
		text-decoration: none;
		color: @black;
	}
}

.moreIcon {
	display: flex;
	justify-content: space-around;
	align-items: center;
	height: 18px;
}
