.bs-rating-star {
  color: rgba(0, 0, 0, 0.25);
}
.bs-rating-star.active {
  color: #00a0f0;
}
video-tile video-360-indicator {
  position: absolute;
  top: 2rem;
  right: 4px;
}
