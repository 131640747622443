.vbBtn {
  composes: btn from global;
  border-radius: 3px;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1rem;
  padding: 8px 12px;
  position: relative;
  top: 0;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: subpixel-antialiased;
}
.vbBtnTransparent {
  background: none;
  border: none !important;
  box-shadow: none;
}
.vbIconBtn {
  composes: vbBtn;
}
.vbIconBtn :global(.glyphicons) {
  margin-right: 3px;
  margin-left: 0;
  font-size: 0.6875rem;
}
