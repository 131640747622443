vb-loading-spinner .loader.accent {
  border-color: #303030;
  border-left-color: #00a0f0;
}
.margin-right-5 vb-loading-spinner .loader {
  margin: 0;
}
.btn vb-loading-spinner .loader {
  font-size: 0.875rem;
  margin: 0 4px;
}
.btn.btn-info vb-loading-spinner .loader.accent {
  border-color: rgba(0, 0, 0, 0.25);
  border-left-color: rgba(0, 0, 0, 0.75);
}
.white vb-loading-spinner .loader.accent {
  border-color: rgba(255, 255, 255, 0.25);
  border-left-color: #00a0f0;
  margin-left: 0;
}
.tree-item vb-loading-spinner .loader,
.tree-item-child vb-loading-spinner .loader {
  margin: 0 4px 0 16px;
}
.selectize-style vb-loading-spinner .loader {
  margin-left: 0;
  margin-right: 4px;
  margin-top: -2px;
}
