@media screen and (max-width: 991px) {
  .kpiWrapper {
    flex-direction: column;
  }
  .kpiWrapper vb-ui-report-cell {
    flex: 1 1 auto;
  }
  .kpiWrapper vb-ui-report-cell:not(:last-child) {
    margin-right: 0 !important;
  }
}
.kpiData {
  font-size: 1.625rem;
}
