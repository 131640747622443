.textArea {
  composes: TextInputFocusBorder from '~rev-shared/ui/text-input/vb-text-input.module.less';
  border: 1px solid #909090;
  color: #141414;
  width: 100%;
  height: 200px;
  font-size: 0.875rem;
  padding: 10px 16px;
  line-height: 1.3;
  background-color: #fff;
  border-radius: 4px;
}
.textArea:global(.ng-invalid):not(:global(.ng-untouched)):not(:focus) {
  border: 3px solid #c41616;
}
