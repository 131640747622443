.rootElement {
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  background-color: #fff;
  height: 100%;
  position: relative;
  width: 100%;
}
.chartOutput {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.noData {
  color: #898989;
  font-size: 1rem;
  font-weight: bold;
}
