public-video-password {
  position: fixed;top: 0;right: 0;bottom: 0;left: 0;
}
public-video-password .system-login-shell {
  display: table;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
public-video-password .description {
  background: #fff;
  border: 1px solid #cbcbcb;
  height: 25vh;
  margin-bottom: 2em;
  overflow-y: auto;
  padding: 2%;
}
public-video-password .registered-user {
  margin: 1em 0 -1em 0;
  font-size: 1rem;
}
public-video-password .registered-user a {
  color: #00a0f0;
}
