branding-logo {
  display: block;
  padding: 4px 0;
}
branding-logo img {
  height: 40px;
}
@media all and (max-width: 991px) {
  branding-logo img {
    height: 36px;
  }
}
