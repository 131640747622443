.container {
  align-items: center;
  background: rgba(0, 0, 0, 0.65);
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}
.button {
  align-items: center;
  background: transparent;
  border: 0;
  color: #fff;
  display: inline-flex;
  flex-direction: column;
  opacity: 0.75;
  text-align: center;
}
.button:hover {
  opacity: 1;
}
.button:nth-child(2) {
  margin-left: 4rem;
}
.icon {
  background: #00a0f0;
  border-radius: 50%;
  display: inline-block;
  font-size: 36px;
  margin-bottom: 0.5rem;
  padding: 0.35em;
}
.repeatIcon {
  transform: scaleX(-1);
}
