@import (reference) '~rev-less/global/variables';

.kpiWrapper {
	@media screen and (max-width: @viewPortMedWidth - 1px) {
		flex-direction: column;
		vb-ui-report-cell {
			flex: 1 1 auto;
			&:not(:last-child) {
				margin-right: 0 !important;
			}
		}
	}
}

.kpiData {
	font-size: @type26;
}