.loadingContainer {
  left: 0;
  line-height: 1.5;
  overflow: hidden;
  right: 0;
  text-align: center;
  top: 50%;
}
.loadingContainer:not(:global(.box-inline)) {
  position: absolute;
}
.loadingContainer:not(:global(.box-inline)) .icon {
  display: flex;
}
.loadingContainer:global(.medium) {
  margin-top: -24px;
}
.loadingContainer:global(.medium) .msg {
  font-size: 0.625rem;
}
.loadingContainer:global(.large) {
  margin-top: -36px;
}
.loadingContainer:global(.large) .msg {
  font-size: 1.313rem;
}
@media (max-width: 505px) {
  .loadingContainer:global(.large) .msg {
    font-size: 0.75rem;
  }
}
.loader {
  -webkit-animation: spin 1.5s infinite linear;
  animation: spin 1.5s infinite linear;
  border-radius: 50%;
  border-left: 0.2em solid rgba(0, 0, 0, 0.5);
  border: 0.2em solid rgba(153, 153, 153, 0.5);
  display: inline-block;
  font-size: 18px;
  height: 1em;
  margin: 0 8px;
  overflow: hidden;
  position: relative;
  text-indent: -9999em;
  vertical-align: middle;
  width: 1em;
}
.loader:after {
  border-radius: 50%;
  width: 1em;
  height: 1em;
}
.loader:global(.medium) {
  font-size: 48px;
  border-width: 0.125em;
  display: block;
}
.loader:global(.large) {
  font-size: 72px;
  border-width: 0.1em;
  display: block;
}
.icon {
  display: inline-block;
  margin-bottom: 2px;
}
.icon:before {
  display: none;
  position: absolute;
}
.icon:global(.medium) {
  font-size: 1.125rem;
}
.icon:global(.medium):before {
  min-width: 64px;
  top: 16px;
}
.icon:global(.large) {
  font-size: 1.875rem;
}
.icon:global(.large):before {
  min-width: 88px;
  top: 22px;
}
.icon:global(.medium),
.icon:global(.large) {
  display: block;
}
.icon:global(.medium):before,
.icon:global(.large):before {
  display: block;
}
.msg:empty {
  display: none;
}
.msg:not(:empty) {
  display: block;
  margin: 1rem 0;
  padding: 0 0.5rem;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
