.tileItem {
  display: block;
  margin-bottom: 30px;
  background: #fff;
  border: 1px solid #cbcbcb;
  border-radius: 3px;
  position: relative;
  top: 0;
}
.tileItem:hover {
  -webkit-transition: all linear 0.125s;
  transition: all linear 0.125s;
  border-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
}
.tileItem:hover a:hover {
  color: #00a0f0;
  text-decoration: none;
}
.infoContainer {
  margin: 0 5px !important;
}
.infoContainer h2 {
  margin: 0;
}
.tileFooter {
  position: absolute;
  bottom: 0;
  width: calc( 100% - 10px );
  padding: 5px 0;
  background: #fff;
  font-size: 1.125rem;
}
.tileFooter,
.tileFooter a {
  color: #898989;
}
.icon {
  color: #898989;
  font-size: 1.125rem;
  margin-left: 5px;
}
.actionBtn {
  margin: 10px 0;
  padding: 5px 10px;
}
.sidebarVbIcon:before {
  vertical-align: middle;
  font-size: 1.25rem;
}
