upload-menu .upload-file-wrapper.upload-error .error-msg {
  word-break: break-all;word-break: break-word;overflow-wrap: break-word;
}
.upload-media {
  background-color: transparent;
}
.webexImportModal .modal-content {
  overflow: visible;
}
typeahead-container {
  z-index: 6000 !important;
}
upload-menu {
  border-style: solid;
  border-width: 1px;
  display: block;
  min-height: 100px;
  overflow: hidden;
  width: 665px;
  border-radius: 3px;
}
upload-menu .form-upload-files {
  margin: 15px;
}
upload-menu .link-url-wrapper {
  padding: 12px 15px;
}
upload-menu .link-url-wrapper label,
upload-menu .link-url-wrapper .has-error {
  margin: 4px 0 !important;
}
upload-menu .link-url-wrapper .has-error {
  color: #c41616;
}
upload-menu .link-url-wrapper vb-ui-form-group {
  padding: 0;
  margin: 0;
}
upload-menu .link-url-wrapper .form-control {
  background: #fff;
}
upload-menu .link-url-wrapper .flex-navigation-bar {
  margin-top: 8px;
}
upload-menu .link-url-wrapper .btn {
  box-shadow: none;
  padding: 0 8px;
  height: 2rem;
  line-height: 2rem;
}
upload-menu .link-url-wrapper .btn-group {
  white-space: nowrap;
}
upload-menu .link-url-wrapper .btn-group .btn {
  display: inline-block;
}
upload-menu .link-url-wrapper .fixed {
  padding-left: 8px;
}
upload-menu .link-url-wrapper .fixed vb-ui-form-group {
  width: 100%;
}
upload-menu .link-url-wrapper .fixed .btn-block {
  min-width: 100px;
}
upload-menu .link-url-wrapper select.form-control.selectize-style {
  height: 2rem;
  line-height: 2rem;
  padding: 0 8px;
  box-shadow: none !important;
}
upload-menu .link-url-wrapper select.form-control.selectize-style::before {
  display: none;
}
upload-menu .upload-file-btn-wrapper .btn-file {
  font-size: 1rem;
  font-weight: 400;
  height: 100px;
  line-height: 70px;
  position: relative;
  border-radius: 3px;
}
upload-menu .upload-file-btn-wrapper .btn-file small {
  position: absolute;
  top: 28px;
  left: 0;
  right: 0;
  text-shadow: none;
}
upload-menu .upload-file-wrapper {
  padding: 8px 8px 4px;
  background: #fff;
  margin: 0 16px;
  border-bottom: 1px solid #cbcbcb;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
}
upload-menu .upload-file-wrapper .loader {
  font-size: 1.1em;
  margin-left: 3px;
}
upload-menu .upload-file-wrapper.upload-complete {
  box-shadow: none;
  border-bottom: 1px solid #d8d8d8;
}
.global-nav-wrap upload-menu .upload-file-wrapper.upload-complete .circle_ok {
  color: #00a0f0;
}
upload-menu .upload-file-wrapper.upload-error h4 {
  color: #c41616;
}
upload-menu .upload-file-wrapper.upload-error .error-msg {
  white-space: normal;
  height: auto;
  line-height: 1rem;
}
upload-menu .upload-file-wrapper.upload-error .glyphicons {
  cursor: pointer;
}
upload-menu .upload-file-wrapper.upload-loading .upload-file h4 {
  padding-right: 2em;
}
.global-nav-wrap upload-menu .upload-file-wrapper.upload-error a,
.global-nav-wrap upload-menu .upload-file-wrapper .error-msg,
.global-nav-wrap upload-menu .upload-file-wrapper.upload-error .glyphicons {
  color: #c41616;
}
upload-menu .upload-file-wrapper .progress-wrapper {
  border-radius: 3px;
  padding: 8px 20px 8px 0;
}
upload-menu .upload-file-wrapper h4 {
  font-weight: bold;
  font-size: 0.875rem;
  color: #303030;
  margin: 0;
  height: 1.5rem;
  line-height: 1.5rem;
  position: relative;
}
upload-menu .upload-file-wrapper p {
  height: 1.5rem;
  line-height: 1.5rem;
  font-size: 0.75rem;
  margin: 0;
  padding-right: 20px;
  color: #898989;
  position: relative;
}
upload-menu .upload-file-wrapper p.upload-name {
  font-weight: bold;
  font-size: 0.875rem;
  color: #303030;
}
upload-menu .upload-file-wrapper p .glyphicons {
  position: absolute;
  right: 0;
  font-size: 0.75rem;
  top: auto;
  bottom: 0.5rem;
}
upload-menu .upload-file-wrapper .upload-file,
upload-menu .upload-file-wrapper .upload-file a {
  color: #303030;
  word-break: break-all;
  word-break: break-word;
  overflow-wrap: break-word;
}
upload-menu .upload-file-wrapper .upload-file .btn .loader {
  position: absolute;
  right: -0.75em;
}
upload-menu .upload-file-wrapper .upload-file h4 {
  height: auto !important;
}
upload-menu .upload-file-wrapper .upload-title {
  font-size: 0.875rem;
}
upload-menu .upload-viewing-hours-warning {
  margin-top: 1rem;
  padding: 0.5rem;
}
upload-menu vb-checkbox .vb-checkbox-label {
  text-transform: capitalize;
}
upload-menu .nav-tabs li {
  border-bottom: 0 !important;
}
upload-menu .nav-tabs li.active {
  border-color: transparent;
}
upload-menu .nav-tabs li.active a:hover {
  color: inherit;
}
upload-menu .nav-tabs li.active a,
upload-menu .nav-tabs li a:hover {
  color: inherit;
}
upload-menu .nav-tabs li.active a,
upload-menu .nav-tabs li.active a:hover,
upload-menu .nav-tabs li a:hover {
  background: none;
}
upload-menu .nav-tabs li a,
upload-menu .nav-tabs li a:hover {
  border: 0 !important;
}
upload-menu .nav-tabs li:not(.active) {
  border-color: transparent;
}
upload-menu .nav-tabs .nav-justified > li > a,
upload-menu .nav-tabs > li > a {
  border-color: transparent;
  line-height: 1;
  color: #898989;
  font-size: 14px;
  padding: 10px;
  margin: 4px 0;
}
upload-menu .nav-tabs.nav-justified > .active > a,
upload-menu .nav-tabs.nav-justified > .active > a:hover {
  border: 1px solid transparent;
}
upload-menu .nav-tabs.nav-justified > li {
  border-left: 1px solid rgba(0, 0, 0, 0.5);
}
upload-menu .nav-tabs.nav-justified > li:first-child {
  border-left: none;
}
upload-menu .tab-pane {
  padding: 0;
}
upload-menu .tab-pane ol {
  margin: 0;
}
upload-menu .tab-pane li {
  display: block;
  position: relative;
}
upload-menu .tab-pane label {
  text-transform: uppercase;
  font-size: 11px;
  margin-bottom: 8px;
}
upload-menu .tab-pane .table-cell {
  vertical-align: top;
}
upload-menu .scrollable-area {
  max-height: 300px;
  overflow-y: scroll;
}
@media (max-width: 600px) {
  upload-menu .link-url-wrapper .table-cell {
    display: block;
    width: 100%;
  }
  upload-menu .link-url-wrapper .table-cell .btn-group {
    width: 100%;
  }
  upload-menu .link-url-wrapper .table-cell .btn-group .btn {
    width: 50%;
  }
  upload-menu .link-url-wrapper .btn,
  upload-menu .link-url-wrapper select {
    height: 36px;
    line-height: normal;
  }
}
.dropdown-menu.navdrop-menu.upload-media .nav-tabs.nav-justified > li {
  display: table-cell;
  width: 1%;
}
.dropdown-menu.navdrop-menu .nav-tabs.nav-justified > li {
  word-break: break-word;
}
.upload-media {
  border: none !important;
}
.upload-media .nav-tabs.nav-justified {
  table-layout: fixed;
}
.upload-media .nav-tabs.nav-justified > li {
  position: relative;
  overflow: hidden;
  vertical-align: middle;
}
.upload-media .nav-tabs.nav-justified > li a {
  padding: 12px 4px;
  white-space: initial;
}
@media (max-width: 991px) {
  .upload-media {
    margin-top: -4px !important;
  }
}
.upload-media .upload-import-button {
  margin: 1em;
}
.upload-media .upload-import-button .upload-import-button-logo {
  display: block;
  width: 50px;
  height: 50px;
  border-radius: 3px;
  margin: 0 auto 0.25em;
  background-repeat: no-repeat;
  background-position: center;
}
.upload-media .upload-import-button .upload-import-button-logo.webex-logo {
  background-image: url(/img/icon-webex-40.png);
}
.upload-media .upload-import-button .upload-import-button-logo.upload-import-button-rev-create {
  background-color: transparent;
  background-image: url(/img/btn-rev-create-50.png);
}
@media (max-width: 768px) {
  .dropdown-menu.navdrop-menu.upload-media {
    position: fixed;
    width: 100vw !important;
  }
  .dropdown-menu.navdrop-menu.upload-media upload-menu {
    width: auto;
  }
}
