.video-status {
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  z-index: 2;
}
.video-status-text {
  display: inline-block;
}
.video-status-text > span {
  background: rgba(255, 255, 0, 0.75);
  display: inline-block;
  height: 24px;
  padding: 0 8px;
  color: #303030;
  font-size: 0.875rem;
  font-weight: bold;
  line-height: 1.8;
  text-transform: uppercase;
}
.video-status-text.rejected > span {
  background: #c41616;
  color: #fff;
}
.video-status-text.pending > span {
  background: #00a0f0;
  color: #fff;
}
.video-status-text.inactive > span {
  background: rgba(48, 48, 48, 0.75);
  color: #fff;
}
.video-status-legal {
  background: #000;
  display: inline-block;
  height: auto;
  padding: 0 8px;
  color: #fff;
  font-size: 0.875rem;
  font-weight: bold;
  line-height: 1.8;
  text-transform: uppercase;
}
.video-status-legal .lock {
  font-size: 0.6875rem;
}
.video-status-text,
.video-status-legal {
  margin-bottom: 0.15rem;
}
.video-status .video-status-public-password {
  background: rgba(48, 48, 48, 0.75);
  color: #fff;
  display: inline-block;
  font-size: 2rem;
  line-height: 1;
  padding: 0.25rem;
  border-radius: 0.5rem;
}
.video-owner .video-text-status.pending > span {
  background: rgba(48, 48, 48, 0.75);
  color: #fff;
}
