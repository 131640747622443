.btnToggleWrapper {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}
.btnToggleWrapper [vb-ui-radio-btn],
.btnToggleWrapper button {
  float: left;
}
.btnToggleWrapper [vb-ui-radio-btn]:first-child,
.btnToggleWrapper button:first-child {
  margin-left: 0;
}
.btnToggleWrapper [vb-ui-radio-btn]:not(:first-child):not(:last-child),
.btnToggleWrapper button:not(:first-child):not(:last-child) {
  margin-left: -1px;
  border-radius: 0;
}
.btnToggleWrapper [vb-ui-radio-btn]:first-child:not(:last-child),
.btnToggleWrapper button:first-child:not(:last-child) {
  border-radius: 4px 0 0 4px;
}
.btnToggleWrapper [vb-ui-radio-btn]:last-child:not(first-child),
.btnToggleWrapper button:last-child:not(first-child) {
  margin-left: -1px;
  border-radius: 0 4px 4px 0;
}
:global(.vbUiRadioBtnActive) {
  background: none;
  position: relative;
}
:global(.vbUiRadioBtnActive):hover {
  box-shadow: none !important;
  background: linear-gradient(rgba(34, 41, 48, 0.5), rgba(34, 41, 48, 0.5));
}
.activeBtn {
  background: none;
  position: relative;
}
.activeBtn:hover {
  box-shadow: none !important;
  background: linear-gradient(rgba(34, 41, 48, 0.5), rgba(34, 41, 48, 0.5));
}
