@import (reference) "~rev-less/global/variables";

branding-preview-banner {
	display: block;

	section {
		position: fixed;
		z-index: 1000;
		bottom: 0;
		height: 50px;
		width: 100%;
		padding: 10px;

		background-color: #fddd9a;
		text-align: center;

		@media(max-width: (@viewPortMedWidth - 1px)) {
			display: none;
		}
	}

	.preview-message {
		display: inline-block;
		margin-top: 5px;
	}

	.btn-preview {
		position: absolute;
		right: 10px;
		top: 10px;
	}

}
