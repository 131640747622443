.root :global(.ag-root-wrapper) {
  flex: 1 1 auto !important;
  order: -1;
  position: relative;
}
.root :global(.ag-root-wrapper-body) {
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}
.root :global(.ag-body-viewport-wrapper.ag-layout-normal) {
  overflow-x: scroll;
  overflow-y: scroll;
}
.root ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 8px;
  height: 8px;
}
.root ::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
.columnPickerIcon {
  font-size: 1.25rem;
  top: 3px;
}
.hasHeaderTooltipValue :global(.ag-header-cell-label:after) {
  content: '\E195';
  color: rgba(48, 48, 48, 0.75);
  margin-left: 5px;
  height: 25px;
  width: 25px;
  font-family: 'Glyphicons Regular';
  position: relative;
  top: 3px;
  font-size: 14px;
}
