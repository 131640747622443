.root {
  align-items: center;
  bottom: 0;
  color: #ffff00;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.warningSign {
  composes: glyphicon from global;
  composes: glyphicon-warning-sign from global;
}
.warningSign:before {
  font-size: 2rem;
  padding: 0 0.5em;
}
