@import "global/variables";
@import "global/mixins";

@fallbackFont: 'Karla', Helvetica Neue, Helvetica, Arial, sans-serif;

body {
	height: 100%;
	color: @color1;
	background: @backgroundLighten03;
	-ms-overflow-style: scrollbar; // prevents the scroll bar from rendering on top of content in IE

	font-family: @fallbackFont;

	&.webcast,
	&.admin,
	&.system,
	&.setup-page {
		padding-top: 0;
	}

	&.admin,
	&.system,
	&.setup-page {
		background: @adminGray;
	}

	> object {
		visibility: hidden;
	}
}

.push-status-message-container{
	position: fixed;
	bottom: 0;
	width: 100%;
	text-align: center;
	z-index: 100;

	&.alert {
		border-radius: 0;
		line-height: 1.75;
		margin-bottom: 0;
		padding: 5px;
	}
}

// Used by Angular components. Found that normalize.less (from UI Bootstrap components?) styles this, which then seems to break the native behavior.
[hidden] {
	display: none !important;
}
