.root {
  display: block;
}
.root:global(.vbUiCheckboxDisabled) {
  cursor: not-allowed;
}
.button,
.label {
  font-size: 0.875rem;
}
.label {
  padding-left: 5px;
}
.button {
  border-radius: 4px;
  border: 1px solid #909090;
  font-size: 0.75rem;
  margin-right: 0.25rem;
  overflow: hidden;
  padding: 2px;
  position: relative;
  height: 18px;
  width: 18px;
}
.button:not(.checked) {
  background-color: #fff;
}
.button:not(.checked) .icon {
  visibility: hidden;
}
.button.disabled {
  opacity: 0.5;
}
.icon {
  vertical-align: top !important;
}
.mixed {
  border: 1px solid;
}
