@import (reference) '~rev-less/global/variables';

.vbBtnFab {
	composes: vbUiBtn from './vb-btn-secondary.module.less';
	cursor: pointer;
	position: relative;
	width: 50px;
	height: 50px;
	border: none;
	border-radius: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);

	:global(.glyphicons) {
		font-size: 25px;
		top: 0;
	}

	&:hover,
	&:focus {
		text-decoration: none;
	}

	&:focus {
		box-shadow: 0 0 0 1pt white;
		outline: none;
	}

	&:hover:not([disabled]) {
		background: @hoverBackground;
	}

	&[disabled] {
		opacity: .5;
	}

	:global(.glyphicons:not(.theme-accent-inverted)) {
		color: inherit !important;
	}
}
