.container {
  display: flex;
  flex-direction: column;
}
@media (min-width: 992px) {
  .container {
    flex-direction: row;
  }
}
.panelHeading {
  border-bottom: 1px solid #cbcbcb;
  margin: 0;
  padding: 15px !important;
  border-radius: 3px 3px 0 0;
}
.panelHeading h4 {
  color: #565656;
  text-transform: uppercase;
  font-size: 1rem;
  text-shadow: 0 1px 1px #fff;
  font-weight: bold;
  vertical-align: middle;
}
.column {
  flex: 1;
  border-top: 2px solid #cbcbcb;
  border-bottom: 2px solid #cbcbcb;
  padding: 10px;
  margin: 10px;
}
.column .columnTitle {
  font-size: 1.125rem;
}
.helpIcon {
  color: rgba(111, 115, 119, 0.5);
}
