.root {
  cursor: pointer;
  display: table-cell;
  padding: 0 0.5em;
  position: relative;
  vertical-align: middle;
  width: auto;
}
.container {
  background-color: #666666;
  height: 12px;
  left: 0.5em;
  max-width: none !important;
  padding: 0;
  position: absolute;
  right: 0.5em;
  top: 12px;
  width: auto !important;
}
.slider {
  background-color: #000;
  height: 2px;
  left: 0;
  margin-bottom: calc(36px / 2);
  margin-top: calc(36px / 2);
  position: relative;
  right: 0;
}
