.bufferingContainer {
  align-items: center;
  display: flex;
  height: 100%;
  opacity: 0.6;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}
.loadingSpinner {
  animation: spin 0.5s infinite linear;
  background-color: transparent;
  border-color: #fff transparent transparent #fff;
  border-radius: 50px;
  border-style: solid;
  border-width: 5px;
  box-shadow: 0 0 35px #fff;
  height: 50px;
  margin: 0 auto;
  width: 50px;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
