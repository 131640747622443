.admin {
	.panel-heading {
		&.no-border {
			border: 0;
		}

		&.panel-heading-toolbar {
			height: 116px;
		}

		div[class ^= "col-"] {
			line-height: 31px;
			color: #7d7d7d;

			.btn {
				position: relative;
				top: 2px;
			}

			.label {
				font-size: 10px;
			}
		}

		h4 > .form-group {
			margin: 0;
		}

		.btn-group {
			margin-right: 30px;

			img {
				vertical-align: middle;
			}
		}

		+ .panel-heading {
			margin-top: 0;
		}

		.btn-primary .glyphicons {
			color: @white;
		}

		.btn-admin .glyphicons {
			color: fade(@black, 30%);
		}

		.cogwheel {
			display: none;
		}

		h4 {
			color: @color1MedText;
			text-transform: uppercase;
			font-size: @type16;
			text-shadow: 0 1px 1px @white;
			font-weight: bold;
			vertical-align: middle;
		}
	}

	.panel-footer {
		h4 {
			&:extend(.admin .panel-heading h4);
		}
	}

	.panel {
		.panel {
			margin: 0;
			border: 0;

			&.panel-default .panel-heading {
				&,
				+ .panel-heading {
					background: @adminPanel;
					.RoundedShape(0);
				}

				h4 {
					font-size: 14px;
					text-shadow: none;
					margin: 0;
					line-height: 48px;
				}
			}
		}

		.panel-heading + .panel .panel-heading,
		.panel.panel-default + .panel.panel-default .panel-heading,
		.panel-heading + form .panel.panel-default .panel-heading {
			border-top: 1px solid @borderColor;
		}
	}

	.split-panel-wrapper .panel {
		border: 1px solid fade(@black, 20%);
	}

	.panel-body {

		background: @adminPanel;
		.RoundedShape(3px);

		.btn-admin:not(.btn-primary):not(.active) {
			background: @whiteFade50;
		}

		&.panel-body-vertical-padding {
			padding: 15px;
		}

		fieldset .form-group:last-child {
			padding-bottom: 8px;
		}

		&.no-padding {
			padding: 0 !important;
			margin-top: 0;

			+ .panel-footer {
				margin-top: 0;
			}
		}

		+ .panel-footer {
			border-top: 1px solid fade(@black, 7.5%);
		}

		+ .panel-body.highlight {
			margin-top: 15px;
			margin-bottom: -15px;
		}

		&.highlight {
			form {
				padding: 15px 0;
			}

			.tab-pane {
				background: @white;

				.form-group {
					margin: 0;
				}
			}
		}
	}

	.form-group {
		padding-top: 8px;
		padding-bottom: 8px;
		margin: 0;

		&.no-padding-top {
			padding-top: 0;
		}
	}

	.form-inline .form-group {
		padding-top: 5px;
		padding-bottom: 12px;
	}

	.panel-sub-heading + .table-tools {
		margin-top: 15px;
	}

	.panel-default {
		border: none;
	}

	.panel-body + .panel-heading,
	.panel-heading + .panel-heading,
	.panel-body + .panel-footer {
		.BorderTopRightRadius(0);
		.BorderTopLeftRadius(0);
	}

	.new-panel-body {
		padding: 0;
	}

	.panel-footer h2 {
		.glyphicons {
			top: 4px;
			margin-right: 4px;
			color: fade(@black, 15%);
		}
	}
}

.tabbed-panel .panel-body {
	padding: 0 0 15px 0;

	.tab-pane {
		padding-top: 15px;
	}

	fieldset {
		margin-left: 15px;
		margin-right: 15px;
	}
}

.scroll-wrapper {
	position: relative;
	z-index: 0;
}

/* end library split view */

.form-button-row {
	margin: 15px -15px 15px -15px;

	&.panel-heading,
	&.panel-footer {
		margin: 15px 0;
		padding: 15px !important;

		h2, h3 {
			margin-top: 0 !important;
			margin-bottom: 0 !important;
			line-height: 40px;
		}
	}

	&.panel-footer {
		margin: 0 0 15px 0;
	}

	&.panel-heading {
		margin-bottom: 0;
	}
}

.panel-sub-heading {
	padding: 15px;
	margin: 0 -15px;
	color: #596065;
	background: @blackFade05;
	font-size: 13px;
	.RoundedTopShape(4px);

	.table-cell & {
		margin: 0;
	}
}

.panel-body,
.panel-heading {
	+ .table tr:first-child {
		th,
		td {
			border-top: 0;
		}
	}
}

.admin-content .panel {
	background: @white;
	border: none;
	.RoundedShape(4px);
	.BoxShadow(0 2px 2px @blackFade10);
}

.label-status-active {
	color: @whiteFade75;
	background: #00af43;
	font-size: 13px;
	font-weight: 500;
}

/* form-button-row override */
.setup .panel-footer.form-button-row {
	margin: 15px -30px !important;
	padding: 15px !important;
}

.new-panel {
	+ .new-panel {
		margin-top: 15px;
	}

	&.rounded {
		.RoundedShape(4px);
	}

	&-header {
		font-weight: 600;
		height: auto;

		h1,
		h2,
		h3,
		h4 {
			color: @color1MedText;
			line-height: 1.4;
			margin-top: 0 !important;
			margin-bottom: 0 !important;
			padding: @type16 0;
			text-transform: uppercase;
			vertical-align: middle;
		}

		p.help {
			text-transform: none;
			font-size: 11px;
			margin-top: 8px;
		}

		.accent & {
			background: rgba(89, 96, 101, 1);
			color: @whiteFade75;
		}

		.content & {
			text-transform: uppercase;
		}

		.admin-light & {
			background: @whiteFade50;
			color: rgb(89, 96, 101);
		}

		.rounded & {
			.RoundedTopShape(4px);

			+ .new-panel-body {
				.BorderTopLeftRadius(0);
				.BorderTopRightRadius(0);
			}

			.collapsed& {
				.RoundedShape(4px);
			}
		}
	}

	&-body {
		padding: 15px;

		.accent & {
			background: rgba(89, 96, 101, 0.3);
			padding: 15px;
		}

		.admin-light & {
			background: @white;
		}

		.rounded & {
			.RoundedShape(4px);

			&:last-child {
				.RoundedBottomShape(4px);
			}
		}
	}

	&-footer {
		margin-top: 0;
		padding: 15px;
		border-top: 1px solid @borderColor;

		.rounded & {
			.RoundedBottomShape(4px);
		}
	}

	&.admin-light {
		.RoundedShape(4px);
		background: @white;

		.table-tools {
			margin: -15px -15px 0 -15px;
			padding: 5px;
			background: rgba(89, 96, 101, 0.2);
			.BoxShadow(0 2px 0 fade(@black, 20%));
		}
	}

	&.new-panel-border .new-panel-header {
		border-bottom: 0;
		padding: 8px;
		text-transform: uppercase;
		background: rgba(89, 96, 101, 0.2);
	}

	.media-permissions.table thead > tr > th {
		background: rgba(89, 96, 101, 1);
		color: @whiteFade50;
	}
}

.dark-version .new-panel-header {
	background-color: rgba(89, 96, 101, 1);
	padding: 5px 15px 5px 15px;
	color: @whiteFade75;
}

.form-control-search.input-search-xs {
	padding: 2px 5px;
	font-size: 10px;
	border-width: 1px;
	.RoundedShape(3px);
	height: auto;
}

.teams-panel {

	background: @backgroundLighten03;

	h4 {
		font-size: 14px;
		font-weight: bold;
		color: @color1MedText;
		border-top: 3px solid @blackFade10;
		padding-top: 8px;
	}

	.form-group {
		padding-top: 0;
	}

	.form-control {
		.BoxShadow(none);
	}

	.name-field {
		.success-field {
			top: auto;
			bottom: 2.5rem;
			right: -4px;
		}

		.error-field {
			position: static;
		}
	}

	@media (min-width: 768px) {
		.has-error .error-field {
			margin-left: 0;
		}
	}
}

@media (max-width: 900px) {
	.admin .panel {
		margin-bottom: 15px;
	}
}
