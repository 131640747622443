.tab {
  background: #4d4d4d;
  border-radius: 3px 3px 0 0;
  border: 1px solid transparent;
  border-bottom-width: 0 !important;
  bottom: -1px;
  color: #fff;
  margin-right: 0.25rem;
  padding: 0.75rem;
  position: relative;
}
.tab.tabInvalid {
  background-color: #c41616;
  color: #fff;
}
.tabActive {
  border: 1px solid #b3b3b3;
  z-index: 2;
}
.tabActiveFixedColor {
  background: #fff;
  color: #000;
}
.tabPanel {
  border-width: 1px;
  border-style: solid;
  border-radius: 0 3px 3px 3px;
  padding: 0.75rem;
  position: relative;
  z-index: 1;
}
.tabPanelFixedColor {
  background: #fff;
  color: #000;
  border: 1px solid #b3b3b3;
}
.tabVerticalFill {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0.75rem;
}
.headerIcon {
  margin-right: 5px;
}
@media all and (max-width: 767px) {
  .headerIcon {
    margin-right: 0;
  }
}
@media all and (max-width: 767px) {
  .hideHeaderLabelInSmallDevices {
    display: none;
  }
}
