.root {
  margin: 1rem;
  min-height: 90vh;
  position: relative;
}
.rootLoading {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}
.videoReports {
  color: #000;
}
.videoReports :global(.reports-header) {
  margin-right: 4rem;
}
.closeBtn {
  composes: btn-transparent from global;
  color: #000;
  font-size: 1.5rem;
  position: absolute;
  right: 0.875rem;
  top: 0.875rem;
  z-index: 1;
}
.reportsServiceDownMsg {
  color: #000;
}
.reportsServiceDownCloseBtn {
  float: right;
}
