@import "global/variables";
@import "global/mixins";


.child-row {
	.circle {
		background: @color2Fade50;
		height: 32px;
		width: 32px;
		padding-top: 8px;
		color: @color1Fade50;
		font-weight: normal;

		&.team.user {

			&:before {
				top: 7px;
				font-size: 17px;
			}

			.cogwheel {
				position: absolute;
				font-size: 11px;
				right: 2px;
				top: 8px;
			}
		}
	}
}

.glyphicons.team.user {

	&:before {
		left: 4px;
		position: absolute;
		top: 1px;
	}

	&:after {
		// cogwheel
		content: "\E137";
		display: inline-block;
		.GlyphiconFont;
		-webkit-font-smoothing: antialiased;
		font-style: normal;
		font-weight: normal;
		line-height: 1;
		position: absolute;
		font-size: 10px;
		right: 3px;
		top: 8px;
	}

}


.table-cell-library-name {
	font-weight: bold;

	a.new-badge {
		width: auto;
	}

	.circle {
		background: @color2Fade50;
		height: 32px;
		width: 32px;
		padding-top: 8px;
		color: @color1Fade50;
		font-weight: bold;
	}

	.badge {
		background: @accentColor;
		color: @white;
		font-size: @type8;
		.RoundedShape(2px);
		padding: 4px;
		width: 32px;
		text-transform: uppercase;
	}

	.new-badge .badge {
		width: auto;
		min-width: 32px;
	}
}

.ldap-auto-parent-import {
	> .tree-item {
		background: fade(@white, 65%);

		.table-cell-library-name .circle {
			background: @whiteFade75;
		}
	}
}

.group-list {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	top: 100px;

	.panel {
		&:extend(.AbsoluteFill);
		margin-bottom: 0;

		.panel-heading {
			height: 100px;
		}

		.panel-body {
			position: absolute;
			top: 100px;
			left: 0;
			right: 0;
			bottom: 64px;
		}
		
		.panel-footer {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
		}
	}
}

@media(min-width:600px){
	.fake-first-child {
		padding-left: 8px !important;
	}
}

@media(min-width: 768px) {
	.group-list {
		left: 30px;
		right: 30px;
		bottom: 30px;
		top: 115px;

		.panel {
			.panel-heading {
				height: 96px;
			}

			.panel-body {
				position: absolute;
				top: 96px;
			}
		}
	}
}

.group-detail .ldap-user-container {
	height: 350px;
	overflow-y: auto;
}