vb-date-time-input,
.vb-date-time-input {
  display: block;
}
vb-date-time-input .input-group:not(.input-group-lg) .input-group-btn,
.vb-date-time-input .input-group:not(.input-group-lg) .input-group-btn {
  height: 31px;
  line-height: 31px;
}
vb-date-time-input .input-group:not(.input-group-lg) .input-group-btn .btn,
.vb-date-time-input .input-group:not(.input-group-lg) .input-group-btn .btn {
  height: 31px;
  line-height: 31px;
}
vb-date-time-input .input-group .input-group-btn .btn span.glyphicon-calendar,
.vb-date-time-input .input-group .input-group-btn .btn span.glyphicon-calendar,
vb-date-time-input .input-group .input-group-btn .btn span.calendar,
.vb-date-time-input .input-group .input-group-btn .btn span.calendar {
  position: relative;
  width: auto;
  height: auto;
  line-height: 1;
}
vb-date-time-input .input-group.input-group-lg .input-group-btn:last-child > .btn,
.vb-date-time-input .input-group.input-group-lg .input-group-btn:last-child > .btn {
  margin-left: 0;
}
vb-date-time-input span.calendar,
.vb-date-time-input span.calendar,
vb-date-time-input span.glyphicon-calendar,
.vb-date-time-input span.glyphicon-calendar {
  position: absolute;
  top: 0;
  color: #898989;
  right: 0;
  display: block;
  width: 31px;
  height: 31px;
  line-height: 31px;
  text-align: center;
}
vb-date-time-input .dropdown-menu,
.vb-date-time-input .dropdown-menu {
  min-width: 354px;
}
vb-date-time-input .dropdown-menu thead .btn-sm,
.vb-date-time-input .dropdown-menu thead .btn-sm {
  font-size: 0.875rem;
  font-weight: normal;
  padding: 12px 10px;
}
vb-date-time-input .dropdown-menu .btn,
.vb-date-time-input .dropdown-menu .btn {
  border-radius: 0;
  border: none;
}
vb-date-time-input .dropdown-menu .btn-sm,
.vb-date-time-input .dropdown-menu .btn-sm {
  border-radius: 0;
  border: none;
  font-size: 0.75rem;
}
vb-date-time-input .dropdown-menu .btn span,
.vb-date-time-input .dropdown-menu .btn span {
  padding: 8px 10px;
}
vb-date-time-input .dropdown-menu .btn span.text-info,
.vb-date-time-input .dropdown-menu .btn span.text-info {
  color: #00a0f0;
  font-weight: bold;
}
vb-date-time-input .dropdown-menu .btn.active,
.vb-date-time-input .dropdown-menu .btn.active {
  background: #00a0f0;
  color: #fff;
}
vb-date-time-input .dropdown-menu .btn.active span.text-info,
.vb-date-time-input .dropdown-menu .btn.active span.text-info {
  color: #fff;
}
vb-date-time-input .form-group,
.vb-date-time-input .form-group {
  padding: 0;
  display: block;
}
vb-date-time-input .form-group input,
.vb-date-time-input .form-group input {
  display: block;
  width: 100%;
}
vb-date-time-input .input-time,
.vb-date-time-input .input-time {
  text-align: right;
}
@media (max-width: 992px) {
  vb-date-time-input .col-sm-12,
  .vb-date-time-input .col-sm-12 {
    padding-bottom: 4px;
  }
  vb-date-time-input .input-time,
  .vb-date-time-input .input-time {
    text-align: left;
  }
}
