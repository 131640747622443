.icon {
  color: #999999;
  font-size: 1rem;
  line-height: 0.75rem;
  margin-left: 0.25rem;
  vertical-align: top;
  width: 0;
}
.value {
  color: rgba(0, 0, 0, 0.75);
  font-size: 1.625rem;
}
.valueDeltaPositive {
  color: #009b01;
}
.valueDeltaNegative {
  color: rgba(196, 22, 22, 0.75);
}
