@import (reference) "~rev-less/global/variables";
@import (reference) "~rev-less/global/mixins";

vbrick-player {
	vb-time-marker { // TODO: these are usage specific, so belong here on the rev side
		&.triangle-on-bottom {
			.TriangleTop(10px, @accentColor);
			margin-left: -9px;
			bottom: -11px;
		}

		&.triangle-on-top {
			.TriangleBottom(5px, @highlight);
			margin-left: -4px;
			top: -5px;
			z-index: 2;
		}

		&.inactive-time-range {
			background-color: @warningColor;
			cursor: default;
			height: 100%;
		}
		&.time-range-display {
			height: 10px;
			top: -10px;
			border: 1px solid @alertColor;
			background-color: @alertColor;
		}
	}
}