.root {
  min-height: 1px;
}
.root :global([slot='mainContent']) {
  display: block;
  height: 100%;
}
.mainContentSlot {
  position: relative;
}
@media (max-width: 991px) {
  .mainContentSlot > :global([slot='mainContent']) {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
  }
}
@media (max-width: 991px) {
  .mainContentSidebarOpen {
    -webkit-flex: none;
    flex: none;
  }
}
