@import (reference) '~rev-less/global/variables';
@import (reference) '~rev-less/global/mixins';

@import './vb-ui-radio-button.module.less';

.vbRadioCheckmark {
	composes: RadioCheckmark from './vb-ui-radio-button.module.less';
	width: 20px;
	height: 20px;
}

.vbRadioCheckmarkChecked {
	composes: RadioCheckmarkChecked from './vb-ui-radio-button.module.less';
	height: 10px;
	width: 10px;
}

.vbRadioTextInput {
	composes: RadioTextInput from './vb-ui-radio-button.module.less';
	position: relative;
	top: -2px;
}
