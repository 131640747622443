.rowDivider {
  composes: theme-primary-border from global;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  margin-top: 0;
  padding-top: 0;
  margin-bottom: 40px;
  padding-bottom: 4px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.rowDivider .dlgIcon {
  font-size: 24px;
  cursor: pointer;
}
.rowDivider h4 {
  margin: 6px 12px;
}
.spacer {
  flex-grow: 1;
}
.container {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  height: 100%;
  width: 100%;
}
.container .spinnerContainer {
  height: 400px;
}
.container .playerContainer {
  width: 100%;
  height: 100%;
}
.container vb-video-player {
  width: 100%;
  height: 400px;
}
.container vb-overlay-play-controls div {
  background: none;
}
.container vb-overlay-play-controls div div {
  display: none;
}
.container vb-playback-rate-button,
.container vb-playback-menu-button,
.container vg-time-display,
.container vg-volume,
.container vg-fullscreen-button {
  display: none;
}
.container .selectBtn {
  margin: 16px;
}
