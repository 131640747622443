@font-face {
	font-family: "vb-icon-font";
	src: url("vb-icon-font.eot?6762a5e78a69ff416b67a9ea65e028aa?#iefix") format("embedded-opentype"),
url("vb-icon-font.woff?6762a5e78a69ff416b67a9ea65e028aa") format("woff");
}

.vb-icon {
	line-height: 1;
}

.vb-icon:before {
	font-family: vb-icon-font !important;
	font-style: normal;
	font-weight: normal !important;
	vertical-align: top;
}

.vb-icon-360-indicator:before {
	content: "\f101";
}
.vb-icon-camera-plus:before {
	content: "\f102";
}
.vb-icon-camera:before {
	content: "\f103";
}
.vb-icon-chapters:before {
	content: "\f104";
}
.vb-icon-cloud-upload:before {
	content: "\f105";
}
.vb-icon-column-picker:before {
	content: "\f106";
}
.vb-icon-completion-rate:before {
	content: "\f107";
}
.vb-icon-content-and-speaker:before {
	content: "\f108";
}
.vb-icon-content-only:before {
	content: "\f109";
}
.vb-icon-csv:before {
	content: "\f10a";
}
.vb-icon-database:before {
	content: "\f10b";
}
.vb-icon-frame-user:before {
	content: "\f10c";
}
.vb-icon-not-streaming:before {
	content: "\f10d";
}
.vb-icon-password:before {
	content: "\f10e";
}
.vb-icon-pencil-gear:before {
	content: "\f10f";
}
.vb-icon-play-newwindow:before {
	content: "\f110";
}
.vb-icon-q-and-a:before {
	content: "\f111";
}
.vb-icon-realtime-analytics:before {
	content: "\f112";
}
.vb-icon-reset:before {
	content: "\f113";
}
.vb-icon-save:before {
	content: "\f114";
}
.vb-icon-side-by-side-layout:before {
	content: "\f115";
}
.vb-icon-speaker-bottom-right-layout:before {
	content: "\f116";
}
.vb-icon-speaker-top-right-layout:before {
	content: "\f117";
}
.vb-icon-speaker:before {
	content: "\f118";
}
.vb-icon-unlisted-video:before {
	content: "\f119";
}
.vb-icon-user-engaged:before {
	content: "\f11a";
}
.vb-icon-warning:before {
	content: "\f11b";
}
