.root {
  border: 1px solid #c5c5c5;
  background-color: #ffffff;
  color: rgba(0, 0, 0, 0.75);
  display: flex;
  height: 160px;
  margin-bottom: 1.5rem;
}
.root:not(:last-child) {
  margin-right: 1.5rem;
}
.root.sizeMedium {
  height: 210px;
}
.root.sizeLarge {
  height: 400px;
}
.headerContainer {
  border-top: 3px solid;
  border-bottom: 1px solid #c5c5c5 !important;
  height: 50px;
  line-height: 1.1;
  width: 100%;
}
.tooltipContainer {
  top: -10px !important;
}
.tooltipContainer :global(.tooltip-arrow) {
  left: 50% !important;
}
.noHeaderBottomBorder .headerContainer {
  border-bottom: 0 !important;
}
.headerAbsoluteContainer {
  padding: 5px 10px;
}
.header {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 0.875rem;
  margin: 0;
}
.subheader {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  font-size: 0.625rem;
  margin: 0.25rem 0 0;
}
.content {
  color: rgba(0, 0, 0, 0.75);
  padding: 1rem;
}
.footer {
  border-top: 1px solid #c5c5c5;
  padding: 10px 20px;
}
.footerItem {
  text-align: center;
  font-weight: bold;
}
.footerItem:not(:first-child) {
  border-left: 1px solid #c5c5c5;
}
