.unlistedVideoStatus {
  background: rgba(48, 48, 48, 0.75);
  display: inline-block;
  height: auto;
  padding: 0 8px;
  color: #fff;
  font-size: 0.875rem;
  font-weight: bold;
  line-height: 1.8;
}
.unlistedVideoStatus :global(.vb-icon) {
  line-height: 1.8;
}
.onlyIconDisplay {
  background: transparent;
}
.onlyIconDisplay :global(.vb-icon) {
  color: rgba(48, 48, 48, 0.75);
  font-size: 1.25rem;
}
