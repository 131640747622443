.TextInput {
  border: 1px solid #909090;
  border-radius: 3px;
  color: #141414;
  font-size: 0.875rem;
  height: 40px;
  line-height: 1.33;
  padding: 10px 16px;
  width: 100%;
}
.TextInput::-webkit-input-placeholder {
  color: #b0b0b0;
}
.TextInput:-moz-placeholder {
  color: #b0b0b0;
}
.TextInput::-moz-placeholder {
  color: #b0b0b0;
}
.TextInput:-ms-input-placeholder {
  color: #b0b0b0;
}
.TextInput:active {
  color: #141414;
}
.TextInput::-ms-clear {
  display: none;
}
.TextInput:global(.ng-invalid):not(:global(.ng-untouched)):not(:focus) {
  border: 3px solid #c41616;
}
.TextInputFocusBorder:focus {
  border-width: 3px;
  box-shadow: inset 0px 0px 5px 0px rgba(0, 0, 0, 0.25), 0 0 12px rgba(0, 0, 0, 0.25);
  outline: 0;
}
