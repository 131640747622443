.root {
  display: table-cell;
  width: 20px;
  vertical-align: middle;
}
.layoutOptionsWrapper {
  padding: 0;
  margin: 10px 15px 15px 10px;
  display: flex;
  align-items: center;
  position: relative;
  min-width: 126px;
}
.layoutOptionsWrapper:before {
  content: '';
  width: 100%;
  border: 1.2px solid rgba(255, 255, 255, 0.5);
  position: absolute;
  z-index: -1;
}
.layoutOption {
  width: 8px;
  height: 8px;
  text-align: center;
  line-height: 1;
  border-radius: 50%;
  display: inline-block;
  border: 1.5px solid rgba(255, 255, 255, 0.5);
  position: absolute;
  background-color: #303030;
}
.layoutOption:hover {
  cursor: pointer;
  background-color: #00a0f0;
}
.layoutOption:first-child {
  left: 0;
}
.layoutOption:last-child {
  left: 100%;
}
.layoutOption:nth-child(2) {
  left: 20%;
}
.layoutOption:nth-child(3) {
  width: 12px;
  height: 12px;
  left: 48%;
}
.layoutOption:nth-child(4) {
  left: 80%;
}
.active {
  background: #00a0f0;
  width: 16px !important;
  height: 16px !important;
  border: none !important;
}
.active:last-child {
  left: 94%;
}
.dropdownMenu {
  composes: dropdownMenu from '~vbrick-player-src/MenuButton.module.less';
  padding: 10px !important;
  border-radius: 0 !important;
}
:global(.dropdown-menu).dropdownMenu {
  min-width: 0;
  transform: translateX(2px);
}
.menuButton {
  composes: menuButton from '~vbrick-player-src/MenuButton.module.less';
  width: auto;
}
.layoutIcon {
  font-size: 1.4rem;
  position: relative;
  top: 1px;
}
.optionButtonsWrapper {
  display: flex;
}
.optionButton {
  composes: iconButton from '~vbrick-player-src/VbrickPlayer.module.less';
  padding: 4px !important;
}
.optionButton:last-child {
  margin-left: auto;
}
.optionButton :global(.vb-icon) {
  font-size: 1.3rem;
}
.ie .layoutOptionsWrapper {
  height: 20px;
}
.ie .layoutOptionsWrapper:before {
  border-width: 1.3px !important;
}
.ie .layoutOption {
  top: 34%;
}
.ie .layoutOption:nth-child(3) {
  top: 22%;
}
.ie .layoutOption.active {
  top: 14% !important;
}
