.root {
  display: block;
}
.level0:before,
.level1:before,
.level2:before,
.level3:before {
  content: '\e185';
}
.mute:before {
  content: '\e183';
}
.button {
  composes: iconButton from '../../VbrickPlayer.module.less';
  width: 100%;
}
