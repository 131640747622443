.root {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  line-height: 0;
}
.profilePicture {
  object-fit: cover;
  vertical-align: baseline;
}
.profileIcon {
  vertical-align: middle;
  top: -2px;
}
.wrap {
  overflow: hidden;
  display: inline-block;
  background-color: #909090;
  border: 2px solid transparent;
  text-align: center;
  background-clip: content-box;
}
.wrap {
  height: 25px;
  width: 25px;
  border-radius: 12.5px;
  line-height: 23px !important;
}
.profilePicture {
  height: 21px;
  width: 21px;
  border-radius: 10.5px;
}
.profileIcon {
  font-size: 15px !important;
  border-radius: 12.5px;
}
.large .wrap {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  line-height: 38px !important;
}
.large .profilePicture {
  height: 36px;
  width: 36px;
  border-radius: 18px;
}
.large .profileIcon {
  font-size: 24px !important;
  border-radius: 20px;
}
:global(.vbProfilePictureLarge) .wrap {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  line-height: 38px !important;
}
:global(.vbProfilePictureLarge) .profilePicture {
  height: 36px;
  width: 36px;
  border-radius: 18px;
}
:global(.vbProfilePictureLarge) .profileIcon {
  font-size: 24px !important;
  border-radius: 20px;
}
