@import (reference) '~rev-less/global/variables';
@import (reference) '~rev-less/global/mixins';

.inputWrap {
	position: relative;

	:global(ul.dropdown-menu) {
		top: 43px !important;
		right: 0;
		font-size: @type16;
		border: 0;
		max-height: 400px;
		overflow-y: auto;
		.RoundedShape() !important;

		li > a {
			border-left: 0px;
			padding: 8px 16px;
		}
	}
	input[vb-text-input] {
		font-size: @type16;
		height: 36px;
	}
}

.noRightBorderRadius {
	.BorderTopRightRadius(0) !important;
	.BorderBottomRightRadius(0) !important;
}

.noLeftBorderRadius {
	.BorderTopLeftRadius(0) !important;
	.BorderBottomLeftRadius(0) !important;
}

.dropdownMenu {
	position: absolute;
	width: 100%;
	margin-top: 2px;
	z-index: 1;
	background: @white;
	min-height: 150px;
	padding: 10px;
	color: @color1Lighten50;
	top: 43px;
	.RoundedShape();

	.fadeInOut {
		&:global(.ng-hide) {
			opacity: 0;
		}

		&:global(.ng-hide-add),
		&:global(.ng-hide-remove) {
			transition: all linear 0.5s;
		}
	}
}
