.vbBtnFab {
  composes: vbUiBtn from './vb-btn-secondary.module.less';
  cursor: pointer;
  position: relative;
  width: 50px;
  height: 50px;
  border: none;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
}
.vbBtnFab :global(.glyphicons) {
  font-size: 25px;
  top: 0;
}
.vbBtnFab:hover,
.vbBtnFab:focus {
  text-decoration: none;
}
.vbBtnFab:focus {
  box-shadow: 0 0 0 1pt white;
  outline: none;
}
.vbBtnFab:hover:not([disabled]) {
  background: linear-gradient(rgba(34, 41, 48, 0.5), rgba(34, 41, 48, 0.5));
}
.vbBtnFab[disabled] {
  opacity: 0.5;
}
.vbBtnFab :global(.glyphicons:not(.theme-accent-inverted)) {
  color: inherit !important;
}
