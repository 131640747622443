.scroll-container {
	min-height: 200px;
	overflow: hidden;
	overflow-y: scroll;
	padding: 0 16px;
}

.scroll-container-auto {
	&:extend(.scroll-container);
	overflow-y: auto;
}
