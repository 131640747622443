@import (reference) '~rev-less/global/variables';
@import (reference) '~rev-less/global/mixins';

vb-date-time-input,
.vb-date-time-input {
	display: block;

	.input-group {
		&:not(.input-group-lg) .input-group-btn {
			height: 31px;
			line-height: 31px;

			.btn {
				height: 31px;
				line-height: 31px;
			}
		}

		.input-group-btn .btn {
			span.glyphicon-calendar,
			span.calendar {
				position: relative;
				width: auto;
				height: auto;
				line-height: 1;
			}
		}

		&.input-group-lg {
			.input-group-btn:last-child > .btn {
				margin-left: 0;
			}
		}
	}

	span.calendar,
	span.glyphicon-calendar {
		position: absolute;
		top: 0;
		color: @color1LightText;
		right: 0;
		display: block;
		width: 31px;
		height: 31px;
		line-height: 31px;
		text-align: center;
	}

	.dropdown-menu {
		min-width: 354px;

		thead {
			.btn {
				&-sm {
					font-size: @type14;
					font-weight: normal;
					padding: 12px 10px;
				}
			}
		}

		.btn {
			.RoundedShape(0);
			border: none;

			&-sm {
				.RoundedShape(0);
				border: none;
				font-size: @type12;
			}

			span {
				padding: 8px 10px;

				&.text-info {
					color: @accentColor;
					font-weight: bold;
				}
			}

			&.active {
				background: @accentColor;
				color: @white;

				span.text-info {
					color: @white;
				}
			}
		}
	}

	.form-group {
		padding: 0;
		display: block;

		input {
			display: block;
			width: 100%;
		}
	}

	.input-time {
		text-align: right;
	}

	@media(max-width: 992px) {
		.col-sm-12 {
			padding-bottom: 4px;
		}
		.input-time {
			text-align: left;
		}

	}
}