.root {
  cursor: pointer;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  width: 50px;
}
.root:hover {
  background-color: #00a0f0;
  color: #fff;
}
.root:hover .button {
  color: #fff;
}
.button {
  composes: iconButton from '../../VbrickPlayer.module.less';
  width: 100%;
}
.play:before {
  content: '\e174';
}
.pause:before {
  content: '\e175';
}
