@import "global/variables";
@import "global/mixins";

.btn {
	font-weight: 400;
	.RoundedShape(3px);
	padding: @typeUnit1-2x @typeUnit3-4x;
	top: 0;
	position: relative;

	&.active {
		.caret {
			border-top-color: @blackFade50;
		}

		.svg-icon {
			fill: @blackFade50;
		}
	}

	.main-nav & {
		.BoxShadow(0 1px 0 @blackFade25);
	}

	.circle_info {
		color: @blackFade25;
	}

	&.fake-disabled {
		cursor: default;
		pointer-events: auto;
	}

	&-modal-header {
		margin-top: 10px;
	}
}


.btn-icon-right .glyphicons,
.btn-icon-right .caret {
	margin-left: 3px;
}

.input-group-btn > .btn {
	padding: 6px 12px;
}

.btn-icon-left {

	.glyphicons {
		margin-right: 3px;
		margin-left: 0;
		font-size: @type11; //this is to get the height of the button down to the same size as a non-icon button
	}
}



.btn-primary {
	background: @accentColor;
	border-color: transparent;
	border-bottom: 1px solid @blackFade50;
	color: @white;
	text-shadow: 0 0 1px fade(@black, 37.5%);

	&:hover,
	&:active,
	&:focus
	{
		background: @accentDarken25;
	}

	&:focus,
	&:active,
	&.active {
		text-shadow: none;
	}

	.glyphicons {
		color: @whiteFade75;
		text-shadow: none;
	}
}

.btn-primary {
	.admin &.disabled,
	.admin &[disabled],
	.admin fieldset[disabled] & {
		&:extend(.mixin-btn-admin-disabled);
	}

	&.disabled,
	&[disabled],
	fieldset[disabled] .btn-primary,
	&.disabled:hover,
	&[disabled]:hover,
	fieldset[disabled] &:hover,
	&.disabled:focus,
	&[disabled]:focus,
	fieldset[disabled] &:focus,
	&.disabled:active,
	&[disabled]:active,
	fieldset[disabled] &:active,
	&.disabled.active,
	&[disabled].active,
	fieldset[disabled] &.active {
		background: @blackFade50;
		color: @whiteFade75;
		border-color: transparent;
	}
}

.btn-white {
	.VerticalGradient(@white, rgb(247,247,247));
	border-color: @borderColorDark;
	.BoxShadow(inset 0 1px 0 @white);
	color: @color1;

	.svg-icon {
		fill: @color1LightText;
	}

	.file-list & {
		color: @color1LightText;
		.BoxShadow(none);

		&.btn-actions-active {
			color: @color1;
			border-color: @blackFade25;
		}
	}

	&.btn-cancel {
		color: @color1LightText;

		&:hover {
			color: @color1;
		}
	}
}
.fc-button,
.btn-white[disabled] {
	.BoxShadow(none);
}

.btn-cancel {
	background: @blackFade05;
}

.btn-transparent {
	/* this style retains the base button padding */

	&,
	&:hover,
	&:active,
	&:focus {
		background: none !important;
		border: none !important;
		.BoxShadow(none);
	}

	&.btn-remove {
		background: none;
		color: @blackFade50;

		&:hover {
			background: none;
			color: @blackFade75;
		}
	}

	&.no-padding {
		padding: 0;
	}

}

.btn-search {
	border: none;
	padding: 0;
}

.open .dropdown-toggle.btn-primary {
	text-shadow: none;
}

/*  light blue "lit up" */
.fc-state-active,
.btn.active,
.btn-admin.btn-white.active {

	.BtnWhiteActive;

	.glyphicons {
		color: @darkBlue;
	}
}

.btn-file {
	position: relative;
	overflow: hidden;

	/*todo convert edit-webcast and branding logo to <div class="btn-file-wrapper"><button></button></div>*/
	&-wrapper {
		position: relative;
		overflow: hidden;
		display: inline-block;

		&.btn {
			padding: 0;
		}
	}

	input[type=file],
	&-wrapper input[type=file] {
		opacity: 0;
		z-index: 1;
		.AbsoluteFill();
		font-size: 999px; /* fixes file upload buttons in IE -- http://stackoverflow.com/a/15842513/1814093 */
		width: 100%;
		cursor: pointer;
	}
}

.fc-event {
	border: 1px solid @blackFade25;
	border-bottom: 1px solid @blackFade50;
	background: @accentDarken25;
	color: @white;
	font-size: @type14;
	cursor: default;

	&:hover {
		background: @accentDarken25;
		.BoxShadow(0 1px 0 @blackFade25);
	}
}

/* video settings */
.progress-bar {
	background: @accentColor;
}

.btn-more-icon {
	padding: 4px;
	.BoxShadow(none);

	&:active,
	&:focus {
		background: @color1Fade25;
	}
}

.more-icon {
	width: 24px;
	text-align: center;
	display: block;

	.circle {
		background: @color1Fade50;
		height: 4px;
		width: 4px;
	}
}

.btn-block {
	&:not(.ng-hide) + & {
		margin-top: 5px;
	}

	&.ng-hide + & {
		margin-top: 0 !important;
	}
}

/* check these */


.btn-lg,
.btn-group-lg > .btn {
	padding: 8px 15px;
	font-size: 14px;
	line-height: 1.33;
}

.btn-sm {
	font-size: @type11;
	padding: 5px 8px;

	&.btn-icon-left {
		.glyphicons {
			margin-right: 3px;
		}
	}

	&.btn-icon-right {
		.glyphicons {
			margin-left: 3px;
		}
	}
}

.btn-round {
	.Round;
	height: 24px !important;
	width: 24px !important;
	min-height: 24px !important;
	text-align: center;
	padding: 0;
}

.btn-radio,
.btn-checkbox {
	.RoundedShape(2px);
	color: @white;
	background: @white;
	border: 1px solid @borderColor;
	width: 24px;
	height: 24px;
	padding: 2px 0 1px 2px;
	.BoxShadow(none);

	&.active {
		color: @white;
		background: @accentColor;

		.glyphicons {
			color: @white;
		}
	}

	&:not(.active) .glyphicons.ok_2:before {
		content: " ";
	}

	.admin & {
		font-size: @type12;
		width: 16px;
		height: 16px;

		.glyphicons {
			top: 0;
		}
	}
}

.btn-radio {
	.Round;

	&.active {

		background: @white;
		border:1px solid @accentColor;

		&:after {
			content: '';
			position: absolute;
			.Round;
			width: 16px;
			height: 16px;
			top: 3px;
			left: 3px;
			background: @accentColor;
		}
	}
}

.full-cell-buttons {

	.btn {
		height: 43px;
		width: 42px;
		padding: 6px 8px;
	}

	.btn-checkbox {
		.RoundedShape(0);
		color: @white;
		background: @accentColor;
		border-color: @accentColor;

		&:focus {
			color: @white;
			background: @accentColor;
		}

		&:hover {
			color: @white;
			background: @accentColor;
		}
	}
}

.btn-link {
	color: @color1LightText;
	.BoxShadow(none);

	&:hover {
		color: rgb(0,85,128);
	}
}

.btn-group {
	&.radio-group {
		.btn.active.btn-xs {
			.BoxShadow(inset 0 1px 1px @blackFade10, 0 1px 0 @borderColor;);
		}

		.disabled & {
			opacity: 0.5;
		}
	}

	&.open .dropdown-toggle {
		.BoxShadow(none);
	}
}

.btn-group-toggle {
	background: @white;
	.RoundedShape(3px);

	.btn.active {
		.BoxShadow(inset 0 1px 2px @blackFade10);
	}
}

.btn-admin {
	background: fade(@accentColor, 35%);
	color: @blackFade75;
	border: 1px solid @borderColor;
	border-bottom-color: fade(@black, 20%);
	.BoxShadow(inset 0 1px 0 @whiteFade25);

	.glyphicons {
		color: @blackFade50;
	}

	&.btn-white {
		background: @backgroundLighten03;
		color: @color1;
		border-color: @borderColor;
		border-bottom-color: fade(@black, 20%);
		.BoxShadow(inset 0 1px 0 @whiteFade50);
	}

	&.btn-white.disabled,
	&.btn-white[disabled],
	fieldset[disabled] &.btn-white {
		&:extend(.mixin-btn-admin-disabled);
	}

	&:hover {
		background: @borderColor;
		color: @color1;
	}

	&-delete:hover {
		background: rgba(170, 40, 40, 0.4);
		color: fade(@black, 60%);
	}
}

.mixin-btn-admin-disabled {
	color: @blackFade50;
	background: none;
	border: 1px solid @borderColor;
	border-bottom-color: @borderColorDark;
	text-shadow: none;
	.TransitionAll(none);
}

.btn-tree-control {
	background: #464c50;
	color: rgba(154,212,241,1);
	font-size: 10px!important;
	border: 1px solid @borderColor;
	.RoundedShape(2px);
	padding: 3px;
	height: 12px;
	width: 14px;
	margin-right: 3px;
	min-height: 16px!important;

	&:hover,
	&:active,
	&:focus {
		color: rgba(154,212,241,0.8);
	}

	.glyphicons {
		position: relative;
		left: -2px;
		top: -2px;
	}
}

.btn.spark-button {
	@size: 20px;
	position: relative;
	padding-left: @size + 14px;
	max-width: 300px;
	.TextClip();

	span{
		display: inline-block;
		position: absolute;
		top: 5px;
		left: 5px;
		height: @size;
		width: @size;
		.RoundedShape(4px);
		background-image: url(/img/logo_spark_64px.png);
		background-size: contain;
		background-repeat: no-repeat;
	}
}

.btn.sms-button {
	@size: 20px;
	position: relative;
	padding-left: @size + 14px;
	max-width: 300px;
	.TextClip();

	span{
		display: inline-block;
		position: absolute;
		top: 5px;
		left: 5px;
		height: @size;
		width: @size;
		border: 1px solid @white;
		.RoundedShape(4px);
		background-color: @white;
		background-image: url(/img/message-sms-text.png);
		background-size: contain;
		background-repeat: no-repeat;
	}
}

.flex-navigation-bar .btn.spark-button{
	margin-bottom: 2px;
}
