.wrapper {
  height: inherit;
  width: inherit;
}
.wrapper:global(.open .dropdown-toggle):before {
  content: " ";
  position: absolute;
  border-style: solid;
  border-width: 0 14px 14px 14px;
  border-color: transparent transparent #000 transparent;
  left: 50%;
  margin-left: -14px;
  bottom: 13px;
  z-index: 5001;
}
.wrapper :global(.dropdown-menu) {
  padding: 0;
  top: 7.7em !important;
}
@media all and (max-width: 992px) {
  .wrapper :global(.dropdown-menu) {
    top: 8.2em !important;
  }
}
@media all and (max-width: 768px) {
  .wrapper :global(.dropdown-menu) {
    top: 12.4em !important;
  }
}
.dropdown-menu {
  padding: 0;
  margin: 0;
  right: -4.4em !important;
}
.uploadBtn {
  border: none;
  border-radius: 0 !important;
}
.uploadBtn:hover {
  border: none !important;
}
