.root {
  display: block;
  border-radius: 4px;
  padding: 1rem;
  width: 50%;
  margin-left: 25vw;
  line-height: 100%;
}
.messageWrapper {
  background-color: #cccccc;
  border-color: #808080;
  font-size: 1.5rem;
  text-align: center;
  border-radius: 4px;
  padding-bottom: 30px;
  line-height: 2rem;
  color: #000;
}
.frown {
  display: block;
  font-size: 4rem;
  padding: 2rem;
}
