.vbBtnPrimary {
  composes: vbUiBtn from './vb-btn-secondary.module.less';
  cursor: pointer;
  position: relative;
  border-width: 1px;
  border-style: solid;
}
.vbBtnPrimary:hover,
.vbBtnPrimary:focus {
  text-decoration: none;
}
.vbBtnPrimary:hover:not([disabled]) {
  background: linear-gradient(rgba(34, 41, 48, 0.5), rgba(34, 41, 48, 0.5));
}
.vbBtnPrimary[disabled] {
  opacity: 0.5;
}
.vbBtnPrimary :global(.glyphicons:not(.theme-accent-inverted)) {
  color: inherit !important;
}
