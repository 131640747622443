.btn-primary.theme-accent {
  position: relative;
  border-width: 0;
}
.btn-primary.theme-accent:hover:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.25);
}
