@import (reference) '~rev-less/global/variables';
@import (reference) '~rev-less/global/mixins';

.root {
	margin: 1rem;
	min-height: 90vh;
	position: relative;
}

.rootLoading {
	.displayFlex();
}

.videoReports {
	color: @black;

	:global(.reports-header) {
		margin-right: 4rem;
	}
}

.closeBtn {
	composes: btn-transparent from global;
	color: @black;
	font-size: @type24;
	position: absolute;
	right: .875rem;
	top: .875rem;
	z-index: 1;
}

.reportsServiceDownMsg {
	color: @black;
}

.reportsServiceDownCloseBtn {
	float: right;
}