.root {
  background-color: #000;
  display: block;
  flex-flow: column;
  height: 100%;
  position: relative;
  user-select: none;
  width: 100%;
}
.root button {
  cursor: pointer;
}
.fullscreen {
  left: 0;
  position: fixed;
  top: 0;
}
