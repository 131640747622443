.root {
  cursor: pointer;
  height: 100px;
  margin-left: -25px;
  position: absolute;
  right: 30px;
  text-align: center;
  top: -100px;
  vertical-align: middle;
  visibility: hidden;
  width: 50px;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .root {
    zoom: 1;
    opacity: 1;
  }
}
.verticalVolumeBar {
  background-color: #303030;
  height: 100px;
  position: absolute;
  width: 40px;
}
.volumeBackground {
  background-color: #666666;
  height: 70px;
  left: 10px;
  position: absolute;
  top: 15px;
  width: 20px;
}
.volumeClickArea {
  width: 20px;
  height: 100%;
  position: absolute;
  z-index: 1;
}
.volumeValue {
  width: 20px;
  height: 100%;
  background-color: #00a0f0;
  position: absolute;
  z-index: 1;
}
