.errorMessage {
  border: 1px solid #c41616;
  background-color: #f9c7c7;
  color: #c41616;
  margin-top: 5px;
  padding: 2px;
  font-size: 0.875rem;
}
.errorMessageWrapper:global(.ng-active) {
  border: 1px solid #c41616;
  background-color: #f9c7c7;
  color: #c41616;
  margin-top: 5px;
  padding: 2px;
  font-size: 0.875rem;
}
.errorMessageWrapper .message {
  padding: 10px;
}
.errorMessageWrapper .message:before {
  content: '\E193';
  position: relative;
  top: 1px;
  display: inline-block;
  font-weight: 400;
  font-family: Glyphicons Regular;
  line-height: 1;
}
