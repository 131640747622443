.dropdownList {
  background: #f8f8f8;
}
.dropdownList li > a {
  padding: 5px 0 5px 25px !important;
  position: relative;
}
.dropdownList li > a > span {
  position: absolute;
  top: 7px;
  left: 6px;
}
.activeAnchor {
  font-weight: bold;
}
